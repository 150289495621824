import React from 'react';
import {
    Stack,
    Typography,
    Avatar,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import YellowArrowRight from '../../Assets/Icons/YellowArrowRight';
import DotIcon from '../../Assets/Icons/DotIcon';
import '@fontsource/outfit';
import blogDetailImage from '../../Assets/Images/blogdetail3image.png';

const BlogDetails3 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    return (
        <Stack flex={1}>
            <Stack
                zIndex={2}
                height={isMobile ? '238px' : '358px'}
                borderRadius={isMobile ? '20px' : '40px'}
                justifyContent="flex-end"
                px={isMobile ? '8px' : '48px'}
                pb={isMobile ? '16px' : '24px'}
                pt="48px"
                mb={isMobile ? '20px' : '60px'}
                sx={{
                    backgroundImage: `url(${blogDetailImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <Stack
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    {isMobile ? null : (
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Jobs
                            </Typography>
                            <YellowArrowRight />
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Leadership
                            </Typography>
                        </Stack>
                    )}

                    <Stack gap="8px">
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="#D8D8D8"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                Feb. 11, 2023
                            </Typography>
                            <DotIcon />
                            <Typography
                                component="span"
                                color="navTextColor.main"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                10 min read
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="12px"
                            py="4px"
                            pl="4px"
                            pr="16px"
                            // width="200px"
                            borderRadius={1}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                cursor: 'pointer'
                            }}
                        >
                            <Avatar
                                variant="square"
                                src={location.state.Profile}
                            />
                            <Typography
                                component="span"
                                color="primaryLight.main"
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontWeight: 500,
                                    lineHeight: '120%',
                                    letterSpacing: '-0.32px'
                                }}
                            >
                                by Samson Olanipekun
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                px={isMobile ? '4px' : undefined}
                pb={isMobile ? '50px' : '38px'}
                zIndex={2}
                maxWidth="914px"
                mx="auto"
                gap="20px"
            >
                <Typography
                    component="span"
                    variant="h2"
                    color="primaryDark"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: isMobile ? '20px' : '40px',
                        fontWeight: 600,
                        lineHeight: '120%',
                        letterSpacing: isMobile ? '-0.6px' : '-0.8px'
                    }}
                >
                    Leading The Way Servant Leadership: The Importance of
                    Treating Employees as Customers
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    The phrase “Servant Leadership” gets thrown around quite a
                    bit. Every leader (myself included) likely list this
                    leadership trait at the top of their resume. But can it
                    really be said that everyone is a servant leader? What in
                    actuality is servant leadership?
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    Servant leadership is a leadership philosophy in which the
                    leader's primary focus is on the needs and well-being of the
                    team or their employees. It is about putting the needs of
                    others first and leading by example. Now, let's ask
                    ourselves, how can we apply the principles of servant
                    leadership to  create a work environment where employees
                    feel valued, respected, and heard?
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    In my quest for answers, I reflected back on a question
                    raised recently by a fellow manager on a conference call,
                    she asked: Should Managers be trained to treat employees as
                    they would customers? I found this to be a profound thought
                    and decided to dig deeper. But first, I wanted to poll for
                    opinions, so I set up a LinkedIn poll where I asked the same
                    question almost verbatim: 
                    <Typography
                        component="span"
                        color="primaryDark.light"
                        sx={{
                            fontSize: isMobile ? '14px' : '20px',
                            fontWeight: 600,
                            lineHeight: '150%',
                            fontFamily: 'Outfit',
                            letterSpacing: isMobile ? '.28px' : '0.4px',
                            display: 'inline'
                        }}
                    >
                        Should Managers be trained to treat their Employees as
                        Customers?
                    </Typography>
                     60% of respondents agreed that the answer is a resounding
                    yes! While I recognize that the response rate for the poll
                    was low and the results cannot be considered to be
                    representative on the larger population, one can only wonder
                    what the results would have been with a higher participation
                    rate. That notwithstanding, the idea of treating employees
                    as customers is rooted in the philosophy that the most
                    valuable asset of any organization is its people. Employees
                    are the lifeblood of any business, and their contributions
                    and engagement are essential to its success. Therefore, it
                    is essential to create a work environment where employees
                    feel valued, respected, and heard. It is my sincere belief
                    that treating employees as you would a customer is not only
                    good for business, but essential for thriving in today's
                    competitive environment.
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    I crave your indulgence to be patient with me as I cannot
                    truly do justice to this topic with a short essay, so this
                    will be a little longer than my typical writing. But I
                    promise it will be worthwhile. Now let’s get started.
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    Customer Relationship Management (CRM) as a concept is the
                    process of building, maintaining, and improving
                    relationships with customers. It's a strategy that companies
                    use to acquire, retain, and grow their customer base. A key
                    aspect of CRM is understanding and addressing the needs of
                    customers, which leads to increased customer satisfaction
                    and loyalty. At its very core, the objectives of a good CRM
                    process are simple and straightforward, namely: Improving
                    customer service, increasing sales, retaining more ‘paying’
                    customers, higher efficiency, Better knowledge sharing and
                    more transparency (Source: CRM.org). Next, lets now discuss
                    each of these objectives and apply them to how we manage
                    employee relationships.
                </Typography>
                <Stack gap="20px">
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Improving customer service
                            </Typography>{' '}
                            equals{' '}
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Improving employee experience
                            </Typography>
                             through engagement and empowerment. For our
                            customers, we are always available with rapid
                            response to questions and problems when they arise.
                            This makes the customers feel valued and confident
                            that they can count on us as a trusted partner. When
                            it comes to our employees, they are the foot
                            soldiers who deliver on the products and services
                            that generate revenue for the company,  it makes
                            sense then to give the same level of attention and
                            dedication to employees experience.  So, by
                            understanding and addressing the needs of employees,
                            we can create a culture of trust and understanding.
                            This will lead to increased engagement and
                            satisfaction, employee loyalty, increased retention,
                            attraction of top talent and ultimately improved
                            bottom-line results.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Increase in sales
                            </Typography>{' '}
                            equals{' '}
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                 Increase in productivity.
                            </Typography>
                            What would you do to increase your sales by 50%? Or
                            perhaps a better question is what would you not do
                            to increase your sales by 50%? For most business
                            leaders and sales professional, we would do whatever
                            it takes under the boundary of law and ethics to
                            grow sales and increase our position. We will make
                            all the right investments, cold-calling and
                            cold-emailing, etc. Same with our employees, we want
                            to get the best out of them for all the investments
                            we made and continue to make in them. When employees
                            feel valued and respected, they are more likely to
                            be invested in their work and committed to the
                            success of the organization, resulting in increased
                            productivity, higher job satisfaction, and
                            ultimately, a more engaged and motivated workforce.
                            Now you have people who are willing to go above and
                            beyond for you and your customers, resulting in a
                            win-win situation for all.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Retaining more ‘paying’ customers
                            </Typography>{' '}
                            equals{' '}
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                 attracting and retaining top talent.
                            </Typography>
                             In today's job market, competition for the best and
                            brightest is fierce. People who work in sales or any
                            customer-facing role understand that they must
                            constantly deliver superior products and /or
                            services to make a case to the customers Why they
                            should choose you and why they should stay with you.
                            Only when you do that can you guarantee success for
                            your business. Likewise, attracting and retaining
                            highly motivated and productive employees is
                            critical to the success of any organization, as it
                            not only saves on recruitment and training costs but
                            also ensures a stable workforce. Organizations that
                            are able to create a culture of engagement and
                            empowerment where employees are invested in their
                            work and committed to the success of the
                            organization will be more successful in attracting
                            and retaining top talent and will thrive better than
                            those that take the traditional approach to employee
                            relationship management.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Higher efficiency
                            </Typography>{' '}
                            equals{' '}
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                 healthy and productive workforce.
                            </Typography>
                             A healthy and productive workforce is essential to
                            achieving long-term financial success. The primary
                            goal of every business leader is to build an
                            efficient product/service delivery processes that
                            meet the needs of the customers at the best speed
                            and at the lowest cost possible. To achieve that,
                            you must invest in the right technologies, ensure a
                            healthy and sound value chain and build a reasonable
                            redundancy in your process to be able to respond to
                            uncertainty while avoiding supply-demand
                            disequilibrium. Treating employees as customers
                            helps to foster a healthy and productive workforce,
                            giving them the necessary tools to be productive,
                            fostering a work-life balance culture and avoiding
                            micromanagement will result in better physical,
                            mental, and emotional health, leading to fewer
                            absences and a more productive workforce. This, in
                            turn, leads to improved bottom-line results for your
                            organization.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Better knowledge sharing
                            </Typography>{' '}
                            equals{' '}
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                 employee development and growth. 
                            </Typography>
                            With customers, better knowledge sharing allows us
                            the opportunity to be proactive in understanding and
                            solving their pain points before our competitors do.
                            We stay close to the customers, checking in from
                            time to time, seeking for new ways we can help
                            improve their workflow, save cost, avoid risk, etc.
                            Similarly, we can do the same for our employees by
                            investing in their development and growth. This
                            includes providing opportunities for training and
                            professional development, as well as creating a
                            culture of continuous learning and growth. This
                            means treating them with the same respect and
                            attention that you would give to your external
                            customers. It means listening to their needs and
                            wants, addressing their concerns, and taking steps
                            to improve their work experience.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                More transparency
                            </Typography>{' '}
                            equals{' '}
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                open, effective communication and constructive
                                feedback.
                            </Typography>
                             No customer wants to do business with a company
                            that is reluctant or slow in sharing information or
                            worse still is not receptive to feedback. To build a
                            strong relationship and remain relevant with
                            customers, businesses must be willing to be
                            transparent in sharing data and actionable insights
                            that help the customers make informed decisions
                            about their businesses. At the same token, must be
                            willing and open to accepting feedback, even
                            actively soliciting such feedback.  In some cases,
                            information or feedback being shared are not always
                            positive or even well received, but they must be
                            shared. Similarly, a transparent leader is an
                            empathetic leader who listen more than they speak,
                            and by “listen”, I mean active listening with intent
                            to understand and follow up with appropriate
                            actions! By actively listening to employee concerns
                            and addressing them with empathy, you can create a
                            culture of trust and understanding. You also will
                            not hold back in providing feedback even when they
                            are unpalatable. But by all means always be gracious
                            and publicly acknowledge and appreciate your
                            employees when they have major accomplishments. Be
                            sure to allow them take credit for their work.
                            Finally Encourage and support problem-solving and
                            empower your employees to take ownership of their
                            work and contribute to the organization's success.
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Of course, there are also those who argue that
                            employees and customers are fundamentally different
                            and therefore cannot be treated the same. They argue
                            that treating employees like customers may lead to:
                        </Typography>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                1.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                 Employees being too demanding or entitled
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                2.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Employees neglecting their job responsibilities
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                3.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Lack of differentiation between employees and
                                customers
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                4.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Lack of effective management
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                5.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Loss of authority and control
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    While it is true that there are some areas where the
                    approach may need to be slightly different when dealing with
                    employees versus customers, the core principles remain the
                    same. Employees, like customers, want to be valued,
                    respected, and treated with dignity. Furthermore, it is
                    important to note that treating employees as customers does
                    not mean neglecting or undermining the management hierarchy.
                    It is about finding a balance between empowering employees
                    and maintaining a clear chain of command. By fostering a
                    culture of mutual respect, managers can create a positive
                    work environment that benefits everyone.
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    Finally, I strongly encourage leaders to adopt this practice
                    in 2023 and beyond. Treating employees as customers should
                    not be seen as a mere leadership style, that are practiced
                    by some leaders and not others. It should be a new{' '}
                    <Typography
                        component="span"
                        color="primaryDark.light"
                        sx={{
                            fontSize: isMobile ? '14px' : '20px',
                            fontWeight: 600,
                            lineHeight: '150%',
                            fontFamily: 'Outfit',
                            letterSpacing: isMobile ? '.28px' : '0.4px',
                            display: 'inline'
                        }}
                    >
                        paradigm
                    </Typography>
                     that is institutionalized and embedded in our management
                    practice across the board. I will even go as far as to coin
                    a new concept - “Employee Relationship Management (ERM)”.
                     There you have it! ERM is not only good for business, but
                    essential for creating a healthy and productive workforce –
                    It is an effective model for business success. I will
                    conclude by saying that those leaders who treat employees as
                    disposable resources today will pay hefty prize for this
                    mistake in the long run. Let us continue to lead the way in
                    shaping the future of work by embracing ERM!
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    As always, I would love to hear your thoughts and insights
                    in this area. Share your thoughts and experiences by using
                    the
                    {/* hashtag #employeeexperience #employeeengagement #employeeretention #toptalent #customerservice #productivity */}
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    Let us continue to lead the way in shaping the future of
                    human resources management.
                </Typography>
            </Stack>
        </Stack>
    );
};

export default BlogDetails3;
