import React from 'react';

const ShareIcon = () => {
    return (
        <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.0503 25.4601C40.3103 25.4601 39.6903 24.9001 39.6103 24.1601C39.1303 19.7601 36.7703 15.8001 33.1303 13.2801C32.4703 12.8201 32.3103 11.9201 32.7703 11.2601C33.2303 10.6001 34.1303 10.4401 34.7903 10.9001C39.1303 13.9201 41.9303 18.6401 42.5103 23.8601C42.5903 24.6601 42.0103 25.3801 41.2103 25.4601C41.1503 25.4601 41.1103 25.4601 41.0503 25.4601Z"
                fill="white"
            />
            <path
                d="M7.81066 25.5599C7.77066 25.5599 7.71066 25.5599 7.67066 25.5599C6.87066 25.4799 6.29066 24.7599 6.37066 23.9599C6.91066 18.7399 9.67066 14.0199 13.9707 10.9799C14.6107 10.5199 15.5307 10.6799 15.9907 11.3199C16.4507 11.9799 16.2907 12.8799 15.6507 13.3399C12.0507 15.8999 9.71065 19.8599 9.27065 24.2399C9.19065 24.9999 8.55066 25.5599 7.81066 25.5599Z"
                fill="white"
            />
            <path
                d="M32.3132 42.2002C29.8532 43.3802 27.2132 43.9802 24.4532 43.9802C21.5732 43.9802 18.8332 43.3402 16.2732 42.0402C15.5532 41.7002 15.2732 40.8202 15.6332 40.1002C15.9732 39.3802 16.8532 39.1002 17.5732 39.4402C18.8332 40.0802 20.1732 40.5202 21.5332 40.7802C23.3732 41.1402 25.2532 41.1602 27.0932 40.8402C28.4532 40.6002 29.7932 40.1802 31.0332 39.5802C31.7732 39.2402 32.6532 39.5202 32.9732 40.2602C33.3332 40.9802 33.0532 41.8602 32.3132 42.2002Z"
                fill="white"
            />
            <path
                d="M24.433 4.02002C21.333 4.02002 18.793 6.54002 18.793 9.66002C18.793 12.78 21.313 15.3 24.433 15.3C27.553 15.3 30.073 12.78 30.073 9.66002C30.073 6.54002 27.553 4.02002 24.433 4.02002Z"
                fill="white"
            />
            <path
                d="M10.433 27.7402C7.33297 27.7402 4.79297 30.2602 4.79297 33.3802C4.79297 36.5002 7.31297 39.0202 10.433 39.0202C13.553 39.0202 16.073 36.5002 16.073 33.3802C16.073 30.2602 13.533 27.7402 10.433 27.7402Z"
                fill="white"
            />
            <path
                d="M38.2338 27.7402C35.1338 27.7402 32.5938 30.2602 32.5938 33.3802C32.5938 36.5002 35.1138 39.0202 38.2338 39.0202C41.3538 39.0202 43.8738 36.5002 43.8738 33.3802C43.8738 30.2602 41.3538 27.7402 38.2338 27.7402Z"
                fill="white"
            />
        </svg>
    );
};

export default ShareIcon;
