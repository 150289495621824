import {
    Container,
    Typography,
    Stack,
    useTheme,
    useMediaQuery
} from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import background from '../../Assets/Images/heroBackground.png';
import YellowArrowRight from '../../Assets/Icons/YellowArrowRight';
import BlogList from '../BlogList/BlogList';
import Newsletter from '../Newsletter/Newsletter';
import BlogDetails1 from './BlogDetails1';
import BlogDetails2 from './BlogDetails2';
import BlogDetails3 from './BlogDetails3';
import BlogDetails4 from './BlogDetails4';

type BlogSectionProps = {
    formik?: any;
};
const BlogDetails = ({ formik }: BlogSectionProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    return (
        <Stack
            position="relative"
            sx={{
                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`,
                backgroundBlendMode: 'overlay'
            }}
        >
            <Stack
                // width="100%"
                // height="100%"
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                right={0}
                sx={{
                    backgroundImage: `url(${background})`,
                    // backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain'
                }}
            ></Stack>
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    pt: isMobile ? '90px' : '100px',
                    px: isMobile ? '8px' : '60px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    gap: isMobile ? '20px' : '50px'
                }}
            >
                {isMobile ? (
                    <Stack
                        pl="8px"
                        pt="12px"
                        flexDirection="row"
                        alignItems="center"
                        gap="8px"
                    >
                        <Typography
                            color="primary2.main"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '120%'
                            }}
                        >
                            Technology
                        </Typography>
                        <YellowArrowRight />
                        <Typography
                            color="primary2.main"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '120%'
                            }}
                        >
                            Data Analysis
                        </Typography>
                    </Stack>
                ) : null}
                {location.state.blogid === 1 && <BlogDetails1 />}
                {location.state.blogid === 2 && <BlogDetails2 />}
                {location.state.blogid === 3 && <BlogDetails3 />}
                {location.state.blogid === 4 && <BlogDetails4 />}
                <Stack gap="14px" px={isMobile ? '8px' : undefined}>
                    <BlogList
                        blogId={location.state.blogid}
                        title="More For You"
                    />
                </Stack>
            </Container>
            <Stack mt={isMobile ? '50px' : '41px'} zIndex={2}>
                <Newsletter formik={formik} />
            </Stack>
        </Stack>
    );
};

export default BlogDetails;
