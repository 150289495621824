import React from 'react';

const ArrowLeftIcon = () => {
    return (
        <svg
            width="96"
            height="64"
            viewBox="0 0 96 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1871_2234)">
                <path
                    d="M66.5818 42.0399L63.1018 35.0799C62.1418 33.1399 62.1418 30.8799 63.1018 28.9399L66.5818 21.9599C69.5618 15.9999 63.1418 9.69995 57.2418 12.8199L54.1618 14.4599C53.7218 14.6799 53.3818 15.0399 53.2018 15.4799L41.8218 40.7799C41.3618 41.8199 41.7818 43.0399 42.7818 43.5599L57.2618 51.1799C63.1418 54.2999 69.5618 47.9999 66.5818 42.0399Z"
                    fill="#FFC24C"
                />
                <path
                    d="M39.3815 39.2L46.8415 22.64C47.6815 20.78 45.6815 18.9 43.8815 19.86L32.3415 25.94C27.4415 28.52 27.4415 35.52 32.3415 38.1L36.4215 40.24C37.5215 40.8 38.8615 40.34 39.3815 39.2Z"
                    fill="#FFC24C"
                />
            </g>
            <rect
                x="-0.5"
                y="0.5"
                width="95"
                height="63"
                rx="31.5"
                transform="matrix(-1 0 0 1 95 0)"
                stroke="#FFC24C"
            />
            <defs>
                <clipPath id="clip0_1871_2234">
                    <rect
                        width="48"
                        height="48"
                        fill="white"
                        transform="matrix(-1 0 0 1 72 8)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ArrowLeftIcon;
