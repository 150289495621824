import React from 'react';

const DreamJobIcon4 = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#56E39F" />
            <path
                d="M45.0286 25.0672L43.9619 22.6672L41.6152 17.3872C40.9886 16.0005 40.0019 14.8672 37.7352 14.8672H26.2686C24.0019 14.8672 23.0152 16.0005 22.3886 17.3872L20.0419 22.6672L18.9752 25.0672C18.3619 26.4672 18.6952 28.5205 19.7219 29.6539L28.8552 39.7072C30.5886 41.6139 33.4152 41.6139 35.1486 39.7072L44.2819 29.6539C45.3086 28.5205 45.6419 26.4672 45.0286 25.0672Z"
                fill="white"
            />
        </svg>
    );
};

export default DreamJobIcon4;
