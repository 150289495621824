import React from 'react';

const YellowRectangleMobile = () => {
    return (
        <svg
            width="236"
            height="285"
            viewBox="0 0 236 285"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.402344"
                width="234.875"
                height="284.848"
                rx="20"
                fill="#FFF9ED"
            />
        </svg>
    );
};

export default YellowRectangleMobile;
