import React from 'react';

const KeyboardIcon = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M36.54 6.7C35.6 6.56 34.52 6.5 33 6.5H15C13.5 6.5 12.4 6.56 11.52 6.68C4.82 7.42 3.5 11.4 3.5 18V30C3.5 36.6 4.82 40.58 11.46 41.3C12.4 41.44 13.48 41.5 15 41.5H33C34.5 41.5 35.6 41.44 36.48 41.32C43.18 40.58 44.5 36.62 44.5 30V18C44.5 11.4 43.18 7.42 36.54 6.7ZM20.18 18C21.3 18 22.2 18.9 22.2 20C22.2 21.1 21.3 22 20.2 22C19.1 22 18.2 21.1 18.2 20C18.2 18.9 19.08 18 20.18 18ZM14.18 18C15.32 18 16.2 18.9 16.2 20C16.2 21.1 15.3 22 14.2 22C13.1 22 12.2 21.1 12.2 20C12.2 18.9 13.08 18 14.18 18ZM34 32.5H14.04C13.22 32.5 12.52 31.82 12.52 31C12.52 30.18 13.18 29.5 14 29.5H34C34.82 29.5 35.5 30.18 35.5 31C35.5 31.82 34.82 32.5 34 32.5ZM34 21.5H27C26.18 21.5 25.5 20.82 25.5 20C25.5 19.18 26.18 18.5 27 18.5H34C34.82 18.5 35.5 19.18 35.5 20C35.5 20.82 34.82 21.5 34 21.5Z"
                fill="white"
            />
        </svg>
    );
};

export default KeyboardIcon;
