import React from 'react';

const DreamPurple = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#EF6CE2" />
            <path
                d="M21.5038 33.6563L21.5031 33.6637L21.5026 33.671C21.4794 33.995 21.5933 34.3148 21.803 34.5478C22.0235 34.7928 22.3482 34.9267 22.668 34.9267H32.0013C32.321 34.9267 32.6458 34.7928 32.8663 34.5478C33.0725 34.3187 33.1962 33.9922 33.1514 33.649C32.9105 31.1714 30.9202 29.2176 28.4487 28.9823L28.4483 28.9822C27.7104 28.9126 26.9456 28.9126 26.2077 28.9822L26.2072 28.9823C23.73 29.2182 21.7546 31.1786 21.5038 33.6563ZM24.0013 16.5H40.0013C42.6751 16.5 44.8346 18.6527 44.8346 21.2933V34.7067C44.8346 37.3473 42.6751 39.5 40.0013 39.5H24.0013C21.3275 39.5 19.168 37.3473 19.168 34.7067V21.2933C19.168 18.6527 21.3275 16.5 24.0013 16.5ZM30.9146 24.64C30.9146 22.6705 29.3041 21.06 27.3346 21.06C25.3652 21.06 23.7546 22.6705 23.7546 24.64C23.7546 26.6095 25.3652 28.22 27.3346 28.22C29.3041 28.22 30.9146 26.6095 30.9146 24.64ZM38.668 34.8333H41.3346C42.1574 34.8333 42.8346 34.1561 42.8346 33.3333C42.8346 32.5105 42.1574 31.8333 41.3346 31.8333H38.668C37.8452 31.8333 37.168 32.5105 37.168 33.3333C37.168 34.1561 37.8452 34.8333 38.668 34.8333ZM36.0013 29.5H41.3346C42.1574 29.5 42.8346 28.8228 42.8346 28C42.8346 27.1772 42.1574 26.5 41.3346 26.5H36.0013C35.1785 26.5 34.5013 27.1772 34.5013 28C34.5013 28.8228 35.1785 29.5 36.0013 29.5ZM34.668 24.1667H41.3346C42.1574 24.1667 42.8346 23.4895 42.8346 22.6667C42.8346 21.8439 42.1574 21.1667 41.3346 21.1667H34.668C33.8452 21.1667 33.168 21.8439 33.168 22.6667C33.168 23.4895 33.8452 24.1667 34.668 24.1667Z"
                fill="white"
                stroke="white"
            />
        </svg>
    );
};

export default DreamPurple;
