import React from 'react';

const SmsIconYellow = () => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="vuesax/bold/sms">
                <g id="sms">
                    <path
                        id="Vector"
                        d="M14.168 3.41675H5.83464C3.33464 3.41675 1.66797 4.66675 1.66797 7.58342V13.4167C1.66797 16.3334 3.33464 17.5834 5.83464 17.5834H14.168C16.668 17.5834 18.3346 16.3334 18.3346 13.4167V7.58342C18.3346 4.66675 16.668 3.41675 14.168 3.41675ZM14.5596 8.49175L11.9513 10.5751C11.4013 11.0167 10.7013 11.2334 10.0013 11.2334C9.3013 11.2334 8.59297 11.0167 8.0513 10.5751L5.44297 8.49175C5.1763 8.27508 5.13464 7.87508 5.34297 7.60841C5.55964 7.34175 5.9513 7.29175 6.21797 7.50841L8.8263 9.59175C9.45964 10.1001 10.5346 10.1001 11.168 9.59175L13.7763 7.50841C14.043 7.29175 14.443 7.33341 14.6513 7.60841C14.868 7.87508 14.8263 8.27508 14.5596 8.49175Z"
                        fill="#FFC24C"
                    />
                </g>
            </g>
        </svg>
    );
};

export default SmsIconYellow;
