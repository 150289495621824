import {
    Box,
    TextField,
    Container,
    useTheme,
    useMediaQuery,
    Typography,
    Divider,
    ImageList,
    ImageListItem,
    Stack
} from '@mui/material';
import React from 'react';
import BlueLeft from '../../Assets/Icons/BlueLeft';
import YellowLogo from '../../Assets/Icons/YellowLogo';
import BlueRight from '../../Assets/Icons/BlueRight';
import SmsIconYellow from '../../Assets/Icons/SmsIconYellow';
import YellowRectangle from '../../Assets/Icons/YellowRectangle';
import DotsBlue from '../../Assets/Icons/DotsBlue';
import DotsYellow from '../../Assets/Icons/DotsYellow';
import ArrowRightIcon1 from '../../Assets/Icons/ArrowRightIcon1';
import background from '../../Assets/Images/heroBackground.png';
import { useAppDispatch } from '../../Feature/Store/Store';
import { openVideoModalState } from '../../Feature/App/AppSlice';
import SmsIconBlue from '../../Assets/Icons/SmsIconBlue';
import YellowRectangleMobile from '../../Assets/Icons/YellowRectangleMobile';
import backgroundMobile from '../../Assets/Images/Group 1000004697.png';
import bj from '../../Assets/Images/3 (1).png';
import play from '../../Assets/Images/play.png';

type GetVideoSectionProps = {
    formik: any;
};

const GetVideoSection = ({ formik }: GetVideoSectionProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matches = useMediaQuery('(max-width:1230px)');
    const medium = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack
            //zIndex={-1}
            marginBottom={medium ? 0 : '170px'}
            pt={medium ? '60px' : '140px'}
            pb={medium ? '96px' : '76px'}
            mt={0}
            position="relative"
            // height="971px"
            sx={{
                background: medium
                    ? `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`
                    : '#FCFBF8'
            }}
        >
            <Stack
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                right={0}
                sx={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            ></Stack>
            {medium ? null : (
                <Stack
                    //zIndex={1}
                    width="100%"
                    height="100%"
                    maxWidth="1536px"
                    mx="auto"
                    position="relative"
                >
                    <Box
                        position="absolute"
                        maxWidth={'1440px'}
                        sx={{
                            top: medium ? 0 : '-300px',
                            left: matches ? '-90px' : 0,
                            overflow: 'hidden'
                            // bgcolor: '#FCFBF8'
                        }}
                    >
                        <YellowLogo />
                    </Box>
                </Stack>
            )}
            {medium ? (
                <Box
                    position="absolute"
                    sx={{
                        width: '100%',
                        bottom: '-50px',
                        left: 0,
                        overflow: 'hidden'
                    }}
                >
                    <YellowLogo />
                </Box>
            ) : null}

            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    px: medium ? '20px' : '60px',
                    position: 'relative'
                }}
            >
                {matches ? (
                    <Stack gap="52px" position="relative">
                        <Stack
                            sx={{
                                backgroundColor: '#05668D',
                                flexDirection: medium ? 'column' : 'row',
                                alignItems: 'center',
                                gap: medium ? '52px' : '100px',
                                borderRadius: matches ? '12px' : '24px',
                                position: 'relative',
                                py: matches ? '53px' : '42px',
                                pl: matches ? '16px' : '88px',
                                backgroundImage: `url(${backgroundMobile})`,
                                backgroundPosition: 'center',
                                backgroundBlendMode: 'overlay'
                            }}
                        >
                            {medium ? (
                                <Stack
                                    width="100%"
                                    bottom={0}
                                    top={0}
                                    left={0}
                                    right={0}
                                    position="absolute"
                                >
                                    <img
                                        width="100%"
                                        height="100%"
                                        src={backgroundMobile}
                                        alt=""
                                    />
                                </Stack>
                            ) : null}
                            {medium ? null : (
                                <Stack
                                    position="absolute"
                                    flexDirection="row"
                                    bottom={0}
                                    top={0}
                                    right={0}
                                >
                                    <BlueLeft />
                                    <BlueRight />
                                </Stack>
                            )}
                            <Box position="relative">
                                <Typography
                                    sx={{
                                        color: '#FFF',
                                        fontFamily: '',
                                        fontSize: medium ? '36px ' : '53px',
                                        fontWeight: '600',
                                        fontStyle: 'normal',
                                        lineHeight: medium
                                            ? '46.8px'
                                            : '68.9px',
                                        letterSpacing: medium
                                            ? '-1.8'
                                            : '-2.65px'
                                    }}
                                >
                                    Sign up for a free video masterclass
                                </Typography>
                                <Box
                                    sx={{
                                        color: '#FAFAFA',
                                        marginTop: '8px'
                                    }}
                                >
                                    <Typography
                                        fontSize={medium ? '14px' : '20px'}
                                        fontWeight={300}
                                        fontFamily="outfit"
                                        lineHeight={medium ? '19.6px' : '30px'}
                                    >
                                        The ultimate guide to efficient
                                        recruitment: Top strategies to attract
                                        and attain the best talent.
                                    </Typography>
                                    <Box marginRight="20px" marginTop="20px">
                                        <Stack
                                            direction="row"
                                            useFlexGap
                                            alignItems="center"
                                            bgcolor="red"
                                            px="20px"
                                            maxWidth="518px"
                                            // mb={medium ? 8 : '10px'}
                                            // mr={medium ? '20px' : 0}
                                            sx={{
                                                height: '68px',
                                                maxWidth: '650px',
                                                backgroundColor: '#F2F2F2',
                                                borderBottomWidth: '2px',
                                                borderBottomStyle: 'solid',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <TextField
                                                id="email"
                                                type="email"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                name="email"
                                                autoComplete="Email Address"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                sx={{
                                                    flex: 1,
                                                    color: 'primary2.main',
                                                    fontSize: '14px'
                                                }}
                                                placeholder="Where should we send your video?"
                                                InputProps={{
                                                    startAdornment: (
                                                        <>
                                                            {matches ? (
                                                                <SmsIconBlue />
                                                            ) : (
                                                                <SmsIconYellow />
                                                            )}
                                                            <Divider
                                                                orientation="vertical"
                                                                variant="middle"
                                                                sx={{
                                                                    mx: '12px',
                                                                    height: '24px'
                                                                }}
                                                            />
                                                        </>
                                                    ),
                                                    disableUnderline: true,
                                                    endAdornment: (
                                                        <Box
                                                            sx={{
                                                                transition:
                                                                    'all .4s ease',
                                                                cursor: 'pointer',
                                                                alignSelf:
                                                                    'center',
                                                                marginLeft:
                                                                    '12px',
                                                                '&:hover': {
                                                                    opacity: 0.8
                                                                }
                                                            }}
                                                            onClick={() =>
                                                                dispatch(
                                                                    openVideoModalState()
                                                                )
                                                            }
                                                        >
                                                            <ArrowRightIcon1 />
                                                        </Box>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                            <Stack alignItems="center">
                                <Box position="relative">
                                    <YellowRectangleMobile />
                                    <Box
                                        position={'absolute'}
                                        top={'50%'}
                                        left="-40px"
                                        sx={{ transform: 'translate(0, -50%)' }}
                                    >
                                        <ImageList
                                            cols={1}
                                            sx={{
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <ImageListItem
                                                sx={{
                                                    width: matches
                                                        ? '290px'
                                                        : '550px',
                                                    maxWidth: matches
                                                        ? '352px'
                                                        : '550px',
                                                    height: '296px',
                                                    flexShrink: 'unset'
                                                }}
                                            >
                                                <img src={bj} alt="logo" />
                                            </ImageListItem>
                                        </ImageList>
                                        <Stack
                                            width={matches ? '270px' : '550px'}
                                            maxWidth={
                                                matches ? '352px' : '550px'
                                            }
                                            onClick={() =>
                                                dispatch(openVideoModalState())
                                            }
                                            position="absolute"
                                            left={'50%'}
                                            bottom={'32px'}
                                            zIndex={2}
                                            sx={{
                                                transform: 'translateX(-50%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <img src={play} alt="play" />
                                        </Stack>
                                        <Stack
                                            position="absolute"
                                            left={0}
                                            right={0}
                                            // top={0}
                                            bottom={0}
                                            borderRadius="8px"
                                            zIndex={-1}
                                            sx={{
                                                background:
                                                    'linear-gradient(170deg, rgba(255, 255, 255, 0.00) 0%, rgba(21, 21, 21, 0.56) 100%)'
                                            }}
                                        ></Stack>
                                    </Box>
                                    <Box
                                        position={'absolute'}
                                        top={'60px'}
                                        right={'-50px'}
                                    >
                                        <DotsYellow />
                                    </Box>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Box
                        position="relative"
                        maxWidth="1136px"
                        mx="auto"
                        sx={{
                            background: '#05668D',
                            align: 'center',
                            borderRadius: '24px',
                            position: 'relative',
                            py: '42px',
                            pl: '88px',
                            pr: '44px'
                        }}
                    >
                        <Stack
                            position="absolute"
                            flexDirection="row"
                            bottom={0}
                            top={0}
                            right={0}
                        >
                            <BlueLeft />
                            <BlueRight />
                        </Stack>
                        <Box position="relative">
                            <Typography
                                sx={{
                                    color: '#FFf',
                                    fontFamily: 'Poppins',
                                    fontSize: '53px',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    lineHeight: '68.9px',
                                    letterSpacing: '-2.65px'
                                }}
                            >
                                Sign up for a free
                                <br /> video masterclass
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                color: '#FAFAFA',
                                marginTop: '8px'
                            }}
                        >
                            <Typography
                                fontSize="20px"
                                fontWeight={300}
                                fontFamily="Outfit"
                                lineHeight="30px"
                            >
                                The ultimate guide to efficient recruitment: Top
                                strategies
                                <br /> to attract and attain the best talent.
                            </Typography>
                            <Box marginRight="20px" marginTop="20px">
                                <Stack
                                    direction="row"
                                    useFlexGap
                                    alignItems="center"
                                    px="20px"
                                    width="518px"
                                    // mb={medium ? 8 : '10px'}
                                    // mr={medium ? '20px' : 0}
                                    sx={{
                                        height: '68px',
                                        maxWidth: '650px',
                                        backgroundColor: '#FFF9ED',
                                        borderBottomWidth: '2px',
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: 'primary2.main'
                                    }}
                                >
                                    <TextField
                                        id="email"
                                        type="email"
                                        variant="standard"
                                        margin="normal"
                                        required
                                        name="email"
                                        autoComplete="Email Address"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        sx={{
                                            flex: 1,
                                            color: 'primary2.main',
                                            fontSize: '18px'
                                        }}
                                        placeholder="Where should we send your video?"
                                        InputProps={{
                                            startAdornment: (
                                                <>
                                                    <SmsIconYellow />
                                                    <Divider
                                                        orientation="vertical"
                                                        variant="middle"
                                                        sx={{
                                                            mx: '12px',
                                                            height: '24px'
                                                        }}
                                                    />
                                                </>
                                            ),
                                            disableUnderline: true,
                                            endAdornment: (
                                                <Box
                                                    sx={{
                                                        transition:
                                                            'all .4s ease',
                                                        cursor: 'pointer',
                                                        alignSelf: 'center',
                                                        marginLeft: '12px',
                                                        '&:hover': {
                                                            opacity: 0.8
                                                        }
                                                    }}
                                                    onClick={() =>
                                                        dispatch(
                                                            openVideoModalState()
                                                        )
                                                    }
                                                >
                                                    <ArrowRightIcon1 />
                                                </Box>
                                            )
                                        }}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                        <Stack>
                            <Stack
                                zIndex={2}
                                position="relative"
                                bgcolor="red"
                                alignItems="flex-end"
                                justifyContent="center"
                            >
                                <Stack position="absolute">
                                    <YellowRectangle />
                                </Stack>
                                <Box
                                    position={'absolute'}
                                    // top={'50px'}
                                    right={'-30px'}
                                >
                                    <ImageList
                                        sx={{
                                            borderRadius: '8px'
                                        }}
                                        cols={1}
                                    >
                                        <ImageListItem>
                                            <img src={bj} alt="logo" />
                                        </ImageListItem>
                                    </ImageList>
                                    <Stack
                                        onClick={() =>
                                            dispatch(openVideoModalState())
                                        }
                                        position="absolute"
                                        left={'50%'}
                                        bottom={'32px'}
                                        zIndex={2}
                                        sx={{
                                            transform: 'translateX(-50%)',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <img src={play} alt="play" />
                                    </Stack>
                                    <Stack
                                        position="absolute"
                                        left={0}
                                        right={0}
                                        // top={0}
                                        bottom={0}
                                        borderRadius="8px"
                                        zIndex={-1}
                                        sx={{
                                            background:
                                                'linear-gradient(170deg, rgba(255, 255, 255, 0.00) 0%, rgba(21, 21, 21, 0.56) 100%)'
                                        }}
                                    ></Stack>
                                    <Box
                                        position={'absolute'}
                                        bottom={'-25px'}
                                        left={'-30px'}
                                    >
                                        <DotsBlue />
                                    </Box>
                                    <Box
                                        position={'absolute'}
                                        top="10px"
                                        right="-35px"
                                    >
                                        <DotsYellow />
                                    </Box>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                )}
            </Container>
        </Stack>
    );
};

export default GetVideoSection;
