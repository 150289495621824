import React from 'react';

const FacebookIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5995 4.4332H14.1661V1.7832C13.4076 1.70432 12.6454 1.66538 11.8828 1.66653C9.61615 1.66653 8.06615 3.04986 8.06615 5.5832V7.76653H5.50781V10.7332H8.06615V18.3332H11.1328V10.7332H13.6828L14.0661 7.76653H11.1328V5.87486C11.1328 4.99986 11.3661 4.4332 12.5995 4.4332Z"
                fill="#808080"
            />
        </svg>
    );
};

export default FacebookIcon;
