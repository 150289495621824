import React from 'react';

const CloseIconModal = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" fill="white" />
            <path
                d="M29.4277 29.4282L10.5715 10.5721"
                stroke="#494949"
                stroke-linecap="round"
            />
            <path
                d="M29.4285 10.5721L10.5723 29.4282"
                stroke="#494949"
                stroke-linecap="round"
            />
        </svg>
    );
};

export default CloseIconModal;
