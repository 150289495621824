import React from 'react';

const GroupYellow = () => {
    return (
        <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 102.728)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 80.6255)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 58.5232)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 36.4207)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 54.207 32)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 76.3086 32)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 98.4102 32)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 86.728)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 64.6255)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 42.5232)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 20.4207)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 38.207 16)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 60.3086 16)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 82.4102 16)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 70.728)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 48.6255)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 26.5232)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 4.42065)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 22.207 0)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 44.3086 0)"
                fill="#E8B145"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 66.4102 0)"
                fill="#E8B145"
            />
        </svg>
    );
};

export default GroupYellow;
