import React from 'react';

const ArrowRightIcon = () => {
    return (
        <svg
            width="96"
            height="64"
            viewBox="0 0 96 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1871_2227)">
                <path
                    d="M29.4182 42.0399L32.8982 35.0799C33.8582 33.1399 33.8582 30.8799 32.8982 28.9399L29.4182 21.9599C26.4382 15.9999 32.8582 9.69995 38.7582 12.8199L41.8382 14.4599C42.2782 14.6799 42.6182 15.0399 42.7982 15.4799L54.1782 40.7799C54.6382 41.8199 54.2182 43.0399 53.2182 43.5599L38.7382 51.1799C32.8582 54.2999 26.4382 47.9999 29.4182 42.0399Z"
                    fill="#05668D"
                />
                <path
                    d="M56.6185 39.2L49.1585 22.64C48.3185 20.78 50.3185 18.9 52.1185 19.86L63.6585 25.94C68.5585 28.52 68.5585 35.52 63.6585 38.1L59.5785 40.24C58.4785 40.8 57.1385 40.34 56.6185 39.2Z"
                    fill="#05668D"
                />
            </g>
            <rect
                x="0.5"
                y="0.5"
                width="95"
                height="63"
                rx="31.5"
                stroke="#05668D"
            />
            <defs>
                <clipPath id="clip0_1871_2227">
                    <rect
                        width="48"
                        height="48"
                        fill="white"
                        transform="translate(24 8)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ArrowRightIcon;
