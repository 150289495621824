import React from 'react';

const BlueRight = () => {
    return (
        <svg
            width="585"
            height="402"
            viewBox="0 0 585 402"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.8">
                <path
                    d="M0.5 0.5H82.9286V79.9H0.5V0.5ZM501.071 0.5H583.5V79.9H501.071V0.5ZM501.071 80.9H583.5V160.3H501.071V80.9ZM501.071 161.3H583.5V240.7H501.071V161.3ZM501.071 241.7H583.5V321.1H501.071V241.7ZM501.071 322.1H583.5V401.5H501.071V322.1ZM500.071 322.1V401.5H417.643V322.1H500.071ZM416.643 322.1V401.5H334.214V322.1H416.643ZM333.214 322.1V401.5H250.786V322.1H333.214ZM249.786 322.1V401.5H167.357V322.1H249.786ZM166.357 322.1V401.5H83.9286V322.1H166.357ZM82.9286 322.1V401.5H0.5V322.1H82.9286ZM82.9286 321.1H0.5V241.7H82.9286V321.1ZM82.9286 240.7H0.5V161.3H82.9286V240.7ZM82.9286 160.3H0.5V80.9H82.9286V160.3ZM83.9286 160.3V80.9H166.357V160.3H83.9286ZM167.357 160.3V80.9H249.786V160.3H167.357ZM249.786 79.9H167.357V0.5H249.786V79.9ZM250.786 160.3V80.9H333.214V160.3H250.786ZM333.214 79.9H250.786V0.5H333.214V79.9ZM334.214 160.3V80.9H416.643V160.3H334.214ZM416.643 79.9H334.214V0.5H416.643V79.9ZM417.643 160.3V80.9H500.071V160.3H417.643ZM500.071 79.9H417.643V0.5H500.071V79.9ZM417.643 161.3H500.071V240.7H417.643V161.3ZM417.643 241.7H500.071V321.1H417.643V241.7ZM416.643 241.7V321.1H334.214V241.7H416.643ZM333.214 241.7V321.1H250.786V241.7H333.214ZM249.786 241.7V321.1H167.357V241.7H249.786ZM166.357 241.7V321.1H83.9286V241.7H166.357ZM166.357 240.7H83.9286V161.3H166.357V240.7ZM167.357 240.7V161.3H249.786V240.7H167.357ZM250.786 240.7V161.3H333.214V240.7H250.786ZM334.214 240.7V161.3H416.643V240.7H334.214ZM83.9286 0.5H166.357V79.9H83.9286V0.5Z"
                    stroke="url(#paint0_radial_1362_11784)"
                />
                <mask id="path-2-inside-1_1362_11784" fill="white">
                    <path d="M56.7305 267.514H83.4276V248.817C83.4276 244.399 79.8459 240.817 75.4276 240.817H56.7305V267.514Z" />
                </mask>
                <path
                    d="M56.7305 267.514H83.4276V248.817C83.4276 244.399 79.8459 240.817 75.4276 240.817H56.7305V267.514Z"
                    fill="url(#paint1_linear_1362_11784)"
                />
                <path
                    d="M56.7305 267.514H83.4276H56.7305ZM84.4276 248.817C84.4276 243.846 80.3982 239.817 75.4276 239.817H56.7305V241.817H75.4276C79.2936 241.817 82.4276 244.951 82.4276 248.817H84.4276ZM56.7305 240.817V267.514V240.817ZM84.4276 267.514V248.817C84.4276 243.846 80.3982 239.817 75.4276 239.817V241.817C79.2936 241.817 82.4276 244.951 82.4276 248.817V267.514H84.4276Z"
                    fill="url(#paint2_radial_1362_11784)"
                    mask="url(#path-2-inside-1_1362_11784)"
                />
                <mask id="path-4-inside-2_1362_11784" fill="white">
                    <path d="M390.445 27.2515H417.142V8.55432C417.142 4.13604 413.561 0.554321 409.142 0.554321H390.445V27.2515Z" />
                </mask>
                <path
                    d="M390.445 27.2515H417.142V8.55432C417.142 4.13604 413.561 0.554321 409.142 0.554321H390.445V27.2515Z"
                    fill="url(#paint3_linear_1362_11784)"
                />
                <path
                    d="M390.445 27.2515H417.142H390.445ZM418.142 8.55432C418.142 3.58376 414.113 -0.445679 409.142 -0.445679H390.445V1.55432H409.142C413.008 1.55432 416.142 4.68833 416.142 8.55432H418.142ZM390.445 0.554321V27.2515V0.554321ZM418.142 27.2515V8.55432C418.142 3.58376 414.113 -0.445679 409.142 -0.445679V1.55432C413.008 1.55432 416.142 4.68833 416.142 8.55432V27.2515H418.142Z"
                    fill="url(#paint4_radial_1362_11784)"
                    mask="url(#path-4-inside-2_1362_11784)"
                />
                <mask id="path-6-inside-3_1362_11784" fill="white">
                    <path d="M26.6953 106.497H-0.00183105V87.7999C-0.00183105 83.3816 3.57989 79.7999 7.99817 79.7999H26.6953V106.497Z" />
                </mask>
                <path
                    d="M26.6953 106.497H-0.00183105V87.7999C-0.00183105 83.3816 3.57989 79.7999 7.99817 79.7999H26.6953V106.497Z"
                    fill="url(#paint5_linear_1362_11784)"
                />
                <path
                    d="M26.6953 106.497H-0.00183105H26.6953ZM-1.00183 87.7999C-1.00183 82.8294 3.02761 78.7999 7.99817 78.7999H26.6953V80.7999H7.99817C4.13218 80.7999 0.998169 83.9339 0.998169 87.7999H-1.00183ZM26.6953 79.7999V106.497V79.7999ZM-1.00183 106.497V87.7999C-1.00183 82.8294 3.02761 78.7999 7.99817 78.7999V80.7999C4.13218 80.7999 0.998169 83.9339 0.998169 87.7999V106.497H-1.00183Z"
                    fill="url(#paint6_radial_1362_11784)"
                    mask="url(#path-6-inside-3_1362_11784)"
                />
                <mask id="path-8-inside-4_1362_11784" fill="white">
                    <path d="M193.137 294.628H166.44V313.326C166.44 317.744 170.021 321.326 174.44 321.326H193.137V294.628Z" />
                </mask>
                <path
                    d="M193.137 294.628H166.44V313.326C166.44 317.744 170.021 321.326 174.44 321.326H193.137V294.628Z"
                    fill="url(#paint7_linear_1362_11784)"
                />
                <path
                    d="M193.137 294.628H166.44H193.137ZM165.44 313.326C165.44 318.296 169.469 322.326 174.44 322.326H193.137V320.326H174.44C170.574 320.326 167.44 317.192 167.44 313.326H165.44ZM193.137 321.326V294.628V321.326ZM165.44 294.628V313.326C165.44 318.296 169.469 322.326 174.44 322.326V320.326C170.574 320.326 167.44 317.192 167.44 313.326V294.628H165.44Z"
                    fill="url(#paint8_radial_1362_11784)"
                    mask="url(#path-8-inside-4_1362_11784)"
                />
                <mask id="path-10-inside-5_1362_11784" fill="white">
                    <path d="M193.137 27.2515H166.44V8.55432C166.44 4.13604 170.021 0.554321 174.44 0.554321H193.137V27.2515Z" />
                </mask>
                <path
                    d="M193.137 27.2515H166.44V8.55432C166.44 4.13604 170.021 0.554321 174.44 0.554321H193.137V27.2515Z"
                    fill="url(#paint9_linear_1362_11784)"
                />
                <path
                    d="M193.137 27.2515H166.44H193.137ZM165.44 8.55432C165.44 3.58376 169.469 -0.445679 174.44 -0.445679H193.137V1.55432H174.44C170.574 1.55432 167.44 4.68833 167.44 8.55432H165.44ZM193.137 0.554321V27.2515V0.554321ZM165.44 27.2515V8.55432C165.44 3.58376 169.469 -0.445679 174.44 -0.445679V1.55432C170.574 1.55432 167.44 4.68833 167.44 8.55432V27.2515H165.44Z"
                    fill="url(#paint10_radial_1362_11784)"
                    mask="url(#path-10-inside-5_1362_11784)"
                />
                <mask id="path-12-inside-6_1362_11784" fill="white">
                    <path d="M473.875 348.023H500.572V329.326C500.572 324.907 496.99 321.326 492.572 321.326H473.875V348.023Z" />
                </mask>
                <path
                    d="M473.875 348.023H500.572V329.326C500.572 324.907 496.99 321.326 492.572 321.326H473.875V348.023Z"
                    fill="url(#paint11_linear_1362_11784)"
                />
                <path
                    d="M473.875 348.023H500.572H473.875ZM501.572 329.326C501.572 324.355 497.543 320.326 492.572 320.326H473.875V322.326H492.572C496.438 322.326 499.572 325.46 499.572 329.326H501.572ZM473.875 321.326V348.023V321.326ZM501.572 348.023V329.326C501.572 324.355 497.543 320.326 492.572 320.326V322.326C496.438 322.326 499.572 325.46 499.572 329.326V348.023H501.572Z"
                    fill="url(#paint12_radial_1362_11784)"
                    mask="url(#path-12-inside-6_1362_11784)"
                />
                <mask id="path-14-inside-7_1362_11784" fill="white">
                    <path d="M390.445 214.12H417.142V232.817C417.142 237.235 413.561 240.817 409.142 240.817H390.445V214.12Z" />
                </mask>
                <path
                    d="M390.445 214.12H417.142V232.817C417.142 237.235 413.561 240.817 409.142 240.817H390.445V214.12Z"
                    fill="url(#paint13_linear_1362_11784)"
                />
                <path
                    d="M390.445 214.12H417.142H390.445ZM418.142 232.817C418.142 237.788 414.113 241.817 409.142 241.817H390.445V239.817H409.142C413.008 239.817 416.142 236.683 416.142 232.817H418.142ZM390.445 240.817V214.12V240.817ZM418.142 214.12V232.817C418.142 237.788 414.113 241.817 409.142 241.817V239.817C413.008 239.817 416.142 236.683 416.142 232.817V214.12H418.142Z"
                    fill="url(#paint14_radial_1362_11784)"
                    mask="url(#path-14-inside-7_1362_11784)"
                />
                <mask id="path-16-inside-8_1362_11784" fill="white">
                    <path d="M557.305 187.006H584.002V168.308C584.002 163.89 580.42 160.308 576.002 160.308H557.305V187.006Z" />
                </mask>
                <path
                    d="M557.305 187.006H584.002V168.308C584.002 163.89 580.42 160.308 576.002 160.308H557.305V187.006Z"
                    fill="url(#paint15_linear_1362_11784)"
                />
                <path
                    d="M557.305 187.006H584.002H557.305ZM585.002 168.308C585.002 163.338 580.972 159.308 576.002 159.308H557.305V161.308H576.002C579.868 161.308 583.002 164.442 583.002 168.308H585.002ZM557.305 160.308V187.006V160.308ZM585.002 187.006V168.308C585.002 163.338 580.972 159.308 576.002 159.308V161.308C579.868 161.308 583.002 164.442 583.002 168.308V187.006H585.002Z"
                    fill="url(#paint16_radial_1362_11784)"
                    mask="url(#path-16-inside-8_1362_11784)"
                />
                <mask id="path-18-inside-9_1362_11784" fill="white">
                    <path d="M359.996 134.029H333.299V152.726C333.299 157.144 336.881 160.726 341.299 160.726H359.996V134.029Z" />
                </mask>
                <path
                    d="M359.996 134.029H333.299V152.726C333.299 157.144 336.881 160.726 341.299 160.726H359.996V134.029Z"
                    fill="url(#paint17_linear_1362_11784)"
                />
                <path
                    d="M359.996 134.029H333.299H359.996ZM332.299 152.726C332.299 157.696 336.328 161.726 341.299 161.726H359.996V159.726H341.299C337.433 159.726 334.299 156.592 334.299 152.726H332.299ZM359.996 160.726V134.029V160.726ZM332.299 134.029V152.726C332.299 157.696 336.328 161.726 341.299 161.726V159.726C337.433 159.726 334.299 156.592 334.299 152.726V134.029H332.299Z"
                    fill="url(#paint18_radial_1362_11784)"
                    mask="url(#path-18-inside-9_1362_11784)"
                />
                <mask id="path-20-inside-10_1362_11784" fill="white">
                    <path d="M526.852 53.52H500.154V72.2172C500.154 76.6354 503.736 80.2172 508.154 80.2172H526.852V53.52Z" />
                </mask>
                <path
                    d="M526.852 53.52H500.154V72.2172C500.154 76.6354 503.736 80.2172 508.154 80.2172H526.852V53.52Z"
                    fill="url(#paint19_linear_1362_11784)"
                />
                <path
                    d="M526.852 53.52H500.154H526.852ZM499.154 72.2172C499.154 77.1877 503.184 81.2172 508.154 81.2172H526.852V79.2172H508.154C504.288 79.2172 501.154 76.0832 501.154 72.2172H499.154ZM526.852 80.2172V53.52V80.2172ZM499.154 53.52V72.2172C499.154 77.1877 503.184 81.2172 508.154 81.2172V79.2172C504.288 79.2172 501.154 76.0832 501.154 72.2172V53.52H499.154Z"
                    fill="url(#paint20_radial_1362_11784)"
                    mask="url(#path-20-inside-10_1362_11784)"
                />
                <mask id="path-22-inside-11_1362_11784" fill="white">
                    <path d="M360.414 370.131H333.717V388.828C333.717 393.247 337.299 396.828 341.717 396.828H360.414V370.131Z" />
                </mask>
                <path
                    d="M360.414 370.131H333.717V388.828C333.717 393.247 337.299 396.828 341.717 396.828H360.414V370.131Z"
                    fill="url(#paint21_linear_1362_11784)"
                />
                <path
                    d="M360.414 370.131H333.717H360.414ZM332.717 388.828C332.717 393.799 336.746 397.828 341.717 397.828H360.414V395.828H341.717C337.851 395.828 334.717 392.694 334.717 388.828H332.717ZM360.414 396.828V370.131V396.828ZM332.717 370.131V388.828C332.717 393.799 336.746 397.828 341.717 397.828V395.828C337.851 395.828 334.717 392.694 334.717 388.828V370.131H332.717Z"
                    fill="url(#paint22_radial_1362_11784)"
                    mask="url(#path-22-inside-11_1362_11784)"
                />
                <mask id="path-24-inside-12_1362_11784" fill="white">
                    <path d="M223.586 214.12H250.283V232.817C250.283 237.235 246.701 240.817 242.283 240.817H223.586V214.12Z" />
                </mask>
                <path
                    d="M223.586 214.12H250.283V232.817C250.283 237.235 246.701 240.817 242.283 240.817H223.586V214.12Z"
                    fill="url(#paint23_linear_1362_11784)"
                />
                <path
                    d="M223.586 214.12H250.283H223.586ZM251.283 232.817C251.283 237.788 247.254 241.817 242.283 241.817H223.586V239.817H242.283C246.149 239.817 249.283 236.683 249.283 232.817H251.283ZM223.586 240.817V214.12V240.817ZM251.283 214.12V232.817C251.283 237.788 247.254 241.817 242.283 241.817V239.817C246.149 239.817 249.283 236.683 249.283 232.817V214.12H251.283Z"
                    fill="url(#paint24_radial_1362_11784)"
                    mask="url(#path-24-inside-12_1362_11784)"
                />
                <mask id="path-26-inside-13_1362_11784" fill="white">
                    <path d="M193.555 106.497H166.858V87.7999C166.858 83.3816 170.439 79.7999 174.858 79.7999H193.555V106.497Z" />
                </mask>
                <path
                    d="M193.555 106.497H166.858V87.7999C166.858 83.3816 170.439 79.7999 174.858 79.7999H193.555V106.497Z"
                    fill="url(#paint25_linear_1362_11784)"
                />
                <path
                    d="M193.555 106.497H166.858H193.555ZM165.858 87.7999C165.858 82.8294 169.887 78.7999 174.858 78.7999H193.555V80.7999H174.858C170.992 80.7999 167.858 83.9339 167.858 87.7999H165.858ZM193.555 79.7999V106.497V79.7999ZM165.858 106.497V87.7999C165.858 82.8294 169.887 78.7999 174.858 78.7999V80.7999C170.992 80.7999 167.858 83.9339 167.858 87.7999V106.497H165.858Z"
                    fill="url(#paint26_radial_1362_11784)"
                    mask="url(#path-26-inside-13_1362_11784)"
                />
                <mask id="path-28-inside-14_1362_11784" fill="white">
                    <path d="M557.305 370.131H584.002V388.828C584.002 393.247 580.42 396.828 576.002 396.828H557.305V370.131Z" />
                </mask>
                <path
                    d="M557.305 370.131H584.002V388.828C584.002 393.247 580.42 396.828 576.002 396.828H557.305V370.131Z"
                    fill="url(#paint27_linear_1362_11784)"
                />
                <path
                    d="M557.305 370.131H584.002H557.305ZM585.002 388.828C585.002 393.799 580.972 397.828 576.002 397.828H557.305V395.828H576.002C579.868 395.828 583.002 392.694 583.002 388.828H585.002ZM557.305 396.828V370.131V396.828ZM585.002 370.131V388.828C585.002 393.799 580.972 397.828 576.002 397.828V395.828C579.868 395.828 583.002 392.694 583.002 388.828V370.131H585.002Z"
                    fill="url(#paint28_radial_1362_11784)"
                    mask="url(#path-28-inside-14_1362_11784)"
                />
                <mask id="path-30-inside-15_1362_11784" fill="white">
                    <path d="M26.6953 370.131H-0.00183105V388.828C-0.00183105 393.247 3.57989 396.828 7.99817 396.828H26.6953V370.131Z" />
                </mask>
                <path
                    d="M26.6953 370.131H-0.00183105V388.828C-0.00183105 393.247 3.57989 396.828 7.99817 396.828H26.6953V370.131Z"
                    fill="url(#paint29_linear_1362_11784)"
                />
                <path
                    d="M26.6953 370.131H-0.00183105H26.6953ZM-1.00183 388.828C-1.00183 393.799 3.02761 397.828 7.99817 397.828H26.6953V395.828H7.99817C4.13218 395.828 0.998169 392.694 0.998169 388.828H-1.00183ZM26.6953 396.828V370.131V396.828ZM-1.00183 370.131V388.828C-1.00183 393.799 3.02761 397.828 7.99817 397.828V395.828C4.13218 395.828 0.998169 392.694 0.998169 388.828V370.131H-1.00183Z"
                    fill="url(#paint30_radial_1362_11784)"
                    mask="url(#path-30-inside-15_1362_11784)"
                />
                <path
                    d="M163 48C163 51.3137 160.314 54 157 54C153.686 54 151 51.3137 151 48C151 44.6863 153.686 42 157 42C160.314 42 163 44.6863 163 48Z"
                    fill="url(#paint31_linear_1362_11784)"
                />
                <path
                    d="M79 201C79 204.314 76.3137 207 73 207C69.6863 207 67 204.314 67 201C67 197.686 69.6863 195 73 195C76.3137 195 79 197.686 79 201Z"
                    fill="url(#paint32_linear_1362_11784)"
                />
                <path
                    d="M496 112C496 115.314 493.314 118 490 118C486.686 118 484 115.314 484 112C484 108.686 486.686 106 490 106C493.314 106 496 108.686 496 112Z"
                    fill="url(#paint33_linear_1362_11784)"
                />
                <path
                    d="M288 321C288 324.314 285.314 327 282 327C278.686 327 276 324.314 276 321C276 317.686 278.686 315 282 315C285.314 315 288 317.686 288 321Z"
                    fill="url(#paint34_linear_1362_11784)"
                />
                <path
                    d="M246 160C246 163.314 243.314 166 240 166C236.686 166 234 163.314 234 160C234 156.686 236.686 154 240 154C243.314 154 246 156.686 246 160Z"
                    fill="url(#paint35_linear_1362_11784)"
                />
                <path
                    d="M413 241C413 244.314 410.314 247 407 247C403.686 247 401 244.314 401 241C401 237.686 403.686 235 407 235C410.314 235 413 237.686 413 241Z"
                    fill="url(#paint36_linear_1362_11784)"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(395 129.5) rotate(164.15) scale(455.832 660.439)"
                >
                    <stop stopColor="#E6E6E6" stopOpacity="0.44" />
                    <stop offset="1" stopColor="#EDEDED" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_1362_11784"
                    x1="98.5066"
                    y1="215.356"
                    x2="74.4539"
                    y2="254.272"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint2_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(88.6187 233.648) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint3_linear_1362_11784"
                    x1="432.221"
                    y1="-24.9068"
                    x2="408.169"
                    y2="14.0095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint4_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(422.334 -6.61435) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint5_linear_1362_11784"
                    x1="-15.0808"
                    y1="54.3388"
                    x2="8.97187"
                    y2="93.2551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint6_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-5.19294 72.6313) rotate(-126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint7_linear_1362_11784"
                    x1="151.361"
                    y1="346.787"
                    x2="175.413"
                    y2="307.87"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint8_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(161.248 328.494) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint9_linear_1362_11784"
                    x1="151.361"
                    y1="-24.9068"
                    x2="175.413"
                    y2="14.0095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint10_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(161.248 -6.61435) rotate(-126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint11_linear_1362_11784"
                    x1="515.651"
                    y1="295.864"
                    x2="491.598"
                    y2="334.781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint12_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(505.763 314.157) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint13_linear_1362_11784"
                    x1="432.221"
                    y1="266.278"
                    x2="408.169"
                    y2="227.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint14_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(422.334 247.986) rotate(53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint15_linear_1362_11784"
                    x1="599.081"
                    y1="134.847"
                    x2="575.028"
                    y2="173.764"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint16_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(589.193 153.14) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint17_linear_1362_11784"
                    x1="318.22"
                    y1="186.187"
                    x2="342.273"
                    y2="147.27"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint18_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(328.108 167.894) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint19_linear_1362_11784"
                    x1="485.075"
                    y1="105.678"
                    x2="509.128"
                    y2="66.7619"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint20_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(494.963 87.3858) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint21_linear_1362_11784"
                    x1="318.638"
                    y1="422.29"
                    x2="342.691"
                    y2="383.373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint22_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(328.526 403.997) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint23_linear_1362_11784"
                    x1="265.362"
                    y1="266.278"
                    x2="241.309"
                    y2="227.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint24_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(255.474 247.986) rotate(53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint25_linear_1362_11784"
                    x1="151.779"
                    y1="54.3388"
                    x2="175.831"
                    y2="93.2551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint26_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(161.666 72.6313) rotate(-126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint27_linear_1362_11784"
                    x1="599.081"
                    y1="422.29"
                    x2="575.028"
                    y2="383.373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint28_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(589.193 403.997) rotate(53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint29_linear_1362_11784"
                    x1="-15.0808"
                    y1="422.29"
                    x2="8.97187"
                    y2="383.373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint30_radial_1362_11784"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-5.19294 403.997) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint31_linear_1362_11784"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_1362_11784"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_1362_11784"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_1362_11784"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_1362_11784"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_1362_11784"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" stopOpacity="0.42" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BlueRight;
