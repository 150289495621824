import React from 'react';
import {
    Stack,
    Container,
    Typography,
    useMediaQuery,
    useTheme,
    Box
} from '@mui/material';
import DreamJobScreen2ArrowLeft from '../../Assets/Icons/DreamJobScreen2ArrowLeft';
import DreamJobScreen2ArrowRight from '../../Assets/Icons/DreamJobScreen2ArrowRight';
import DreamJobScreen2Vector from '../../Assets/Icons/DreamJobScreen2Vector';
import BusinessMan from '../../Assets/Images/black-businessman-happy-expression 1.png';
import BusinessMan2 from '../../Assets/Images/black-businessman-happy-expression 1 (1).png';
import DreamJobCard from '../DreamJobCard/DreamJobCard';
import DreamJobIcon1 from '../../Assets/Icons/DreamJobIcon1';
import DreamJobIcon2 from '../../Assets/Icons/DreamJobIcon2';
import DreamJobIcon3 from '../../Assets/Icons/DreamJobIcon3';
import DreamJobIcon4 from '../../Assets/Icons/DreamJobIcon4';
import DreamJobEclipse1 from '../../Assets/Icons/DreamJobEclipse1';
import DreamJobEclipse2 from '../../Assets/Icons/DreamJobEclipse2';
import DreamJobEclipse3 from '../../Assets/Icons/DreamJobEclipse3';
import DreamJobEclipse4 from '../../Assets/Icons/DreamJobEclipse4';
import VectorBlue from '../../Assets/Icons/VectorBlue';
import DreamJobGroup from '../../Assets/Icons/DreamJobGroup';

type DreamJobScreenProps = {
    screen: number;
    setScreen: (screen: number) => void;
};

const DreamJobScreen2 = ({ screen, setScreen }: DreamJobScreenProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack
            data-aos="fade"
            data-aos-duration={2000}
            bgcolor="#E6F0F4"
            pt={isMedium ? '60px' : '92px'}
            pb={isMedium ? '123px' : '79px'}
            mb={isMobile ? undefined : '180px'}
            position="relative"
        >
            {isMobile ? (
                <Stack position="absolute" right={0} top={0}>
                    <VectorBlue />
                </Stack>
            ) : null}
            <Container
                maxWidth="xl"
                disableGutters
                sx={{ px: isMedium ? '16px' : '60px', position: 'relative' }}
            >
                <Stack>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography
                            color="primaryDark.main"
                            component="h2"
                            fontFamily="Poppins"
                            fontSize={isMedium ? '28px' : '48px'}
                            fontWeight={600}
                            lineHeight="120%"
                            letterSpacing={isMedium ? '-0.9px' : '-2.4px'}
                            maxWidth={isMedium ? '280px' : '617px'}
                            mb={isMobile ? '20px' : undefined}
                        >
                            Find amazing professionals ready to work
                        </Typography>
                        {isMedium ? (
                            <Stack width="200px" height="20px"></Stack>
                        ) : null}
                    </Stack>
                    {isMobile ? (
                        <Stack
                            alignSelf="flex-start"
                            py="8px"
                            px="24px"
                            bgcolor="#56E39F"
                            borderRadius="64px"
                            mb={isMobile ? '36px' : undefined}
                        >
                            <Typography
                                color="primaryLight.main"
                                component="h2"
                                fontFamily="Open Sans"
                                fontSize="14px"
                                fontWeight={700}
                                lineHeight="120%"
                            >
                                GET HIRED
                            </Typography>
                        </Stack>
                    ) : null}
                    {isMobile ? null : (
                        <Stack
                            flexDirection="row"
                            gap="16px"
                            mt="120px"
                            mb="148px"
                        >
                            <Box
                                sx={{
                                    transition: 'all .4s ease',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        opacity: 0.8
                                    }
                                }}
                            >
                                <DreamJobScreen2ArrowLeft
                                    bgColor="#FFC24C"
                                    screen={screen}
                                    setScreen={setScreen}
                                />
                            </Box>
                            <Box
                                sx={{
                                    transition: 'all .4s ease',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        opacity: 0.8
                                    }
                                }}
                            >
                                <DreamJobScreen2ArrowRight
                                    bgColor="#05668D"
                                    screen={screen}
                                    setScreen={setScreen}
                                />
                            </Box>
                        </Stack>
                    )}
                </Stack>
                {isMobile ? null : (
                    <Stack position="absolute" right="60px" top="31px">
                        <DreamJobScreen2Vector />
                    </Stack>
                )}
                {isMedium ? (
                    <Stack position="absolute" right={0} top="13px">
                        <img src={BusinessMan2} alt="business man" />
                    </Stack>
                ) : null}

                <Stack
                    top="179px"
                    right="60px"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap="20px"
                    position={isMobile ? undefined : 'absolute'}
                    zIndex={2}
                >
                    {isMobile ? null : (
                        <Stack position="absolute" top="-45px" left="-45px">
                            <DreamJobGroup />
                        </Stack>
                    )}
                    <Stack gap="20px">
                        <DreamJobCard
                            title="Make better hiring decisions in real time."
                            subTitle="Say goodbye to costly, time-consuming methods. Ditch the
                    resume reviews and tedious verifications. Jobfactor’s
                    cutting-edge AI and data-driven insights deliver
                    prequalified talent at your fingertips. Hire smarter,
                    faster, and with unwavering confidence."
                            StartIcon={DreamJobIcon1}
                            EndIcon={DreamJobEclipse1}
                        />
                        <DreamJobCard
                            title="Turn around the turnover"
                            subTitle="Skills and cultural mismatch in people and jobs are the leading cause of high turnover rate. Turn that around with Jobfactor’s innovative skill-based hiring platform that give you the most suitable candidates based on skills, cultural fit and candidate-employer values alignment."
                            StartIcon={DreamJobIcon2}
                            EndIcon={DreamJobEclipse2}
                        />
                    </Stack>
                    <Stack gap="88px">
                        {isMobile ? null : (
                            <Stack
                                alignSelf="flex-end"
                                py="8px"
                                px="24px"
                                bgcolor="primary.main"
                                borderRadius="64px"
                                width="152px"
                            >
                                <Typography
                                    color="primaryLight.main"
                                    component="h2"
                                    fontFamily="Open Sans"
                                    fontSize="20px"
                                    fontWeight={700}
                                    lineHeight="28px"
                                >
                                    GET HIRED
                                </Typography>
                            </Stack>
                        )}
                        <Stack gap="20px">
                            <DreamJobCard
                                title="Attract top talent"
                                subTitle="With our dynamic jobfactor score for employers, we will help you engage your employees to tell your company’s story to the world, making it easier to find and attract top talents."
                                StartIcon={DreamJobIcon3}
                                EndIcon={DreamJobEclipse3}
                            />
                            <DreamJobCard
                                title="Find the diamond in the rough"
                                subTitle="Jobfactor’s AI-driven scoring system and prequalification feature assess skills, experience, personality, and cultural fit. Discover the perfect candidate amidst hundreds of qualified applicants."
                                StartIcon={DreamJobIcon4}
                                EndIcon={DreamJobEclipse4}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
            {isMobile ? null : (
                <Stack bgcolor="#05668D" height="300px" position="relative">
                    <Stack
                        width="100%"
                        maxWidth="1536px"
                        position="relative"
                        mx="auto"
                        height="100%"
                    >
                        <Stack position="absolute" bottom={0} left="-60px">
                            <img src={BusinessMan} alt="business man" />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default DreamJobScreen2;
