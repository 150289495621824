import React from 'react';
import {
    Stack,
    Typography,
    Avatar,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import YellowArrowRight from '../../Assets/Icons/YellowArrowRight';
import DotIcon from '../../Assets/Icons/DotIcon';
import '@fontsource/outfit';
import blogDetailImage from '../../Assets/Images/blogdetail2image.png';

const BlogDetails2 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    return (
        <Stack flex={1}>
            <Stack
                zIndex={2}
                height={isMobile ? '238px' : '358px'}
                borderRadius={isMobile ? '20px' : '40px'}
                justifyContent="flex-end"
                px={isMobile ? '8px' : '48px'}
                pb={isMobile ? '16px' : '24px'}
                pt="48px"
                mb={isMobile ? '20px' : '60px'}
                sx={{
                    backgroundImage: `url(${blogDetailImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <Stack
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    {isMobile ? null : (
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Job
                            </Typography>
                            <YellowArrowRight />
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Work ethics
                            </Typography>
                        </Stack>
                    )}

                    <Stack gap="8px">
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="#D8D8D8"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                Feb. 11, 2023
                            </Typography>
                            <DotIcon />
                            <Typography
                                component="span"
                                color="#D8D8D8"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                10 min read
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="12px"
                            py="4px"
                            pl="4px"
                            pr="16px"
                            // width="200px"
                            borderRadius={1}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                cursor: 'pointer'
                            }}
                        >
                            <Avatar
                                variant="square"
                                src={location.state.Profile}
                            />
                            <Typography
                                component="span"
                                color="primaryLight.main"
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontWeight: 500,
                                    lineHeight: '120%',
                                    letterSpacing: '-0.32px'
                                }}
                            >
                                by Samson Olanipekun
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                px={isMobile ? '4px' : undefined}
                pb={isMobile ? '50px' : '38px'}
                zIndex={2}
                maxWidth="914px"
                mx="auto"
                gap="20px"
            >
                <Typography
                    component="span"
                    variant="h2"
                    color="primaryDark"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: isMobile ? '20px' : '40px',
                        fontWeight: 600,
                        lineHeight: '120%',
                        letterSpacing: isMobile ? '-0.6px' : '-0.8px'
                    }}
                >
                    Is It a Game of Chess? Aligning Employee and Employer
                    Interests
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    The past couple of years have been marked by significant
                    shifts in the employment landscape, including the Great
                    Resignation of 2021 and the growing popularity of remote
                    work. In fact, according to the U.S. Bureau of Labor
                    Statistics, over 47 million people in the United States
                    alone voluntarily left their jobs in 2021, leading to a
                    shortage of talent and an abundance of unfilled positions.
                    At the same time, many skilled workers have come to realize
                    the true value of their skills and are now demanding more
                    comprehensive compensation packages than in the past.
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    However, the employment outlook for 2023 and beyond is
                    uncertain, with some companies already laying off workers
                    and the possibility of an economic recession on the horizon.
                    In this environment, it's natural for both employees and
                    employers to be concerned about their future prospects and
                    many questions arise; Will workers still be able to demand
                    fair wages, flexible work schedule and all the perks they
                    have enjoyed in the last couple of years? Will those
                    employers who have been against remote work or any form of
                    hybrid work schedule see this as an opportunity to force
                    people back to the office? (Some already doing this).
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    But is it necessary for this to be a game of chess, with one
                    side trying to outmaneuver the other? Or is it possible for
                    the interests of both parties to align? The more I think
                    about this, the more I realize that at the core, we all want
                    the same things. Employees want to feel valued and
                    respected, have opportunities for growth and development,
                    and feel fulfilled in their work. Employers, on the other
                    hand, want a productive and motivated workforce, a positive
                    company culture, and ultimately, success. So how can we
                    align these seemingly disparate goals? To explore this
                    question, let's examine five key variables from both the
                    employee and employer perspective:
                </Typography>
                <Stack gap="20px">
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Pay and Benefits
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employees: "We want fair compensation and
                            comprehensive benefits that meet our needs and
                            reflect the value of our work."
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employers: "We want to attract and retain top
                            talent, but we also have to balance our budget and
                            stay competitive in the market."
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Flexible Work Schedule and Remote Work
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employees: "We want the flexibility to balance our
                            work and personal commitments, and the option to
                            work remotely can make that easier."
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employers: "We want a productive workforce, but we
                            also understand the benefits of flexible work
                            arrangements for both the employee and the
                            organization."
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Work-Life Balance
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employees: "We want a healthy balance between our
                            professional and personal lives, and we need support
                            from our employer to achieve that."
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employers: "We want a motivated and engaged
                            workforce, and we recognize that work-life balance
                            is an important factor in achieving that."
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Productivity
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employees: "We want to be productive and contribute
                            to the success of the organization, but we also need
                            the support and resources to do so."
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employers: "We want a productive workforce, and we
                            need to ensure that we are providing the necessary
                            support and resources to enable that."
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Career Growth
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employees: "We want opportunities for growth and
                            development within the organization, and we need
                            support to achieve those goals."
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Employers: "We want a skilled and capable workforce,
                            and we recognize the value of investing in employee
                            development."
                        </Typography>
                    </Stack>
                </Stack>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    See, ultimately, we all want the same things: to feel valued
                    and respected, have opportunities for growth and
                    development, and feel fulfilled in our work. Here are five
                    key strategies that leaders can adopt to bridge the divide
                    and play on the same team for long term success:
                </Typography>
                <Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            1.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Effective Communication: When employees and
                            employers are able to openly and honestly
                            communicate their needs and expectations, it fosters
                            a sense of trust and understanding. This not only
                            leads to a more harmonious workplace, but it also
                            allows for the identification and addressing of any
                            potential conflicts or misalignments before they
                            become major issues.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            2.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Clear Goals and Expectations: Having clear and
                            attainable goals and expectations is important for
                            both employees and employers. When employees
                            understand what is expected of them and have the
                            resources and support to meet those expectations,
                            they are more likely to feel fulfilled in their work
                            and be motivated to perform to the best of their
                            ability. Employers, on the other hand, benefit from
                            a productive and motivated workforce.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            3.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Growth and Development Opportunities: Offering
                            opportunities for growth and development is
                            beneficial for both employees and employers.
                            Employees want to feel like they are constantly
                            learning and improving, and this not only keeps them
                            engaged and motivated, but it also helps to retain
                            top talent. Employers, on the other hand, benefit
                            from a more skilled and capable workforce.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            4.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Make a Flexible Work Policy: Allowing for flexible
                            work arrangements, such as a flexible schedule or
                            the option to work remotely, can help employees
                            better balance their work and personal commitments.
                            This can lead to increased job satisfaction and
                            ultimately, a more productive workforce. So don't
                            treat it as a temporary arrangement that can be
                            discontinued, make it part of your HR policy.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            5.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Continued Commitment to Work-Life Balance Support:
                            Providing support for work-life balance, such as
                            access to resources like Employee Assistance
                            Programs or flexible leave policies, can help
                            employees feel more fulfilled and supported in their
                            work. This can lead to increased engagement and
                            motivation, ultimately benefiting the employer as
                            well.
                        </Typography>
                    </Stack>
                </Stack>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    By implementing these strategies, we can align the interests
                    of employees and employers and create a more productive and
                    successful workplace where everyone's needs are met.
                </Typography>
            </Stack>
        </Stack>
    );
};

export default BlogDetails2;
