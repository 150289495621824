import React from 'react';
import {
    Button,
    Container,
    Stack,
    Typography,
    Avatar,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '@fontsource/poppins';
import Arrow45Deg from '../../Assets/Icons/Arrow45Deg';
import blogImage from '../../Assets/Images/blogpost1image.png';
import bj from '../../Assets/Images/bjprofile.png';
import background from '../../Assets/Images/heroBackground.png';
import BlogList from '../BlogList/BlogList';
import Newsletter from '../Newsletter/Newsletter';
import BlogPost4 from '../BlogPost/BlogPost4';
import BlogPost3 from '../BlogPost/BlogPost3';

type BlogSectionProps = {
    formik?: any;
};
const BlogPageMainSection = ({ formik }: BlogSectionProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery('(max-width:350px)');

    const openBlogPost = () => {
        navigate(`/blog-details/1`, {
            state: {
                blogImage: blogImage,
                Profile: bj,
                blogid: 1
            }
        });
    };
    return (
        <Stack
            position="relative"
            sx={{
                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`
            }}
        >
            <Stack
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                right={0}
                sx={{
                    backgroundImage: `url(${background})`,
                    // backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain'
                }}
            ></Stack>
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    pt: '90px',
                    px: isMobile ? '8px' : '60px',
                    position: 'relative',
                    backgroundBlendMode: 'overlay'
                }}
            >
                <Stack
                    flexDirection={isMobile ? 'column' : 'row'}
                    gap="12px"
                    // px="8px"
                >
                    <Stack
                        onClick={openBlogPost}
                        zIndex={2}
                        flexDirection="column"
                        width="100%"
                        position="relative"
                        justifyContent="space-between"
                        maxWidth="1180px"
                        height={isMobile ? '345px' : '768px'}
                        sx={{
                            backgroundImage: `url(${blogImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                        borderRadius={isMobile ? '20px' : '40px'}
                    >
                        <Stack
                            position="absolute"
                            left={0}
                            right={0}
                            top={0}
                            bottom={0}
                            borderRadius={isMobile ? '20px' : '40px'}
                            zIndex={-1}
                            sx={{
                                background:
                                    'linear-gradient(170deg, rgba(255, 255, 255, 0.00) -32.58%, #030813 97.41%)'
                            }}
                        ></Stack>
                        <Stack
                            position="relative"
                            flexDirection="row"
                            justifyContent="space-between"
                            pr="12px"
                        >
                            <Stack
                                position="relative"
                                width={isMobile ? '200px' : '360px'}
                                height={isMobile ? '65px' : '120px'}
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    background: `#fff3f5`,
                                    borderBottomRightRadius: isMobile
                                        ? '16px'
                                        : '24px',
                                    boxShadow: '-14px -13px 0 #fff3f5',
                                    '&::before': {
                                        content: `""`,
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '50%',
                                        bgcolor: 'transparent',
                                        position: 'absolute',
                                        bottom: '-35px',
                                        left: 0,
                                        zIndex: -1,
                                        boxShadow: '-14px -13px 0 #fff3f5'
                                    },
                                    '&::after': {
                                        content: `""`,
                                        width: '35px',
                                        height: '35px',
                                        borderRadius: '50%',
                                        bgcolor: 'transparent',
                                        position: 'absolute',
                                        right: '-35px',
                                        top: 0,
                                        zIndex: -1,
                                        boxShadow: '-14px -13px 0 #fff3f5'
                                    }
                                }}
                            >
                                <Stack
                                    zIndex={2}
                                    px={isMobile ? '12px' : '20px'}
                                    py={isMobile ? '8px' : '20px'}
                                    sx={{ backgroundColor: 'primary2.main' }}
                                    borderRadius={isMobile ? '6px' : '12PX'}
                                >
                                    <Typography
                                        color="primaryLight.main"
                                        variant="h3"
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: isSmall
                                                ? '16px'
                                                : isMobile
                                                ? '20px'
                                                : '36px',
                                            fontWeight: 600,
                                            lineHeight: '32px',
                                            letterSpacing: isMobile
                                                ? '-.8px'
                                                : '-1.8px'
                                        }}
                                    >
                                        Featured Articles
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            gap={isMobile ? '8px' : '12px'}
                            alignItems="flex-end"
                            justifyContent="space-between"
                            pl={isMobile ? '12px' : 5}
                            pr={isMobile ? '12px' : 4}
                            pb={isMobile ? '17px' : 5}
                        >
                            <Stack width="100%" alignItems="flex-start">
                                <Typography
                                    color="primaryLight.main"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        lineHeight: '100%',
                                        pb: '12px'
                                    }}
                                >
                                    10 min read
                                </Typography>
                                <Typography
                                    maxWidth="584px"
                                    variant="h2"
                                    component="h1"
                                    color="primaryLight.main"
                                    sx={{
                                        fontFamily: 'Poppins',
                                        fontSize: isMobile ? '20px' : '60px',
                                        fontWeight: 600,
                                        lineHeight: '120%',
                                        letterSpacing: isMobile
                                            ? '-.6'
                                            : '-3px',
                                        pb: isMobile ? '12px' : '23px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Boost your hiring potential: 7 Resume Tips
                                    for 2023
                                </Typography>
                                <Stack
                                    flexDirection="row"
                                    flexWrap="wrap"
                                    gap={isMobile ? '8px' : '16px'}
                                    alignItems="center"
                                >
                                    <Button
                                        onClick={openBlogPost}
                                        sx={{
                                            py: '12px',
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            width: '134px'
                                        }}
                                        variant="contained"
                                        endIcon={<Arrow45Deg />}
                                    >
                                        Read article
                                    </Button>
                                    <Stack
                                        onClick={openBlogPost}
                                        flexDirection="row"
                                        alignItems="center"
                                        gap="12px"
                                        py="4px"
                                        pl="4px"
                                        pr="16px"
                                        // width="200px"
                                        borderRadius={1}
                                        sx={{
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.15)',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Avatar variant="square" src={bj} />
                                        <Typography
                                            color="primaryLight.main"
                                            sx={{
                                                fontSize: isMobile
                                                    ? '14px'
                                                    : '16px',
                                                fontWeight: 500,
                                                lineHeight: '120%',
                                                letterSpacing: '-0.32px'
                                            }}
                                        >
                                            by Samson Olanipekun
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        width={isMobile ? '100%' : undefined}
                        gap="12px"
                        justifyContent="space-between"
                    >
                        <BlogPost4
                            blogid={4}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                        <BlogPost3
                            blogid={3}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                    </Stack>
                </Stack>
                <Stack
                    width="100%"
                    maxWidth="1536px"
                    mx="auto"
                    alignSelf="flex-end"
                    // gap="14px"
                    // px={isMobile ? '8px' : undefined}
                >
                    <BlogList mainBlog={true} title="Most Popular Articles" />
                    <BlogList mainBlog={true} title="Quick Reads" />
                </Stack>
            </Container>
            <Stack mt={isMobile ? '50px' : '41px'} zIndex={2}>
                <Newsletter formik={formik} />
            </Stack>
        </Stack>
    );
};

export default BlogPageMainSection;
