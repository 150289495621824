import React from 'react';

const Logo = () => {
    return (
        <svg
            width="154"
            height="322"
            viewBox="0 0 154 322"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="path-1-inside-1_2908_3601" fill="white">
                <path d="M154 0C130.259 0 111.014 17.2583 111.014 38.5497V91.4762H21.4139C9.57992 91.4762 0 100.111 0 110.748V283.435C0 304.737 19.1598 322 42.8279 322V249.497H132.378C144.212 249.497 153.842 240.857 153.842 230.204V0.0722966M111.093 212.408H42.9068V131.332H111.093V212.408Z" />
            </mask>
            <path
                d="M111.014 91.4762V95.4762H115.014V91.4762H111.014ZM42.8279 322V326H46.8279V322H42.8279ZM42.8279 249.497V245.497H38.8279V249.497H42.8279ZM111.093 212.408V216.408H115.093V212.408H111.093ZM42.9068 212.408H38.9068V216.408H42.9068V212.408ZM42.9068 131.332V127.332H38.9068V131.332H42.9068ZM111.093 131.332H115.093V127.332H111.093V131.332ZM154 -4C128.47 -4 107.014 14.6505 107.014 38.5497H115.014C115.014 19.866 132.047 4 154 4V-4ZM107.014 38.5497V91.4762H115.014V38.5497H107.014ZM111.014 87.4762H21.4139V95.4762H111.014V87.4762ZM21.4139 87.4762C7.77592 87.4762 -4 97.5169 -4 110.748H4C4 102.704 11.3839 95.4762 21.4139 95.4762V87.4762ZM-4 110.748V283.435H4V110.748H-4ZM-4 283.435C-4 307.329 17.3547 326 42.8279 326V318C20.9649 318 4 302.144 4 283.435H-4ZM46.8279 322V249.497H38.8279V322H46.8279ZM42.8279 253.497H132.378V245.497H42.8279V253.497ZM132.378 253.497C146.006 253.497 157.842 243.459 157.842 230.204H149.842C149.842 238.255 142.417 245.497 132.378 245.497V253.497ZM157.842 230.204V0.0722966H149.842V230.204H157.842ZM111.093 208.408H42.9068V216.408H111.093V208.408ZM46.9068 212.408V131.332H38.9068V212.408H46.9068ZM42.9068 135.332H111.093V127.332H42.9068V135.332ZM107.093 131.332V212.408H115.093V131.332H107.093Z"
                fill="#FFC24C"
                mask="url(#path-1-inside-1_2908_3601)"
            />
        </svg>
    );
};

export default Logo;
