import React from 'react';

const DreamRed = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#EF6F6C" />
            <path
                d="M44.8213 22.412V22.4134V33.5868C44.8213 35.914 44.1295 37.7114 42.9144 38.9265C41.6993 40.1417 39.9019 40.8334 37.5746 40.8334H26.4146C24.0874 40.8334 22.2901 40.1417 21.0751 38.9251C19.8599 37.7084 19.168 35.9077 19.168 33.5734V22.4134C19.168 20.0862 19.8597 18.2888 21.0749 17.0736C22.29 15.8585 24.0874 15.1667 26.4146 15.1667H37.588C39.9153 15.1667 41.7125 15.8586 42.9259 17.0734C44.1391 18.2882 44.8277 20.0851 44.8213 22.412ZM36.3282 29.5619L36.3298 29.5597L39.4086 25.5879C39.4089 25.5875 39.4092 25.5872 39.4095 25.5868C39.92 24.9322 39.7807 24.0002 39.1446 23.4798L39.1433 23.4787C38.4953 22.9522 37.5338 23.0751 37.0312 23.7358L34.0474 27.585L31.7372 25.7671L31.7364 25.7666C31.1393 25.2985 30.3791 25.0869 29.6313 25.1841C28.8699 25.2813 28.2062 25.6716 27.7411 26.265L27.7385 26.2683L24.5652 30.3883L24.5652 30.3883L24.5628 30.3915C24.0671 31.0457 24.1809 31.985 24.8425 32.4893C25.12 32.7061 25.4418 32.8067 25.7613 32.8067C26.2058 32.8067 26.6444 32.6048 26.9404 32.2299L26.9404 32.2299L26.9441 32.2252L30.0222 28.2288L32.3321 30.0464L32.3329 30.0469C32.9286 30.5138 33.6734 30.7256 34.4328 30.63C35.2 30.547 35.8645 30.1534 36.3282 29.5619Z"
                fill="white"
                stroke="white"
            />
        </svg>
    );
};

export default DreamRed;
