import React from 'react';

const FacebookIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <g clipPath="url(#clip0_1565_6031)">
                <path
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                    fill="#05668D"
                />
                <path
                    d="M30.2889 7.01587H25.2255C22.2207 7.01587 18.8785 8.27965 18.8785 12.6352C18.8932 14.1529 18.8785 15.6064 18.8785 17.2421H15.4023V22.7737H18.9861V38.6982H25.5713V22.6686H29.9178L30.3111 17.2266H25.4579C25.4579 17.2266 25.4687 14.8058 25.4579 14.1027C25.4579 12.3815 27.2489 12.4801 27.3566 12.4801C28.2089 12.4801 29.866 12.4826 30.2914 12.4801V7.01587H30.2889Z"
                    fill="#FAFAFA"
                />
            </g>
            <defs>
                <clipPath id="clip0_1565_6031">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FacebookIcon;
