import React, { useEffect } from 'react';
import {
    Stack,
    Modal,
    TextField,
    Typography,
    Divider,
    Checkbox,
    Button,
    useTheme,
    useMediaQuery
} from '@mui/material';
import CloseIcon from '../../Assets/Icons/CloseIcon';
import SmsIconYellow from '../../Assets/Icons/SmsIconYellow';
import ProfileIcon from '../../Assets/Icons/ProfileIcon';
import Support24 from '../../Assets/Icons/Support24';
import CompleteIcon from '../../Assets/Icons/CompleteIcon';
import { useNavigate } from 'react-router-dom';
import SmsIconBlue from '../../Assets/Icons/SmsIconBlue';
import ProfileIconBlue from '../../Assets/Icons/ProfileIconBlue';
import { useAppSelector, useAppDispatch } from '../../Feature/Store/Store';
import {
    closeSignupModalState,
    successPopup,
    errorPopup
} from '../../Feature/App/AppSlice';
import ErrorIcon from '../../Assets/Icons/ErrorIcon';
import Loader from '../../Assets/Icons/Loader';
import axios from 'axios';

type SignupModalProps = {
    data?: number;
    error?: any;
    formik: any;
    isLoading?: boolean;
};

const SignupModal = ({ formik, data, error, isLoading }: SignupModalProps) => {
    const signupModalState = useAppSelector(
        (state) => state.app.signupModalState
    );
    const videoModalState = useAppSelector(
        (state) => state.app.videoModalState
    );
    const errorModalState = useAppSelector(
        (state) => state.app.errorModalState
    );
    const feedbackModalState = useAppSelector(
        (state) => state.app.feedbackModalState
    );
    const completeModalState = useAppSelector(
        (state) => state.app.completeModalState
    );

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (data) dispatch(successPopup());
        if (error) dispatch(errorPopup());
    }, [data, error, dispatch]);
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const sendInBlueQuestionMail = (e: any) => {
        e.preventDefault();
        const body = {
            sender: { email: 'contact@jobfactor.com', name: 'JobFactor' },
            to: [{ email: 'contact@jobfactor.com', name: 'JobFactor' }],
            replyTo: { email: formik.values.email },
            htmlContent: `<h4>Email:</h4> <p>${formik.values.email}</p> <h4>Full name:</h4> <p> ${formik.values.fullname}</p> <h4>Message:</h4><p>${formik.values.message}</p>`,
            subject: 'JobFactor Question'
        };
        axios
            .post('https://api.sendinblue.com/v3/smtp/email', body, {
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    'api-key': process.env.REACT_APP_API_KEY
                }
            })
            .then((response) => {
                dispatch(successPopup());
                formik.resetForm();
            })
            .catch((err) => dispatch(errorPopup()));
    };

    return (
        <>
            <Modal
                data-aos="zoom-out"
                open={signupModalState}
                onClose={() => dispatch(closeSignupModalState())}
            >
                {completeModalState ? (
                    <Stack
                        px={isMedium ? '16px' : '40px'}
                        py={isMedium ? undefined : '16px'}
                        pt={isMedium ? '43px' : undefined}
                        pb={isMedium ? '16px' : undefined}
                        position="absolute"
                        width="95%"
                        height={isMedium ? 'fit-content' : '461px'}
                        maxWidth="750px"
                        top="50%"
                        left="50%"
                        sx={{
                            backgroundColor: '#fff',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <Stack
                            height="100%"
                            gap="32px"
                            justifyContent={isMedium ? undefined : 'center'}
                            alignItems={isMedium ? undefined : 'center'}
                        >
                            <Stack gap="8px" alignItems="center">
                                <CompleteIcon />
                                <Typography
                                    textAlign="center"
                                    color="#494949"
                                    fontFamily="Poppins"
                                    fontSize="20px"
                                    fontWeight={700}
                                    lineHeight="28px"
                                >
                                    {videoModalState
                                        ? 'You’ve signed up for the video!'
                                        : feedbackModalState
                                        ? 'Your feedback has been submitted '
                                        : 'You’ve signed up for Beta!'}
                                </Typography>
                                <Typography
                                    color="navTextColor.main"
                                    fontSize="16px"
                                    fontWeight={300}
                                    lineHeight="24px"
                                    letterSpacing="0.08px"
                                >
                                    {videoModalState
                                        ? 'You’ll get the link to the video soon'
                                        : feedbackModalState
                                        ? 'Expect a response from us soon'
                                        : 'We will let you know when we are up.'}
                                </Typography>
                            </Stack>
                            <Button
                                onClick={() => {
                                    dispatch(closeSignupModalState());
                                    navigate('/');
                                }}
                                variant="contained"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '0.024px',
                                    textTransform: 'none',
                                    py: '10px',
                                    px: '40px',
                                    borderRadius: '8px'
                                }}
                            >
                                Continue
                            </Button>
                        </Stack>
                    </Stack>
                ) : errorModalState ? (
                    <Stack
                        px={isMedium ? '16px' : '40px'}
                        py={isMedium ? undefined : '16px'}
                        pt={isMedium ? '43px' : undefined}
                        pb={isMedium ? '16px' : undefined}
                        position="absolute"
                        width="95%"
                        height={isMedium ? 'fit-content' : '461px'}
                        maxWidth="750px"
                        top="50%"
                        left="50%"
                        sx={{
                            backgroundColor: '#fff',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <Stack
                            height="100%"
                            gap="32px"
                            justifyContent={isMedium ? undefined : 'center'}
                            alignItems={isMedium ? undefined : 'center'}
                        >
                            <Stack gap="8px" alignItems="center">
                                <ErrorIcon />
                                <Typography
                                    fontSize={isMedium ? '14px' : '16px'}
                                    fontWeight={300}
                                    lineHeight="130%"
                                    color="navTextColor.main"
                                >
                                    {error.response?.status === 400
                                        ? error.response.data.message
                                        : error.message}
                                </Typography>
                            </Stack>
                            <Button
                                onClick={() => {
                                    dispatch(closeSignupModalState());
                                    navigate('/');
                                }}
                                variant="contained"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '0.024px',
                                    textTransform: 'none',
                                    py: '10px',
                                    px: '40px',
                                    borderRadius: '8px'
                                }}
                            >
                                Continue
                            </Button>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack
                        px={isMedium ? '16px' : '32px'}
                        py={isMedium ? '20px' : '40px'}
                        position="absolute"
                        width="95%"
                        maxWidth="750px"
                        top="50%"
                        left="50%"
                        sx={{
                            backgroundColor: '#fff',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <Stack
                            flexDirection="row"
                            gap={isMedium ? '12px' : '16px'}
                            mb="32px"
                        >
                            {feedbackModalState ? <Support24 /> : null}
                            <Stack flex={1} gap="8px">
                                <Typography
                                    fontFamily="Poppins"
                                    fontSize="20px"
                                    fontWeight={600}
                                    lineHeight="140%"
                                    letterSpacing="-0.6px"
                                    color="primaryDark.main"
                                >
                                    {isMedium &&
                                    !feedbackModalState &&
                                    !videoModalState
                                        ? 'Sign up'
                                        : feedbackModalState
                                        ? 'Feedback'
                                        : videoModalState
                                        ? 'Sign up for a free video masterclass'
                                        : 'Get Exclusive Early Access to Our Upcoming Beta Launch Sign Up to our Beta Program Today.'}
                                </Typography>
                                <Typography
                                    fontSize={isMedium ? '14px' : '16px'}
                                    fontWeight={300}
                                    lineHeight="130%"
                                    color="navTextColor.main"
                                >
                                    {feedbackModalState
                                        ? 'Have any question or comment, Let us know'
                                        : videoModalState
                                        ? 'Drop your details below and you will get the link to the video.'
                                        : 'We will keep you in the know, so you are the first to gain access to this amazing product.'}
                                </Typography>
                            </Stack>
                            <Stack
                                onClick={() =>
                                    dispatch(closeSignupModalState())
                                }
                            >
                                <CloseIcon />
                            </Stack>
                        </Stack>
                        <form
                            onSubmit={
                                feedbackModalState
                                    ? sendInBlueQuestionMail
                                    : signupModalState
                                    ? formik.handleSubmit
                                    : handleSubmit
                            }
                        >
                            <Stack mb="12px">
                                <Stack
                                    sx={{
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        borderBottomColor:
                                            formik.touched.fullName &&
                                            formik.errors.fullName
                                                ? 'red'
                                                : '#c5c5c5',
                                        backgroundColor: '#fcfbf8'
                                    }}
                                    p="16px"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={isMedium ? '12px' : undefined}
                                >
                                    {isMedium ? (
                                        <ProfileIconBlue />
                                    ) : (
                                        <ProfileIcon />
                                    )}
                                    {isMedium ? null : (
                                        <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{
                                                mx: '20px',
                                                height: '15px'
                                            }}
                                        />
                                    )}
                                    <Stack flex={1}>
                                        <Typography
                                            fontSize="14px"
                                            fontWeight={400}
                                            lineHeight="20px"
                                            letterSpacing="0.049px"
                                            color="#747474"
                                        >
                                            Full Name*
                                        </Typography>

                                        <TextField
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 300,
                                                lineHeight: '24px',
                                                letterSpacing: '0.08px',
                                                color: '#999'
                                            }}
                                            id="fullName"
                                            name="fullName"
                                            type="text"
                                            value={formik.values.fullName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            // required
                                            fullWidth
                                            variant="standard"
                                            placeholder="Enter your full name"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                {/* {formik.touched.fullName &&
                                formik.errors.fullName ? (
                                    <Typography
                                        pl="16px"
                                        color="error"
                                        fontSize="14px"
                                    >
                                        {formik.errors.fullName}
                                    </Typography>
                                ) : null} */}
                            </Stack>
                            <Stack mb="20px">
                                <Stack
                                    sx={{
                                        borderBottomWidth: '1px',
                                        borderBottomStyle: 'solid',
                                        borderBottomColor:
                                            formik.touched.email &&
                                            formik.errors.email
                                                ? 'red'
                                                : '#c5c5c5',
                                        backgroundColor: '#fcfbf8'
                                    }}
                                    p="16px"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={isMedium ? '12px' : undefined}
                                >
                                    {isMedium ? (
                                        <SmsIconBlue />
                                    ) : (
                                        <SmsIconYellow />
                                    )}
                                    {isMedium ? null : (
                                        <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{
                                                mx: '20px',
                                                height: '24px'
                                            }}
                                        />
                                    )}
                                    <Stack flex={1}>
                                        <Typography
                                            fontSize="14px"
                                            fontWeight={400}
                                            lineHeight="20px"
                                            letterSpacing="0.049px"
                                            color="#747474"
                                        >
                                            Email Address*
                                        </Typography>
                                        <TextField
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 300,
                                                lineHeight: '24px',
                                                letterSpacing: '0.08px',
                                                color: '#999'
                                            }}
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                            name="email"
                                            type="email"
                                            id="email"
                                            onChange={formik.handleChange}
                                            // required
                                            fullWidth
                                            variant="standard"
                                            placeholder="Enter your Email Address"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                {/* {formik.touched.email &&
                                    formik.errors.email && (
                                        <Typography
                                            pl="16px"
                                            color="error"
                                            fontSize="14px"
                                        >
                                            {formik.errors.email}
                                        </Typography>
                                    )} */}
                            </Stack>
                            {feedbackModalState ? (
                                <Stack mb="20px">
                                    <Stack
                                        p="16px"
                                        sx={{
                                            borderBottomWidth: '1px',
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: '#c5c5c5',
                                            backgroundColor: '#fcfbf8'
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 300,
                                                lineHeight: '24px',
                                                letterSpacing: '0.08px',
                                                color: '#999'
                                            }}
                                            rows={4}
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="message"
                                            type="text"
                                            id="message"
                                            fullWidth
                                            variant="standard"
                                            multiline
                                            placeholder="Write your comment or question"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Stack>
                                    {formik.touched.message &&
                                        formik.errors.message && (
                                            <Typography
                                                pl="16px"
                                                color="error"
                                                fontSize="14px"
                                            >
                                                {formik.errors.message}
                                            </Typography>
                                        )}
                                </Stack>
                            ) : null}
                            <Stack mb="36px">
                                <Stack flexDirection="row" alignItems="center">
                                    <Checkbox
                                        id="checkbox"
                                        name="checkbox"
                                        checked={formik.values.checkbox}
                                        onChange={formik.handleChange}
                                    />
                                    <Typography
                                        fontSize={isMedium ? '12px' : '14px'}
                                        fontWeight={isMedium ? 300 : 400}
                                        lineHeight={isMedium ? '16px' : '20px'}
                                        letterSpacing={
                                            isMedium ? '0.06px' : '0.014px'
                                        }
                                        color="#494949"
                                    >
                                        I would love to get an early access for
                                        Beta
                                    </Typography>
                                </Stack>
                                {formik.touched.checkbox &&
                                    formik.errors.checkbox && (
                                        <Typography
                                            pl="16px"
                                            color="error"
                                            fontSize="14px"
                                        >
                                            {formik.errors.checkbox}
                                        </Typography>
                                    )}
                            </Stack>
                            <Stack>
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        lineHeight: '24px',
                                        letterSpacing: '0.024px',
                                        textAlign: 'center',
                                        textTransform: 'none',
                                        py: '16px'
                                    }}
                                >
                                    {isLoading ? (
                                        <Loader />
                                    ) : feedbackModalState ? (
                                        'Submit'
                                    ) : (
                                        'Sign up'
                                    )}
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                )}
            </Modal>
        </>
    );
};

export default SignupModal;
