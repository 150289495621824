import { Stack } from '@mui/material';
import React from 'react';

const CarouselRightArrow = ({ onClick }: any) => {
    return (
        <Stack
            sx={{ cursor: 'pointer' }}
            position="absolute"
            bottom={0}
            right={0}
            onClick={onClick}
        >
            <svg
                width="53"
                height="52"
                viewBox="0 0 53 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M35.7599 22.2266L20.3066 14.0932C16.6399 12.1599 12.6532 16.0666 14.5066 19.7732L16.6666 24.0932C17.2666 25.2932 17.2666 26.7066 16.6666 27.9066L14.5066 32.2266C12.6532 35.9332 16.6399 39.8266 20.3066 37.9066L35.7599 29.7732C38.7999 28.1732 38.7999 23.8266 35.7599 22.2266Z"
                    fill="#FFC24C"
                    stroke="#FFC24C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="0.789062"
                    y="0.75"
                    width="50.5"
                    height="50.5"
                    rx="25.25"
                    stroke="white"
                    strokeOpacity="0.5"
                    strokeWidth="1.5"
                />
            </svg>
        </Stack>
    );
};

export default CarouselRightArrow;
