import { useFormik } from 'formik';
import { useCreateUserData } from '../../Hooks/createUser';
import * as yup from 'yup';

const Form = () => {
    const { mutate, data, error, isLoading } = useCreateUserData();
    var emailReg = /^([\w\-.]+@([\w-]+\.)+[\w-]{2,13})?$/;
    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            message: '',
            checkbox: true
        },
        validationSchema: yup.object({
            fullName: yup
                .string()
                .max(25, 'Must be 25 characters or less')
                .required('Required'),
            email: yup
                .string()
                .matches(emailReg, 'Invalid email address')
                .required('Required'),
            message: yup.string().max(250, 'Must be 250 characters or less'),
            checkbox: yup
                .boolean()
                .required('Required')
                .oneOf([true], 'Required')
        }),
        onSubmit: (values, actions) => {
            const user = {
                fullName: values.fullName,
                email: values.email,
                checkbox: values.checkbox
            };
            mutate(user);

            actions.resetForm();
        }
    });
    return { formik, data, error, isLoading };
};

export default Form;
