import { Stack } from '@mui/material';
import React from 'react';

const DisabledCarouselLeftArrow = () => {
    return (
        <Stack position="absolute" zIndex={1} bottom={0} right="66px">
            <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.2811 29.7729L31.7344 37.9063C35.4011 39.8396 39.3878 35.9329 37.5344 32.2263L35.3744 27.9063C34.7744 26.7063 34.7744 25.2929 35.3744 24.0929L37.5344 19.7729C39.3878 16.0663 35.4011 12.1729 31.7344 14.0929L16.2811 22.2263C13.2411 23.8263 13.2411 28.1729 16.2811 29.7729Z"
                    fill="#808080"
                    stroke="#808080"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="0.75"
                    y="0.75"
                    width="50.5"
                    height="50.5"
                    rx="25.25"
                    stroke="#808080"
                    strokeWidth="1.5"
                />
            </svg>
        </Stack>
    );
};

export default DisabledCarouselLeftArrow;
