import React from 'react';
import {
    Stack,
    Container,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import background from '../../Assets/Images/OurApproachBackgroundTexture.png';
import '@fontsource/arsenal';
import CarouselCard from '../CarouselCard/CarouselCard';

const Carousel = () => {
    const theme = useTheme();
    const matches = useMediaQuery('(max-width:1439px)');
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack
            bgcolor="#05668D"
            sx={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundBlendMode: 'overlay'
            }}
        >
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    px: isMobile ? '16px' : '85px',
                    py: isMedium ? '47px' : '78px',
                    display: 'flex',
                    flexDirection: matches ? 'column' : 'row',
                    gap: isMedium ? '32px' : '80px',
                    alignItems: 'center'
                }}
            >
                <Stack
                    alignSelf="flex-start"
                    maxWidth={matches ? '500px' : '315px'}
                >
                    <Typography
                        fontFamily="Arsenal"
                        fontSize={isMedium ? '28px' : '36px'}
                        fontWeight={700}
                        lineHeight="120%"
                        mb="40px"
                        color="primaryLight.main"
                    >
                        Jobfactor: Unveiling the Future of Talent Acquisition
                    </Typography>
                    <Typography
                        component="span"
                        fontSize={isMedium ? '16px' : '20px'}
                        fontWeight={400}
                        lineHeight={isMedium ? '150%' : '130%'}
                        color="primaryLight.main"
                    >
                        Embrace Jobfactor and transform the way you approach
                        talent acquisition. Together we can shape a future where
                        trust and precision unite to create a vibrant talent
                        marketplace.
                        <br />
                        <br />
                        Join us on this exhilarating journey toward excellence.
                        {isMobile ? null : (
                            <Typography component="span">
                                <br />
                                <br />
                                Click on the arrow to view how{' '}
                                <Typography
                                    component="h2"
                                    display="inline"
                                    fontSize="24px"
                                    fontWeight={700}
                                    color="primary2.main"
                                >
                                    JOBFACTOR
                                </Typography>{' '}
                                unveils the future of talent acquisition using
                                our business model.
                            </Typography>
                        )}
                    </Typography>
                </Stack>
                <Stack width="100%">
                    <CarouselCard />
                </Stack>
                {isMobile ? (
                    <Stack alignSelf="flex-start">
                        <Typography
                            fontSize={isMedium ? '16px' : '20px'}
                            fontWeight={400}
                            lineHeight={isMedium ? '150%' : '130%'}
                            color="primaryLight.main"
                            mb="20px"
                        >
                            Swipe through to view how{' '}
                            <Typography
                                component="span"
                                display="inline"
                                fontSize="24px"
                                fontWeight={700}
                                color="primary2.main"
                            >
                                JOBFACTOR
                            </Typography>{' '}
                            unveils the future of talent acquisition using our
                            business model.
                        </Typography>
                    </Stack>
                ) : null}
            </Container>
        </Stack>
    );
};

export default Carousel;
