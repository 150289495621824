import React from 'react';

const SecondVector = () => {
    return (
        <svg
            width="155"
            height="100"
            viewBox="0 0 155 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_f_357_13653)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.1155 30.8213C94.1578 31.4469 104.497 33.1238 111.94 36.9494C120.058 41.1215 125.325 46.3344 124.175 51.9232C122.801 58.602 118.412 66.0401 105.355 68.9564C92.2907 71.8744 77.928 68.042 64.8016 65.1843C51.4468 62.2769 36.2673 59.5231 31.9963 52.9857C27.4708 46.0586 32.4229 38.039 43.8348 33.091C53.9654 28.6985 69.3358 30.0406 83.1155 30.8213Z"
                    fill="#56E39F"
                    fillOpacity="0.3"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_357_13653"
                    x="0.332031"
                    y="0"
                    width="154"
                    height="100"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="15"
                        result="effect1_foregroundBlur_357_13653"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default SecondVector;
