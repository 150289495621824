import React from 'react';
import {
    Container,
    Typography,
    useTheme,
    useMediaQuery,
    Stack,
    Box
} from '@mui/material';
import KeyboardIcon from '../../Assets/Icons/KeyboardIcon';
import ShareIcon from '../../Assets/Icons/ShareIcon';
import RecieptItemIcon from '../../Assets/Icons/RecieptItemIcon';
import LinkAddIcon from '../../Assets/Icons/LinkAddIcon';
import BackgroundImage from '../../Assets/Images/OurApproachBackgroundTexture.png';
import FirstVector from '../../Assets/Icons/FirstVector';
import SecondVector from '../../Assets/Icons/SecondVector';
import ThirdVector from '../../Assets/Icons/ThirdVector';
import FourthVector from '../../Assets/Icons/FourthVector';
import '@fontsource/poppins';

const OurApproach = () => {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Stack
            sx={{
                backgroundColor: 'primaryBlue.main',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundBlendMode: 'overlay'
            }}
        >
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    px: isMedium ? '16px' : '60px',
                    pb: '87px',
                    position: 'relative'
                }}
            >
                <Stack pt={isMedium ? '49px' : '125px'} zIndex={4}>
                    <Box
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-delay="300"
                        width="100%"
                        alignSelf={isMedium ? 'center' : 'flex-start'}
                        maxWidth="569px"
                        mb={isMedium ? '48px' : '100px'}
                        display="flex"
                        flexDirection="column"
                        alignItems={isMedium ? 'center' : undefined}
                    >
                        <Stack
                            alignSelf={isMedium ? 'center' : 'flex-start'}
                            px="28px"
                            py="20px"
                            mb={isMedium ? 3 : 5}
                            sx={{
                                backgroundColor: 'primary2.main',
                                borderRadius: '12px'
                            }}
                        >
                            <Typography
                                variant="h2"
                                color="primaryLight.main"
                                fontSize={isMedium ? '28px' : '52px'}
                                fontWeight={600}
                                fontFamily="poppins"
                                textAlign="center"
                                sx={{
                                    borderRadius: '10px',
                                    lineHeight: '100%',
                                    letterSpacing: isMedium
                                        ? '-1.4px'
                                        : '-2.6px'
                                }}
                            >
                                Our Approach
                            </Typography>
                        </Stack>
                        <Typography
                            variant="body2"
                            fontWeight={400}
                            fontSize={isMedium ? '14px' : '20px'}
                            color="textGrey.main"
                            textAlign={isMedium ? 'center' : undefined}
                            sx={{ lineHeight: '150%', letterSpacing: '0.6px' }}
                        >
                            Jobfactor uses a combination of tools to transform
                            the traditional hiring and professional networking
                            process with immense benefit to both employers and
                            job seekers.
                        </Typography>
                    </Box>
                    <Stack
                        flexDirection="row"
                        justifyContent={isMedium ? 'center' : 'space-between'}
                        gap={isMedium ? '44px' : undefined}
                        flexWrap="wrap"
                    >
                        <Box
                            maxWidth="320px"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay={isMedium ? '500' : '300'}
                            mb={isMedium ? undefined : '44px'}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Box
                                px={3}
                                mb={isMedium ? 2 : 6}
                                borderRadius={1}
                                py="20px"
                                alignItems="center"
                                justifyContent="center"
                                color="primaryLight.main"
                                sx={{
                                    backgroundColor: 'primary.iconBlue',
                                    boxShadow:
                                        '4px 4px 40px 0px rgba(4, 19, 24, 0.20), -4px -4px 40px 0px rgba(4, 19, 24, 0.20)'
                                }}
                            >
                                <KeyboardIcon />
                            </Box>
                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box position="absolute">
                                    <FirstVector />
                                </Box>
                                <Typography
                                    variant="body1"
                                    mb={isMedium ? 1 : 2}
                                    color="primaryLight.medium"
                                    fontSize={isMedium ? 16 : 24}
                                    fontWeight={600}
                                    fontFamily="Poppins"
                                >
                                    Job Fit Analytics
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                align="center"
                                fontSize={isMedium ? '14px' : '16px'}
                                sx={{
                                    letterSpacing: isMedium
                                        ? undefined
                                        : '0.024px',
                                    lineHeight: isMedium ? '21px' : '24px'
                                }}
                                color="textGrey.main"
                                fontWeight={400}
                            >
                                A holistic and accurate method of evaluating job
                                fit and recommendations with the use of
                                Artificial Intelligence and machine learning
                            </Typography>
                        </Box>
                        <Box
                            maxWidth="320px"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay={isMedium ? '500' : '800'}
                            mb={isMedium ? undefined : '44px'}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Box
                                px={3}
                                mb={isMedium ? 2 : 6}
                                borderRadius={1}
                                py="20px"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'primary.iconGreen',
                                    boxShadow:
                                        '4px 4px 40px 0px rgba(4, 19, 24, 0.20), -4px -4px 40px 0px rgba(4, 19, 24, 0.20)'
                                }}
                            >
                                <ShareIcon />
                            </Box>
                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box position="absolute">
                                    <SecondVector />
                                </Box>
                                <Typography
                                    variant="body1"
                                    mb={isMedium ? 1 : 2}
                                    fontSize={isMedium ? 16 : 24}
                                    color="primaryLight.medium"
                                    fontWeight={600}
                                    fontFamily="poppins"
                                >
                                    Peer Review
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                align="center"
                                fontSize={isMedium ? '14px' : '16px'}
                                sx={{
                                    letterSpacing: isMedium
                                        ? undefined
                                        : '0.024px',
                                    lineHeight: isMedium ? '21px' : '24px'
                                }}
                                color="textGrey.main"
                                fontWeight={400}
                            >
                                Jobfactor uses peer to peer feedback to create a
                                level playing field for everyone to have equal
                                opportunity to get hired.
                            </Typography>
                        </Box>
                        <Box
                            maxWidth="320px"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay={isMedium ? '500' : '1300'}
                            mb={isMedium ? undefined : '44px'}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Box
                                px={3}
                                mb={isMedium ? 2 : 6}
                                borderRadius={1}
                                py="20px"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'primary.iconPurple',
                                    boxShadow:
                                        '4px 4px 40px 0px rgba(4, 19, 24, 0.20), -4px -4px 40px 0px rgba(4, 19, 24, 0.20)'
                                }}
                            >
                                <RecieptItemIcon />
                            </Box>
                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box position="absolute">
                                    <ThirdVector />
                                </Box>
                                <Typography
                                    variant="body1"
                                    mb={isMedium ? 1 : 2}
                                    fontSize={isMedium ? 16 : 24}
                                    fontWeight={600}
                                    fontFamily="poppins"
                                    color="primaryLight.medium"
                                >
                                    Outcome-Focused
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                align="center"
                                fontSize={isMedium ? '14px' : '16px'}
                                sx={{
                                    letterSpacing: isMedium
                                        ? undefined
                                        : '0.024px',
                                    lineHeight: isMedium ? '21px' : '24px'
                                }}
                                fontWeight={400}
                                color="textGrey.main"
                            >
                                With our ‘No hire, No pay’ promise for employers
                                and ‘Interview guarantee’ for jobseekers, we can
                                ensure your partnership with Jobfactor is
                                risk-free and success is guaranteed.
                            </Typography>
                        </Box>
                        <Box
                            maxWidth="320px"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay={isMedium ? '500' : '1800'}
                            mb={isMedium ? undefined : '44px'}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Box
                                px={3}
                                mb={isMedium ? 2 : 6}
                                borderRadius={1}
                                py="20px"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'primary.iconRed',
                                    boxShadow:
                                        '4px 4px 40px 0px rgba(4, 19, 24, 0.20), -4px -4px 40px 0px rgba(4, 19, 24, 0.20)'
                                }}
                            >
                                <LinkAddIcon />
                            </Box>
                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box position="absolute">
                                    <FourthVector />
                                </Box>
                                <Typography
                                    variant="body1"
                                    mb={isMedium ? 1 : 2}
                                    fontSize={isMedium ? 16 : 24}
                                    color="primaryLight.medium"
                                    fontWeight={600}
                                    fontFamily="poppins"
                                >
                                    Skill Assessment
                                </Typography>
                            </Box>
                            <Typography
                                variant="body2"
                                fontFamily="Outfit"
                                align="center"
                                fontSize={isMedium ? '14px' : '16px'}
                                sx={{
                                    letterSpacing: isMedium
                                        ? undefined
                                        : '0.024px',
                                    lineHeight: isMedium ? '21px' : '24px'
                                }}
                                color="textGrey.main"
                                fontWeight={400}
                                fontStyle={'normal'}
                            >
                                Our skill assessment system is directly linked 
                                to job requirements. This gives companies and 
                                professionals a powerful tool to increase the 
                                speed and quality of a job match.
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default OurApproach;
