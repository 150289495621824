import * as React from 'react';
import {
    Box,
    Typography,
    Container,
    Link,
    Grid,
    useMediaQuery,
    useTheme,
    ImageList,
    ImageListItem
} from '@mui/material';
import FacebookIcon from '../../Assets/Icons/FacebookIcon';
import TwitterIcon from '../../Assets/Icons/TwitterIcon';
import TiktokIcon from '../../Assets/Icons/TiktokIcon';
import InstagramIcon from '../../Assets/Icons/InstagramIcon';
import LinkedinIcon from '../../Assets/Icons/LinkedinIcon';
import ArrowUpIcon from '../../Assets/Icons/ArrowUpIcon';
import Image from '../../Assets/Images/Image';
import '@fontsource/outfit';
import '@fontsource/open-sans';
import { useAppDispatch } from '../../Feature/Store/Store';
import { openFeedbackModalState } from '../../Feature/App/AppSlice';
import { useNavigate } from 'react-router-dom';
type FooterPropsType = {
    setPolicy: (policy: string) => void;
};
export default function Footer({ setPolicy }: FooterPropsType) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));
    const medium = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    return (
        <Container
            maxWidth="xl"
            disableGutters
            sx={{
                display: 'flex',
                zIndex: 99,
                flexDirection: medium ? 'column' : 'row',
                justifyContent: 'space-between',
                gap: '24px',
                backgroundColor: '#FFFFFF',
                px: medium ? '20px' : '60px',
                py: medium ? '40px' : '64px'
            }}
        >
            <Box marginBottom="28px" maxWidth={'175px'}>
                <ImageList
                    onClick={() => {
                        setOpen(false);
                        navigate('/');
                    }}
                    sx={{
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                    cols={1}
                >
                    <ImageListItem>
                        <img
                            src={require('./../../Assets/Images/logo.png')}
                            alt="logo"
                        />
                    </ImageListItem>
                </ImageList>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    marginBottom="22px"
                    alignItems="center"
                    sx={{
                        display: 'flex',
                        gap: mobile ? '8px' : '64px',
                        flexDirection: mobile ? 'column' : 'row'
                        // flexWrap: 'wrap'
                    }}
                >
                    <Typography
                        onClick={() => dispatch(openFeedbackModalState())}
                        variant="body1"
                        fontSize="20px"
                        fontFamily="Outfit"
                        fontWeight={400}
                        sx={{ cursor: 'pointer' }}
                    >
                        Questions and comments?
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight={400}
                        fontSize="20px"
                        sx={{ fontFamily: 'Outfit' }}
                    >
                        <Typography
                            component="span"
                            display="inline"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setPolicy('term');
                                navigate('/policy');
                            }}
                        >
                            Terms of use
                        </Typography>{' '}
                        and{' '}
                        <Typography
                            component="span"
                            display="inline"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setPolicy('privacy');
                                navigate('/policy');
                            }}
                        >
                            Privacy Policy
                        </Typography>
                    </Typography>
                    <Typography
                        onClick={() => navigate('/about')}
                        variant="body1"
                        fontWeight={400}
                        fontSize="20px"
                        sx={{ fontFamily: 'Outfit', cursor: 'pointer' }}
                    >
                        About us
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '36px',
                        alignSelf: 'center'
                    }}
                >
                    <Link
                        target="_blank"
                        href="https://www.linkedin.com/company/job-factor/"
                        color="inherit"
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <LinkedinIcon />
                    </Link>

                    <Link
                        href="https://twitter.com/_jobfactor?t=9xNuozxqPZaQ9ilYibL0iQ&s=09"
                        target="_blank"
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <TwitterIcon />
                    </Link>

                    <Link
                        href="https://www.facebook.com/profile.php?id=100089853477839&mibextid=ZbWKwL"
                        target="_blank"
                        color="inherit"
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <FacebookIcon />
                    </Link>

                    <Link
                        href="http://www.instagram.com/officialjobfactor"
                        target="_blank"
                        color="primary"
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <InstagramIcon />
                    </Link>
                    <Link
                        href="https://www.tiktok.com/@officialjobfactor?_t=8aFd3ZWiKDF&_r=1"
                        target="_blank"
                        color="rgba(255,255,255,1)"
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <TiktokIcon />
                    </Link>
                </Box>

                <Box mt={3} gap="4px">
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        fontFamily={'Outfit'}
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="24px"
                        letterSpacing="0.024px"
                    >
                        {'©'}
                        {new Date().getFullYear()}
                        {','} Jobfactor Inc. All rights reserved.
                    </Typography>
                </Box>
            </Box>

            {medium ? null : (
                <Grid
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                    position="inherit"
                    color="aquamarine"
                    alignItems="right"
                    sx={{
                        alignSelf: 'flex-end',
                        transition: 'all .4s ease',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <ArrowUpIcon />
                </Grid>
            )}
        </Container>
    );
}
