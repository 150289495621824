import React from 'react';
import { Stack } from '@mui/material';

type DreamJobScreen2ArrowRightProps = {
    bgColor: string;
    screen: number;
    setScreen: (screen: number) => void;
};
const DreamJobScreen2ArrowRight = ({
    bgColor,
    screen,
    setScreen
}: DreamJobScreen2ArrowRightProps) => {
    const current = {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '#999',
        cursor: 'pointer'
    };

    const notCurrent = {
        bgcolor: bgColor,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: bgColor,
        cursor: 'pointer'
    };
    return (
        <Stack
            onClick={() => setScreen(2)}
            alignSelf="flex-start"
            p="20px"
            borderRadius="100px"
            sx={screen === 2 ? current : notCurrent}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
                    stroke={screen === 2 ? '#999' : '#fff'}
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Stack>
    );
};

export default DreamJobScreen2ArrowRight;
