import Footer from './components/Footer/Footer';
import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import Store from './Feature/Store/Store';
import {
    ThemeProvider,
    CssBaseline,
    Stack,
    Box,
    useMediaQuery
} from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fontsource/outfit';
import '@fontsource/poppins';
import Navbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import OurApproach from './components/OurApproach/OurApproach';
import { theme } from './Theme/Theme';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BlogPageMainSection from './components/BlogPageMainSection/BlogPageMainSection';
import BlogDetails from './components/BlogDetails/BlogDetails';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import MoreBlogs from './components/MoreBlogs/MoreBlogs';
import Slideshow from './components/Slideshow/Slideshow';
import About from './components/AboutPage/About';
import Newsletter from './components/Newsletter/Newsletter';
import SignupModal from './components/SignupModal/SignupModal';
import Form from './Feature/Form/Form';
import DreamJobSection from './components/DreamJobSection/DreamJobSection';
import Policies from './components/Policies/Policies';
import Career from './components/Career/Career';
import GetVideoSection from './components/GetVideoSection/GetVideoSection';
import CommunityModal from './components/CommunityModal/CommunityModal.tsx';
import Carousel from './components/Carousel/Carousel';

function App() {
    useEffect(() => {
        AOS.init({
            once: true
        });
    }, []);

    const { formik, data, error, isLoading } = Form();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [policy, setPolicy] = useState('term');

    return (
        <Stack>
            <Provider store={Store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <ScrollToTop />
                        <Navbar />
                        <SignupModal
                            data={data}
                            formik={formik}
                            error={error}
                            isLoading={isLoading}
                        />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <Box
                                            sx={{
                                                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`,
                                                backgroundBlendMode: 'overlay'
                                            }}
                                        >
                                            <HeroSection formik={formik} />
                                        </Box>
                                        <OurApproach />
                                        <Career />
                                        <Newsletter formik={formik} />
                                        <Carousel />
                                        <DreamJobSection />
                                        <GetVideoSection formik={formik} />
                                        {isMobile ? null : <Slideshow />}
                                    </>
                                }
                            ></Route>
                            <Route
                                path="/blog"
                                element={
                                    <BlogPageMainSection formik={formik} />
                                }
                            ></Route>
                            <Route
                                path="/blog-details/:id"
                                element={<BlogDetails formik={formik} />}
                            ></Route>
                            {/* <Route
                                    path=":id/blog-details"
                                    element={<BlogDetails />}
                                ></Route> */}
                            <Route
                                path="/more-blogs"
                                element={<MoreBlogs formik={formik} />}
                            ></Route>
                            <Route
                                path="/about"
                                element={<About formik={formik} />}
                            ></Route>
                            <Route
                                path="/policy"
                                element={
                                    <Policies
                                        policy={policy}
                                        setPolicy={setPolicy}
                                    />
                                }
                            ></Route>
                        </Routes>
                        <CommunityModal />
                        <Footer policy={policy} setPolicy={setPolicy} />
                    </Router>
                </ThemeProvider>
            </Provider>
        </Stack>
    );
}
export default App;
