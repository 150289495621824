import { Stack } from '@mui/material';
import React from 'react';

const DisabledCarouselRightArrow = () => {
    return (
        <Stack position="absolute" bottom={0} right={0}>
            <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M35.7208 22.2266L20.2675 14.0932C16.6008 12.1599 12.6142 16.0666 14.4675 19.7732L16.6275 24.0932C17.2275 25.2932 17.2275 26.7066 16.6275 27.9066L14.4675 32.2266C12.6142 35.9332 16.6008 39.8266 20.2675 37.9066L35.7208 29.7732C38.7608 28.1732 38.7608 23.8266 35.7208 22.2266Z"
                    fill="#808080"
                    stroke="#808080"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="0.75"
                    y="0.75"
                    width="50.5"
                    height="50.5"
                    rx="25.25"
                    stroke="white"
                    strokeOpacity="0.5"
                    strokeWidth="1.5"
                />
            </svg>
        </Stack>
    );
};

export default DisabledCarouselRightArrow;
