import React from 'react';
import {
    Stack,
    Container,
    Typography,
    TextField,
    useTheme,
    useMediaQuery,
    Box,
    Divider
} from '@mui/material';
import backgroundTexture from '../../Assets/Images/OurApproachBackgroundTexture.png';
import SmsIconBlue from '../../Assets/Icons/SmsIconBlue';
import ArrowIcon from '../../Assets/Icons/ArrowIcon';
import SmsIconYellow from '../../Assets/Icons/SmsIconYellow';
import { useAppDispatch } from '../../Feature/Store/Store';
import { openSignupModalState } from '../../Feature/App/AppSlice';

type HeroSectionProps = {
    formik?: any;
};

const Newsletter = ({ formik }: HeroSectionProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Stack
            position="relative"
            py={isMobile ? '30px' : '59px'}
            sx={{
                backgroundColor: 'primaryBlue.main',
                backgroundImage: `url(${backgroundTexture})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundBlendMode: 'overlay',
                mixBlendMode: 'multiply'
            }}
        >
            <Container
                maxWidth="xl"
                disableGutters
                sx={{ px: isMobile ? '16px' : '140px' }}
            >
                <Typography
                    mb="24px"
                    fontFamily="Poppins"
                    fontSize={isMobile ? '16px' : '24px'}
                    fontWeight={isMobile ? 500 : 600}
                    lineHeight={isMobile ? '20px' : '28px'}
                    letterSpacing={isMobile ? '-0.32px' : '-0.48px'}
                    color="primaryLight.main"
                >
                    {isMobile
                        ? 'Be the first to know when we go live'
                        : 'Never miss an update from us'}
                </Typography>
                <Stack
                    borderRadius={isMobile ? '8px' : '4px'}
                    flexDirection="row"
                    alignItems="center"
                    px={2}
                    sx={{
                        height: '80px',
                        backgroundColor: 'primaryLight.main'
                    }}
                >
                    {isMobile ? <SmsIconYellow /> : <SmsIconBlue />}
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        sx={{ mx: '12px', height: '24px' }}
                    />
                    <TextField
                        id="email"
                        name="email"
                        type="text"
                        variant="standard"
                        margin="dense"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        sx={{
                            flex: 1,
                            color: 'primary2.main',
                            fontSize: isMobile ? '14px' : '16px'
                        }}
                        placeholder={
                            isMobile
                                ? 'Enter your email address'
                                : 'Enter your email address to sign up for our newsletter'
                        }
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                    <Box
                        onClick={() => dispatch(openSignupModalState())}
                        display="flex"
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            alignSelf: 'center',
                            marginLeft: '12px',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        <ArrowIcon />
                    </Box>
                </Stack>
            </Container>
        </Stack>
    );
};

export default Newsletter;
