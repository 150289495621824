import React from 'react';

const Rectangle = () => {
    return (
        <svg
            width="150"
            height="744"
            viewBox="0 0 150 744"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_dd_357_15210)">
                <path
                    d="M133.956 900.878C122.952 901.835 113.255 893.69 112.299 882.686L28.0026 -86.6556L307.946 -111L392.242 858.342C393.199 869.346 385.054 879.042 374.05 879.999L133.956 900.878Z"
                    fill="#FFC24C"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_357_15210"
                    x="0.00390625"
                    y="-127"
                    width="420.316"
                    height="1055.95"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="8" dy="8" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_357_15210"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-8" dy="4" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_357_15210"
                        result="effect2_dropShadow_357_15210"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_357_15210"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default Rectangle;
