import React from 'react';

const DotsYellow = () => {
    return (
        <svg
            width="28"
            height="75"
            viewBox="0 0 28 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="2.597" cy="2.69832" r="2.30794" fill="#FFC24C" />
            <circle cx="2.597" cy="25.7777" r="2.30794" fill="#FFC24C" />
            <circle cx="2.597" cy="48.857" r="2.30794" fill="#FFC24C" />
            <circle cx="2.597" cy="71.9364" r="2.30794" fill="#FFC24C" />
            <circle cx="25.6751" cy="2.69832" r="2.30794" fill="#FFC24C" />
            <circle cx="25.6751" cy="25.7777" r="2.30794" fill="#FFC24C" />
            <circle cx="25.6751" cy="48.8573" r="2.30794" fill="#FFC24C" />
            <circle cx="25.6751" cy="71.9364" r="2.30794" fill="#FFC24C" />
        </svg>
    );
};

export default DotsYellow;
