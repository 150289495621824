import React from 'react';

const AboutPageArrowIcon = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="48" height="48" rx="24" fill="#EDEDED" />
            <path
                d="M26.4297 17.9302L32.4997 24.0002L26.4297 30.0702"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5 24H32.33"
                stroke="#05668D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AboutPageArrowIcon;
