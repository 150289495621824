import React from 'react';
import {
    Container,
    Stack,
    Typography,
    TextField,
    Box,
    Divider,
    useTheme,
    useMediaQuery,
    ImageList,
    ImageListItem
} from '@mui/material';
import EclipseLarge from '../../Assets/Icons/EclipseLarge';
import EclipseMedium from '../../Assets/Icons/EclipseMedium';
import EclipseSmall from '../../Assets/Icons/EclipseSmall';
import SmsIconBlue from '../../Assets/Icons/SmsIconBlue';
import ArrowIcon from '../../Assets/Icons/ArrowIcon';
import '@fontsource/poppins';
import HeroImage1 from '../../Assets/Images/HeroImage1.png';
import HeroImage2 from '../../Assets/Images/HeroImage2.png';
import HeroImage3 from '../../Assets/Images/HeroImage3.png';
import HeroImage4 from '../../Assets/Images/HeroImage4.png';
import HeroImage5 from '../../Assets/Images/HeroImage5.png';
import HeroImage6 from '../../Assets/Images/HeroImage6.png';
import HeroImage7 from '../../Assets/Images/HeroImage7.png';
import heroBackground from '../../Assets/Images/heroBackground.png';
import Slider from 'react-slick';
import { useAppDispatch } from '../../Feature/Store/Store';
import { openSignupModalState } from '../../Feature/App/AppSlice';

type HeroSectionProps = {
    formik: any;
};

const HeroSection = ({ formik }: HeroSectionProps) => {
    const dispatch = useAppDispatch();

    const heroImages = [
        HeroImage1,
        HeroImage2,
        HeroImage3,
        HeroImage4,
        HeroImage5,
        HeroImage6,
        HeroImage7
    ];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    // const [email, setEmail] = useState('');
    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { // to be used when modals have been created
    //     setEmail(event.target.value);
    // }

    const settings = {
        fade: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        nextArrow: <></>,
        prevArrow: <></>
    };

    return (
        <Container
            maxWidth="xl"
            disableGutters
            sx={{
                position: 'relative',
                pr: isMedium ? 0 : '60px',
                pl: '16px',
                pb: isMobile ? 0 : '50px'
                // backgroundImage: `url(${heroBackground})`,
                // backgroundRepeat: 'no-repeat',
                // backgroundPosition: 'center',
                // backgroundSize: 'cover',
                // zIndex: 1
            }}
        >
            <Stack
                mr={isMedium ? undefined : '20px'}
                sx={{
                    zIndex: 2,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,

                    backgroundImage: `url(${heroBackground})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            ></Stack>
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    display: 'flex',
                    pt: '120px',
                    flexDirection: 'row',
                    justifyContent: isMobile ? 'center' : undefined
                }}
            >
                {isMobile ? null : (
                    <Stack zIndex={1} position="relative">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Box>
                                <EclipseLarge />
                            </Box>
                            <Box sx={{ position: 'absolute' }}>
                                <EclipseMedium />
                            </Box>
                            <Box sx={{ position: 'absolute' }}>
                                <EclipseSmall />
                            </Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '484px',
                                    height: '470px',
                                    zIndex: 29
                                }}
                            >
                                <Slider {...settings}>
                                    {heroImages.map((item) => (
                                        <Box key={item}>
                                            <img src={item} alt="hero" />
                                        </Box>
                                    ))}
                                </Slider>
                            </Box>
                        </Box>
                    </Stack>
                )}
                <Stack
                    zIndex={3}
                    position="relative"
                    sx={{
                        width: '100%',
                        maxWidth: isMobile ? '1000px' : undefined
                    }}
                    mt={isMobile ? undefined : '33px'}
                >
                    <Stack
                        pl={isMobile ? undefined : '20px'}
                        mr={isMedium ? '20px' : 0}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                lineHeight: '120%',
                                letterSpacing: '-3.2px',
                                fontFamily: 'Poppins',
                                fontWeight: 600
                            }}
                            color="primaryDark.main"
                            fontSize={isMedium ? 40 : 64}
                        >
                            Beyond{' '}
                            <span style={{ color: theme.palette.primary.main }}>
                                Resume,
                            </span>
                        </Typography>
                        <Typography
                            mb={3}
                            variant="h2"
                            sx={{
                                lineHeight: '120%',
                                letterSpacing: '-3.2px',
                                fontFamily: 'Poppins',
                                fontWeight: 600
                            }}
                            color="primaryDark.main"
                            fontSize={isMedium ? 40 : 64}
                        >
                            Beyond{' '}
                            <span
                                style={{ color: theme.palette.primary2.main }}
                            >
                                Keywords.
                            </span>
                        </Typography>
                        <Typography
                            color="navTextColor.main"
                            fontSize={24}
                            mb={7}
                        >
                            Jobfactor: the ultimate talent marketplace powered
                            by AI.
                        </Typography>
                    </Stack>
                    <Stack
                        width="100%"
                        direction="row"
                        // useFlexGap
                        px="20px"
                        mb={isMedium ? 8 : 10.5}
                        mr={isMedium ? '20px' : 0}
                        sx={{
                            transform: 'translateX(-2px)',
                            height: '80px',
                            maxWidth: isMobile ? '650px' : undefined,
                            backgroundColor: 'primaryLight.main',
                            borderBottomWidth: '2px',
                            borderBottomStyle: 'solid',
                            borderBottomColor: 'primary.main'
                        }}
                    >
                        <TextField
                            id="email"
                            type="email"
                            variant="standard"
                            margin="normal"
                            required
                            name="email"
                            fullWidth
                            autoComplete="Email Address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            sx={{
                                flex: 1,
                                color: 'primary2.main',
                                fontSize: '16px'
                            }}
                            placeholder="Enter your Email"
                            InputProps={{
                                startAdornment: (
                                    <>
                                        <SmsIconBlue />
                                        <Divider
                                            orientation="vertical"
                                            variant="middle"
                                            sx={{ mx: '12px', height: '24px' }}
                                        />
                                    </>
                                ),
                                disableUnderline: true,
                                endAdornment: (
                                    <Box
                                        sx={{
                                            transition: 'all .4s ease',
                                            cursor: 'pointer',
                                            alignSelf: 'center',
                                            marginLeft: '12px',
                                            '&:hover': {
                                                opacity: 0.8
                                            }
                                        }}
                                        onClick={() =>
                                            dispatch(openSignupModalState())
                                        }
                                    >
                                        <ArrowIcon />
                                    </Box>
                                )
                            }}
                        />
                    </Stack>
                    <ImageList
                        cols={1}
                        sx={{
                            position: isMobile ? null : 'absolute',
                            top: '27.9rem',
                            zIndex: 5
                        }}
                    >
                        <ImageListItem>
                            {isMedium ? (
                                <img
                                    src={require('../../Assets/Images/HeroImage9.png')}
                                    alt="hero"
                                />
                            ) : (
                                <img
                                    src={require('../../Assets/Images/HeroImage8.png')}
                                    alt="hero"
                                    style={{
                                        width: '750px',
                                        maxWidth: isMobile ? '100%' : '100%',
                                        height: isMobile ? undefined : '100%',
                                        // position: 'absolute',
                                        bottom: 0
                                    }}
                                />
                            )}
                        </ImageListItem>
                    </ImageList>
                </Stack>
            </Container>
        </Container>
    );
};

export default HeroSection;
