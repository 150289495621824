import React from 'react';

const DreamGreen = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#56E39F" />
            <path
                d="M39.395 21.8589C39.2874 21.8471 39.1794 21.8471 39.0718 21.8589C37.2936 21.7818 35.8867 20.3185 35.8867 18.5201C35.8867 16.6751 37.384 15.1667 39.2401 15.1667C41.0845 15.1667 42.5925 16.6632 42.5934 18.5185C42.581 20.3192 41.1721 21.7818 39.395 21.8589Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M43.4415 31.1844L43.4413 31.1846C42.2315 31.9947 40.5607 32.372 38.9213 32.2846C39.2847 31.2997 39.4746 30.2402 39.4861 29.1386L39.4861 29.1386V29.1334C39.4861 27.9814 39.2785 26.8708 38.8749 25.8575C40.5395 25.7626 42.2093 26.14 43.4296 26.95L43.4312 26.951C44.3801 27.5756 44.7865 28.351 44.7878 29.0626C44.7891 29.7748 44.3849 30.5535 43.4415 31.1844Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M24.8872 21.8589C24.7796 21.8471 24.6716 21.8471 24.564 21.8589C22.7858 21.7818 21.3789 20.3185 21.3789 18.5201C21.3789 16.6618 22.8762 15.1667 24.7189 15.1667C26.5761 15.1667 28.0722 16.6629 28.0722 18.5201C28.0722 20.3185 26.6654 21.7818 24.8872 21.8589Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M20.5169 26.9498L20.5169 26.9498L20.5193 26.9482C21.6625 26.179 23.2486 25.7962 24.8333 25.8475C24.4403 26.8641 24.2335 27.9727 24.2335 29.1334C24.2335 30.2476 24.4303 31.316 24.7936 32.3074C23.229 32.3486 21.6768 31.9591 20.5159 31.1963C19.5665 30.565 19.1602 29.7864 19.1602 29.0734C19.1602 28.3601 19.5668 27.5812 20.5169 26.9498Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M32.1769 32.659C32.0526 32.6467 31.9227 32.6473 31.7956 32.6591C29.6277 32.5713 27.8991 30.7868 27.8984 28.588C27.911 26.3279 29.7298 24.5 31.9984 24.5C34.2552 24.5 36.0844 26.3288 36.0851 28.5854C36.0725 30.787 34.3553 32.5702 32.1769 32.659Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M27.8264 35.92C25.8131 37.2667 25.8131 39.48 27.8264 40.8134C30.1197 42.3467 33.8797 42.3467 36.1731 40.8134C38.1864 39.4667 38.1864 37.2534 36.1731 35.92C33.8931 34.3867 30.1331 34.3867 27.8264 35.92Z"
                fill="white"
            />
        </svg>
    );
};

export default DreamGreen;
