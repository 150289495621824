import React from 'react';

const Arrow45Deg = () => {
    return (
        <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.6757 14.0696C15.5861 14.1591 15.4636 14.2157 15.3221 14.2157C15.0487 14.2157 14.8224 13.9894 14.8224 13.716L14.8224 8.49285L9.59928 8.49285C9.32587 8.49285 9.09959 8.26658 9.09959 7.99316C9.09959 7.71975 9.32587 7.49348 9.59928 7.49348L15.3221 7.49347C15.5955 7.49347 15.8218 7.71975 15.8218 7.99316L15.8218 13.716C15.8218 13.8574 15.7653 13.98 15.6757 14.0696Z"
                fill="white"
            />
            <path
                d="M15.596 8.42665L7.66222 16.3604C7.46895 16.5537 7.14839 16.5537 6.95512 16.3604C6.76184 16.1671 6.76184 15.8466 6.95512 15.6533L14.8889 7.71954C15.0821 7.52627 15.4027 7.52627 15.596 7.71954C15.7892 7.91282 15.7892 8.23338 15.596 8.42665Z"
                fill="white"
            />
        </svg>
    );
};

export default Arrow45Deg;
