import React from 'react';
import { Stack } from '@mui/material';

const CarouselLeftArrow = ({ onClick }: any) => {
    return (
        <Stack
            sx={{ cursor: 'pointer' }}
            position="absolute"
            zIndex={1}
            bottom={0}
            right="66px"
            onClick={onClick}
        >
            <svg
                width="53"
                height="52"
                viewBox="0 0 53 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.3182 29.7734L31.7716 37.9068C35.4382 39.8401 39.4249 35.9334 37.5716 32.2268L35.4116 27.9068C34.8116 26.7068 34.8116 25.2934 35.4116 24.0934L37.5716 19.7734C39.4249 16.0668 35.4382 12.1734 31.7716 14.0934L16.3182 22.2268C13.2782 23.8268 13.2782 28.1734 16.3182 29.7734Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="0.789062"
                    y="0.75"
                    width="50.5"
                    height="50.5"
                    rx="25.25"
                    stroke="white"
                    strokeOpacity="0.5"
                    strokeWidth="1.5"
                />
            </svg>
        </Stack>
    );
};

export default CarouselLeftArrow;
