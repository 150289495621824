import React from 'react';

const CompleteIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="105"
            viewBox="0 0 106 105"
            fill="none"
        >
            <path
                d="M14.2182 25.7512C8.24009 34.4129 5.33701 44.8281 5.9727 55.3333C6.60839 65.8386 10.7462 75.8277 17.7252 83.7054C24.7041 91.5831 34.1217 96.8947 44.4737 98.7921C54.8257 100.689 65.515 99.063 74.8341 94.1725C84.1533 89.282 91.5648 81.4095 95.8848 71.8126C100.205 62.2157 101.184 51.4479 98.6666 41.229C96.1489 31.0102 90.2794 21.9298 81.9955 15.4382C73.7116 8.94659 63.4912 5.41825 52.9668 5.41675C50.0809 5.41691 47.2008 5.67747 44.3618 6.19525"
                stroke="#05668D"
                strokeWidth="1.87075"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.2447 16.8562C20.6631 18.225 19.1721 19.6951 17.7812 21.2573"
                stroke="#D9D9D9"
                strokeWidth="1.87075"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M89.749 68.1517C98.3931 47.8564 88.948 24.3963 68.6527 15.7522C48.3573 7.10803 24.8973 16.5532 16.2531 36.8485C7.60901 57.1438 17.0542 80.6039 37.3495 89.248C57.6448 97.8922 81.1049 88.447 89.749 68.1517Z"
                fill="#05668D"
            />
            <path
                d="M34.5547 48.347L49.7095 65.5674L74.4969 30.4519"
                stroke="white"
                strokeWidth="11.2245"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CompleteIcon;
