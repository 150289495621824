import React from 'react';

const NextIcon = () => {
    return (
        <svg
            width="99"
            height="44"
            viewBox="0 0 99 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="99" height="44" rx="8" fill="#DFEBF0" />
            <path
                d="M21.152 28V16.752H22.672L23.328 18.928V28H21.152ZM28.928 28L22.256 19.36L22.672 16.752L29.344 25.392L28.928 28ZM28.928 28L28.304 25.888V16.752H30.496V28H28.928ZM36.329 28.16C35.5183 28.16 34.7983 27.9893 34.169 27.648C33.5397 27.296 33.0383 26.816 32.665 26.208C32.3023 25.6 32.121 24.9067 32.121 24.128C32.121 23.36 32.297 22.6773 32.649 22.08C33.0117 21.472 33.5023 20.992 34.121 20.64C34.7397 20.2773 35.4277 20.096 36.185 20.096C36.9317 20.096 37.5877 20.2667 38.153 20.608C38.729 20.9387 39.177 21.3973 39.497 21.984C39.8277 22.56 39.993 23.216 39.993 23.952C39.993 24.0907 39.9823 24.2347 39.961 24.384C39.9503 24.5227 39.9237 24.6827 39.881 24.864L33.385 24.88V23.312L38.905 23.296L38.057 23.952C38.0357 23.4933 37.9503 23.1093 37.801 22.8C37.6623 22.4907 37.4543 22.256 37.177 22.096C36.9103 21.9253 36.5797 21.84 36.185 21.84C35.769 21.84 35.4063 21.936 35.097 22.128C34.7877 22.3093 34.5477 22.5707 34.377 22.912C34.217 23.2427 34.137 23.6373 34.137 24.096C34.137 24.5653 34.2223 24.976 34.393 25.328C34.5743 25.6693 34.8303 25.936 35.161 26.128C35.4917 26.3093 35.8757 26.4 36.313 26.4C36.7077 26.4 37.065 26.336 37.385 26.208C37.705 26.0693 37.9823 25.8667 38.217 25.6L39.449 26.832C39.0757 27.2693 38.617 27.6 38.073 27.824C37.5397 28.048 36.9583 28.16 36.329 28.16ZM45.9981 28L43.9661 24.864L43.5341 24.544L40.5261 20.272H43.0061L44.8781 23.104L45.2781 23.408L48.4621 28H45.9981ZM40.3661 28L43.5181 23.584L44.7181 25.008L42.7341 28H40.3661ZM45.3581 24.528L44.1261 23.12L45.9661 20.272H48.3181L45.3581 24.528ZM50.5071 28V17.056H52.6031V28H50.5071ZM48.6991 22.112V20.272H54.4111V22.112H48.6991Z"
                fill="#044864"
            />
            <path
                d="M81.4311 28.8201C81.2411 28.8201 81.0511 28.7501 80.9011 28.6001C80.6111 28.3101 80.6111 27.8301 80.9011 27.5401L86.4411 22.0001L80.9011 16.4601C80.6111 16.1701 80.6111 15.6901 80.9011 15.4001C81.1911 15.1101 81.6711 15.1101 81.9611 15.4001L88.0311 21.4701C88.3211 21.7601 88.3211 22.2401 88.0311 22.5301L81.9611 28.6001C81.8111 28.7501 81.6211 28.8201 81.4311 28.8201Z"
                fill="#044864"
            />
            <path
                d="M87.33 22.75H70.5C70.09 22.75 69.75 22.41 69.75 22C69.75 21.59 70.09 21.25 70.5 21.25H87.33C87.74 21.25 88.08 21.59 88.08 22C88.08 22.41 87.74 22.75 87.33 22.75Z"
                fill="#044864"
            />
        </svg>
    );
};

export default NextIcon;
