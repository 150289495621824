import React from 'react';

const DreamBlue = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#4CBFFF" />
            <path
                d="M22.6654 32C19.7187 32 17.332 34.3867 17.332 37.3333C17.332 38.3333 17.612 39.28 18.1054 40.08C19.0254 41.6267 20.7187 42.6667 22.6654 42.6667C24.612 42.6667 26.3054 41.6267 27.2254 40.08C27.7187 39.28 27.9987 38.3333 27.9987 37.3333C27.9987 34.3867 25.612 32 22.6654 32ZM25.292 36.8933L22.452 39.52C22.2654 39.6933 22.012 39.7867 21.772 39.7867C21.5187 39.7867 21.2654 39.6933 21.0654 39.4933L19.7454 38.1733C19.3587 37.7867 19.3587 37.1467 19.7454 36.76C20.132 36.3733 20.772 36.3733 21.1587 36.76L21.7987 37.4L23.932 35.4267C24.332 35.0533 24.972 35.08 25.3454 35.48C25.7187 35.88 25.692 36.52 25.292 36.8933Z"
                fill="white"
            />
            <path
                d="M44.668 28.6667H41.3346C39.868 28.6667 38.668 29.8667 38.668 31.3334C38.668 32.8001 39.868 34.0001 41.3346 34.0001H44.668C45.0413 34.0001 45.3346 33.7067 45.3346 33.3334V29.3334C45.3346 28.9601 45.0413 28.6667 44.668 28.6667Z"
                fill="white"
            />
            <path
                d="M38.0416 19.1999C38.4416 19.5865 38.1082 20.1865 37.5482 20.1865L26.5082 20.1732C25.8682 20.1732 25.5482 19.3999 26.0016 18.9465L28.3349 16.5999C30.3082 14.6399 33.4949 14.6399 35.4682 16.5999L37.9882 19.1465C38.0016 19.1599 38.0282 19.1865 38.0416 19.1999Z"
                fill="white"
            />
            <path
                d="M45.1592 36.8799C44.3458 39.6266 41.9992 41.3333 38.7992 41.3333H30.1325C29.6125 41.3333 29.2792 40.7599 29.4925 40.2799C29.8925 39.3466 30.1458 38.2933 30.1458 37.3333C30.1458 33.2933 26.8525 29.9999 22.8125 29.9999C21.7992 29.9999 20.8125 30.2133 19.9058 30.6133C19.4125 30.8266 18.8125 30.4933 18.8125 29.9599V27.9999C18.8125 24.3733 20.9992 21.8399 24.3992 21.4133C24.7325 21.3599 25.0925 21.3333 25.4658 21.3333H38.7992C39.1458 21.3333 39.4792 21.3466 39.7992 21.3999C42.4925 21.7066 44.4392 23.3466 45.1592 25.7866C45.2925 26.2266 44.9725 26.6666 44.5192 26.6666H41.4658C38.5725 26.6666 36.2792 29.3066 36.9058 32.3066C37.3458 34.4933 39.3725 35.9999 41.5992 35.9999H44.5192C44.9858 35.9999 45.2925 36.4533 45.1592 36.8799Z"
                fill="white"
            />
        </svg>
    );
};

export default DreamBlue;
