import React from 'react';

const ArrowIcon = () => {
    return (
        <svg
            width="60"
            height="44"
            viewBox="0 0 60 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="60" height="44" rx="4" fill="#05668D" />
            <path
                d="M32.8359 14.9185L39.9176 22.0001L32.8359 29.0818"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.082 22H39.717"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowIcon;
