import React, { useState } from 'react';
import {
    Container,
    Button,
    List,
    ListItem,
    Box,
    AppBar,
    Link,
    useMediaQuery,
    useTheme,
    Stack,
    ImageList,
    ImageListItem
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileNav from '../MobileNav/MobileNav';
import SmsIcon from '../../Assets/Icons/SmsIcon';
import MenuIcon from '../../Assets/Icons/MenuIcon';
import CloseIcon from '../../Assets/Icons/CloseIcon';
import { useAppDispatch } from '../../Feature/Store/Store';
import { openSignupModalState } from '../../Feature/App/AppSlice';
import BasicModal from '../CommunityModal/CommunityModal';

const Navbar = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    // List of nav items
    const navItems = [
        { name: 'ABOUT US', location: '/about' },
        { name: 'BLOG', location: '/blog' },
        { name: 'COMMUNITY' }
    ];

    const theme = useTheme();

    // Responsive navbar for mobile devices
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    // Style for nav items
    const navText = {
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'all .4s ease',
        '&:hover': { textDecoration: 'underline', color: 'primaryDark.main' }
    };

    // Style for nav items when they are clicked to be added later in project
    const onAction = {
        color: '#23282B',
        cursor: 'pointer',
        fontWeight: 700
    };

    return (
        <AppBar
            position="fixed"
            color="transparent"
            sx={{
                boxShadow: 'none',
                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    px: isMobile ? '16px' : '60px'
                }} // left and right padding changes when breakpoint is 'lg'
                maxWidth="xl"
                disableGutters
            >
                <Stack
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: isMobile ? null : '100px',
                        py: '0px'
                    }}
                >
                    <ImageList
                        onClick={() => {
                            setMenu(false);
                            navigate('/');
                        }}
                        sx={{
                            transition: 'all .4s ease',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                        cols={1}
                    >
                        <ImageListItem>
                            <img
                                src={require('./../../Assets/Images/logo.png')}
                                alt="logo"
                            />
                        </ImageListItem>
                    </ImageList>
                    {!isMobile ? (
                        <>
                            <Box sx={{ display: 'flex', gap: 10 }}>
                                {navItems.map(
                                    (
                                        item // Mapping through list of nav items
                                    ) => (
                                        <List key={item.name}>
                                            <ListItem disablePadding>
                                                <Link
                                                    onClick={() => {
                                                        item.name !==
                                                        'COMMUNITY'
                                                            ? navigate(
                                                                  item.location ??
                                                                      ''
                                                              )
                                                            : handleOpen();
                                                    }}
                                                    // href={item?.location}
                                                    variant="body1"
                                                    underline="none"
                                                    color="navTextColor.main"
                                                    sx={
                                                        location.pathname ===
                                                            item.location ||
                                                        location.pathname.includes(
                                                            item.name.toLocaleLowerCase()
                                                        )
                                                            ? onAction
                                                            : navText
                                                    }
                                                >
                                                    {item.name}
                                                </Link>
                                            </ListItem>
                                        </List>
                                    )
                                )}
                            </Box>
                            <Box>
                                <Link
                                    href="https://app.jobfactor.com/"
                                    target="_blank"
                                >
                                    <Button
                                        sx={{
                                            gap: '12px',
                                            padding: '16px 32px',
                                            borderRadius: '8px',
                                            fontWeight: 500,
                                            textTransform: 'none',
                                            lineHeight: '24px',
                                            letterSpacing: '0.024px'
                                        }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Signup for Beta
                                        <SmsIcon />
                                    </Button>
                                </Link>
                            </Box>
                        </>
                    ) : (
                        <Box onClick={() => setMenu(!menu)}>
                            {!menu ? <MenuIcon /> : <CloseIcon />}
                        </Box>
                    )}
                </Stack>
                {menu && isMobile ? <MobileNav setMenu={setMenu} /> : null}
            </Container>
            <BasicModal open={open} setOpen={setOpen} />
        </AppBar>
    );
};

export default Navbar;
