import React from 'react';
import { Container, Stack } from '@mui/material';
import Slider from 'react-slick';
import slide1 from '../../Assets/Images/slide1.png';
import slide2 from '../../Assets/Images/slide2.png';
import slide3 from '../../Assets/Images/slide3.png';
import ArrowRightIcon from '../../Assets/Icons/ArrowRightIcon';
import ArrowLeftIcon from '../../Assets/Icons/ArrowLeftIcon';

const Slideshow = () => {
    const slides = [slide1, slide2, slide3];

    // const SlickButton = ({
    //     currentSlide,
    //     slideCount,
    //     children,
    //     ...props
    // }: any) => <span {...props}>{children}</span>;

    const ArrowLeft = ({ onClick }: any) => {
        return (
            <Stack
                position="absolute"
                zIndex={5}
                onClick={onClick}
                justifyContent="center"
                top="50%"
                py="10px"
                px="5px"
                sx={{
                    transform: 'translate(0, -50%)'
                }}
            >
                <ArrowLeftIcon />
            </Stack>
        );
    };
    const ArrowRight = ({ onClick }: any) => {
        return (
            <Stack
                position="absolute"
                right={0}
                top="50%"
                zIndex={5}
                onClick={onClick}
                justifyContent="center"
                py="10px"
                px="5px"
                sx={{
                    transform: 'translate(0, -50%)'
                }}
            >
                <ArrowRightIcon />
            </Stack>
        );
    };

    const settings = {
        infinite: false,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    };

    return (
        <Stack
            sx={{
                background:
                    'linear-gradient(57deg, #F1FBFF -26.99%, rgba(255, 251, 243, 0.92) 65.3%)'
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    pl: '100px',
                    pr: '160px'
                }}
                maxWidth="xl"
                disableGutters
            >
                <Stack mx="auto" maxWidth="1140px" py="98px">
                    <Slider {...settings}>
                        {slides.map((slide) => (
                            <Stack key={slide}>
                                <img
                                    style={{
                                        margin: '0 auto'
                                    }}
                                    src={slide}
                                    alt="slideshow"
                                />
                            </Stack>
                        ))}
                    </Slider>
                </Stack>
            </Container>
        </Stack>
    );
};

export default Slideshow;
