import { createTheme, PaletteColorOptions, Theme } from '@mui/material';

declare module '@mui/material' {
    interface PaletteOptions {
        navTextColor: PaletteColorOptions;
        primary2: PaletteColorOptions;
        primaryDark: PaletteColorOptions;
        primaryLight: PaletteColorOptions;
        textGrey: PaletteColorOptions;
        primaryBlue: PaletteColorOptions;
    }

    interface SimplePaletteColorOptions {
        main: string;
        medium?: string;
        iconBlue?: string;
        iconGreen?: string;
        iconPurple?: string;
        iconRed?: string;
    }

    interface PaletteColor {
        main: string;
        medium: string;
        iconBlue: string;
        iconGreen: string;
        iconPurple: string;
        iconRed: string;
    }
}

export const theme: Theme = createTheme({
    palette: {
        primary: {
            main: '#05668D',
            iconBlue: '#4CBFFF',
            iconGreen: '#56E39F',
            iconPurple: '#EF6CE2',
            iconRed: '#EF6F6C',
            light: '#fff'
        },
        navTextColor: {
            main: '#808080'
        },
        primary2: {
            main: '#FFC24C'
        },
        primaryDark: {
            light: '#494949',
            main: '#23282B'
        },
        primaryLight: {
            main: '#fff',
            medium: '#EBF6FF'
        },
        textGrey: {
            main: '#E6F0F4'
        },
        primaryBlue: {
            main: '#05668D',
            dark: '#0E3A4B'
        }
    },
    typography: {
        fontFamily: 'Outfit',
        fontSize: 16,
        fontWeightRegular: 400
    }
});
