import React from 'react';
import {
    Container,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
    ImageList,
    ImageListItem
} from '@mui/material';
import background from '../../Assets/Images/heroBackground.png';
import AboutPageIcon from '../../Assets/Icons/AboutPageIcon';
import AboutPageImage from '../../Assets/Images/aboutPageImage.png';
import AboutPageImage2 from '../../Assets/Images/AboutImage2.png';
import backgroundTexture from '../../Assets/Images/OurApproachBackgroundTexture.png';
import bj from '../../Assets/Images/bj.png';
import samson from '../../Assets/Images/samson.png';
import thad from '../../Assets/Images/thad.png';
import michael from '../../Assets/Images/michael.png';
import AboutPageArrowIcon from '../../Assets/Icons/AboutPageArrowIcon';
import Vector from '../../Assets/Icons/Vector';
import Newsletter from '../Newsletter/Newsletter';
import Rectangle from '../../Assets/Icons/Rectangle';
import Frame from '../../Assets/Icons/Frame';

type AboutSectionProps = {
    formik: any;
};
const About = ({ formik }: AboutSectionProps) => {
    const theme = useTheme();
    // const matches = useMediaQuery('(max-width:789px)')
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack
            pt={isMedium ? '120px' : '145px'}
            position="relative"
            sx={{
                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`,
                backgroundBlendMode: 'overlay'
            }}
        >
            <Stack
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                right={0}
                sx={{
                    backgroundImage: `url(${background})`,
                    // backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain'
                }}
            ></Stack>
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    zIndex: 1,
                    px: isMobile ? '16px' : '60px',
                    pb: isMedium ? undefined : '87px'
                }}
            >
                <Typography
                    mb={isMedium ? '40px' : '18px'}
                    variant={'h2'}
                    fontSize={isMedium ? '40px' : '64px'}
                    fontFamily="Poppins"
                    fontWeight={600}
                    lineHeight="120%"
                    color="primaryDark.main"
                    // letterSpacing="-3.2px"
                >
                    Welcome to{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                        Jobfactor
                    </span>{' '}
                    <span style={{ color: theme.palette.primary2.main }}>
                        Inc.
                    </span>
                </Typography>
                <Stack
                    position="relative"
                    flexDirection={isMedium ? 'column' : 'row'}
                    alignItems={isSmall ? 'flex-start' : 'center'}
                    justifyContent={isMedium ? undefined : 'space-between'}
                    gap={isMedium ? undefined : '98px'}
                >
                    <Stack
                        width="100%"
                        maxWidth={isMedium ? undefined : '663px'}
                    >
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            // pr={isSmall ? '16px' : undefined}
                            mb={isSmall ? '20px' : '40px'}
                        >
                            <Stack
                                py={isSmall ? '4px' : '8px'}
                                px={isSmall ? '16px' : '32px'}
                                borderRadius="64px"
                                sx={{ backgroundColor: 'primary2.main' }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography
                                    fontFamily="Poppins"
                                    fontWeight="700"
                                    fontSize={isSmall ? '14px' : '20px'}
                                    lineHeight={isSmall ? '200%' : '140%'}
                                    color="primaryLight.main"
                                >
                                    Our Mission
                                </Typography>
                            </Stack>
                            <AboutPageIcon color="#05668D" />
                        </Stack>
                        <Typography
                            mb={isSmall ? '12px' : '28px'}
                            variant={'h2'}
                            fontFamily="Poppins"
                            fontSize={isSmall ? '24px' : '48px'}
                            fontWeight={600}
                            lineHeight="130%"
                            color="primaryDark.main"
                            letterSpacing={isSmall ? '-1.2px' : '-2.4px'}
                        >
                            Bridging the gap between{' '}
                            <span style={{ color: theme.palette.primary.main }}>
                                Talent
                            </span>{' '}
                            and{' '}
                            <span
                                style={{ color: theme.palette.primary2.main }}
                            >
                                Opportunity
                            </span>
                        </Typography>
                        <Typography
                            textAlign={isSmall ? 'justify' : undefined}
                            fontSize={isSmall ? '14px' : '20px'}
                            fontWeight={400}
                            lineHeight="150%"
                            color="navTextColor.main"
                        >
                            At Jobfactor, our mission is “to revolutionize the
                            talent acquisition process by empowering
                            professionals to showcase their skills, connect with
                            value-aligned employers while bridging the gap
                            between talent and opportunity”.
                        </Typography>
                    </Stack>
                    <Stack>
                        <ImageList cols={1}>
                            <ImageListItem>
                                <img src={AboutPageImage} alt="about page" />
                            </ImageListItem>
                        </ImageList>
                    </Stack>
                </Stack>
            </Container>
            <Stack
                position="relative"
                top={isMedium ? '-55px' : undefined}
                // zIndex={2}
                pt={isMedium ? '107px' : '123px'}
                pb="100px"
                sx={{
                    backgroundColor: 'primaryBlue.main',
                    backgroundImage: `url(${backgroundTexture})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'overlay'
                }}
            >
                <Container
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        zIndex: 1,
                        px: isMobile ? '16px' : '60px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Stack
                        zIndex={2}
                        mb={isMedium ? '40px' : '32px'}
                        px={isMedium ? '16px' : '28px'}
                        py={isMedium ? '12px' : '28px'}
                        sx={{ backgroundColor: 'primary2.main' }}
                        alignSelf="flex-start"
                        borderRadius={isMedium ? '10px' : '16PX'}
                    >
                        <Typography
                            color="primaryLight.main"
                            variant="h3"
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: isMedium ? '28px' : '52px',
                                fontWeight: 600,
                                lineHeight: '32px',
                                letterSpacing: isMedium ? '-1.4px' : '-2.6px'
                            }}
                        >
                            About Jobfactor
                        </Typography>
                    </Stack>
                    <Stack
                        flexDirection={isMedium ? 'column' : 'row'}
                        justifyContent="space-between"
                        gap="32px"
                    >
                        <Stack
                            width="100%"
                            maxWidth={isMedium ? undefined : '644px'}
                        >
                            <Typography
                                fontFamily="Outfit"
                                color="primaryLight.medium"
                                fontSize={isMedium ? '16px' : '20px'}
                                fontWeight={isMedium ? 300 : 400}
                                lineHeight="150%"
                                letterSpacing="0.03px"
                                textAlign={isMedium ? 'justify' : undefined}
                            >
                                Jobfactor is more than just another tech job
                                platform; we are a thriving community and
                                ecosystem of employers and professionals
                                empowered by shared values and a drive for
                                success. At Jobfactor, we recognize that the job
                                market is constantly evolving, and it's crucial
                                to find the perfect fit quickly and efficiently.
                                That's why we've developed Jobfactor—an
                                innovative platform that leverages advanced
                                algorithms to seamlessly connect job seekers and
                                employers. <br /> <br /> With our proprietary
                                data-driven Jobfactor score and AI-powered job
                                fit metrics, we empower both employers and job
                                seekers with the necessary tools to ensure
                                ultimate organizational and career success.{' '}
                            </Typography>
                        </Stack>
                        <Stack
                            width="100%"
                            maxWidth={isMedium ? undefined : '644px'}
                        >
                            <Typography
                                color="primaryLight.medium"
                                fontSize={isMedium ? '16px' : '20px'}
                                fontWeight={isMedium ? 300 : 400}
                                lineHeight="150%"
                                letterSpacing="0.03px"
                                textAlign={isMedium ? 'justify' : undefined}
                            >
                                Jobfactor serves as the ultimate talent
                                marketplace, bridging the gap between talent and
                                opportunities. Our mission is to revolutionize
                                the talent acquisition process by empowering
                                professionals to showcase their skills, connect
                                with value-aligned employers, and bridge the gap
                                between talent and opportunity.{' '}
                                {isMedium ? null : (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                )}{' '}
                                Our diverse team of talented entrepreneurs
                                brings together a wealth of experience spanning
                                various fields, including Human Resources,
                                Finance, IT, Organizational Leadership, Software
                                Development, Product Design, and Marketing. Join
                                us today and let's forge partnerships to connect
                                the right talent with the perfect job.
                            </Typography>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
            <Stack
                pt={isMedium ? '50px' : '106px'}
                pb={isMedium ? undefined : '106px'}
            >
                <Container
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        zIndex: 1,
                        px: isMobile ? '16px' : '60px'
                    }}
                >
                    <Stack
                        position="relative"
                        flexDirection={isMedium ? 'column-reverse' : 'row'}
                        alignItems={isSmall ? 'flex-start' : 'center'}
                        justifyContent={isMedium ? undefined : 'space-between'}
                        gap={isMedium ? undefined : '98px'}
                    >
                        <Stack>
                            <ImageList cols={1}>
                                <ImageListItem>
                                    <img
                                        src={AboutPageImage2}
                                        alt="about page"
                                    />
                                </ImageListItem>
                            </ImageList>
                        </Stack>
                        <Stack
                            width="100%"
                            maxWidth={isMedium ? undefined : '663px'}
                        >
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                // pr={isSmall ? '16px' : undefined}
                                mb={isSmall ? '20px' : '40px'}
                            >
                                <Stack
                                    py={isSmall ? '4px' : '8px'}
                                    px={isSmall ? '16px' : '32px'}
                                    borderRadius="64px"
                                    sx={{
                                        backgroundColor: 'primary.main'
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography
                                        fontFamily="Poppins"
                                        fontWeight="700"
                                        fontSize={isSmall ? '14px' : '20px'}
                                        lineHeight={isSmall ? '200%' : '140%'}
                                        color="primaryLight.main"
                                    >
                                        Our Vision
                                    </Typography>
                                </Stack>
                                <AboutPageIcon color="#FFC24C" />
                            </Stack>
                            <Typography
                                mb={isSmall ? '12px' : '28px'}
                                variant={'h2'}
                                fontFamily="Poppins"
                                fontSize={isSmall ? '24px' : '48px'}
                                fontWeight={600}
                                lineHeight="130%"
                                color="primaryDark.main"
                                letterSpacing={isSmall ? '-1.2px' : '-2.4px'}
                            >
                                <span
                                    style={{
                                        color: theme.palette.primary.main
                                    }}
                                >
                                    World's Leading
                                </span>{' '}
                                and{' '}
                                <span
                                    style={{
                                        color: theme.palette.primary2.main
                                    }}
                                >
                                    Dynamic Job-Focused
                                </span>{' '}
                                Community
                            </Typography>
                            <Typography
                                textAlign={isSmall ? 'justify' : undefined}
                                fontSize={isSmall ? '14px' : '20px'}
                                fontWeight={400}
                                lineHeight="150%"
                                color="navTextColor.main"
                            >
                                Our vision is to be the world’s leading and
                                dynamic job-focused community by empowering
                                professionals and employers through cutting-edge
                                technology. Unlocking unlimited opportunities
                                with a purpose-enabling career advancement,
                                meaningful connections, and organizational
                                excellence.
                            </Typography>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
            <Stack
                position="relative"
                pb={isMedium ? undefined : '197px'}
                height="fit-content"
                sx={{
                    backgroundColor: 'primaryBlue.main',
                    backgroundImage: `url(${backgroundTexture})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'overlay'
                }}
            >
                <Stack
                    position="absolute"
                    width="100%"
                    height="100%"
                    left="50%"
                    maxWidth="1536px"
                    sx={{
                        transform: 'translate(-50%, 0)'
                    }}
                >
                    <Stack position="absolute" top={0} right={0} bottom={0}>
                        {isMedium ? <Rectangle /> : <Frame />}
                    </Stack>
                </Stack>
                <Container
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        pt: isMedium ? '20px' : '102px',
                        zIndex: 1,
                        px: isMobile ? '16px' : '60px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={isMedium ? '40px' : '62px'}
                    >
                        <Stack
                            zIndex={2}
                            px={isMedium ? '16px' : '28px'}
                            py={isMedium ? '12px' : '28px'}
                            sx={{ backgroundColor: 'primary2.main' }}
                            // alignSelf="flex-start"
                            borderRadius={isMedium ? '10px' : '16PX'}
                        >
                            <Typography
                                color="primaryLight.main"
                                variant="h3"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: isMedium ? '28px' : '52px',
                                    fontWeight: 600,
                                    lineHeight: '32px',
                                    letterSpacing: isMedium
                                        ? '-1.4px'
                                        : '-2.6px'
                                }}
                            >
                                Meet the Team
                            </Typography>
                        </Stack>
                        {isMedium ? <Vector /> : null}
                    </Stack>
                    <Stack
                        mb={isMedium ? '79px' : undefined}
                        flexDirection="row"
                        flexWrap={isMedium ? 'wrap' : 'nowrap'}
                        gap="20px"
                        justifyContent={isSmall ? undefined : 'space-between'}
                    >
                        <Stack
                            width={
                                isSmall ? '46%' : isMedium ? '169px' : '315px'
                            }
                            px="12px"
                            pb="12px"
                            gap="12px"
                            borderRadius="12px"
                            sx={{ backgroundColor: 'primaryLight.main' }}
                        >
                            <ImageList cols={1}>
                                <ImageListItem>
                                    <img src={bj} alt="team" />
                                </ImageListItem>
                            </ImageList>
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <Typography
                                        fontSize={isMedium ? '16px' : '20px'}
                                        fontWeight="700"
                                        color="primaryDark.main"
                                    >
                                        Samson Olanipekun
                                    </Typography>
                                    <Typography
                                        fontSize={isMedium ? '14px' : '16px'}
                                        fontWeight="400"
                                        color="navTextColor.main"
                                    >
                                        Founder/CEO
                                    </Typography>
                                </Stack>
                                {isMedium ? null : <AboutPageArrowIcon />}
                            </Stack>
                        </Stack>
                        <Stack
                            width={
                                isSmall ? '46%' : isMedium ? '179px' : '315px'
                            }
                            px="12px"
                            pb="12px"
                            gap="12px"
                            borderRadius="12px"
                            sx={{ backgroundColor: 'primaryLight.main' }}
                        >
                            <ImageList cols={1}>
                                <ImageListItem>
                                    <img src={michael} alt="team" />
                                </ImageListItem>
                            </ImageList>
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <Typography
                                        fontSize={isMedium ? '16px' : '20px'}
                                        fontWeight="700"
                                        color="primaryDark.main"
                                    >
                                        Michael Ajanaku
                                    </Typography>
                                    <Typography
                                        fontSize={isMedium ? '14px' : '16px'}
                                        fontWeight="400"
                                        color="navTextColor.main"
                                    >
                                        CTO
                                    </Typography>
                                </Stack>
                                {isMedium ? null : <AboutPageArrowIcon />}
                            </Stack>
                        </Stack>
                        <Stack
                            width={
                                isSmall ? '46%' : isMedium ? '169px' : '315px'
                            }
                            px="12px"
                            pb="12px"
                            gap="12px"
                            borderRadius="12px"
                            sx={{ backgroundColor: 'primaryLight.main' }}
                        >
                            <ImageList cols={1}>
                                <ImageListItem>
                                    <img src={samson} alt="team" />
                                </ImageListItem>
                            </ImageList>
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <Typography
                                        fontSize={isMedium ? '16px' : '20px'}
                                        fontWeight="700"
                                        color="primaryDark.main"
                                    >
                                        Samson Adediji
                                    </Typography>
                                    <Typography
                                        fontSize={isMedium ? '14px' : '16px'}
                                        fontWeight="400"
                                        color="navTextColor.main"
                                    >
                                        Head of Design
                                    </Typography>
                                </Stack>
                                {isMedium ? null : <AboutPageArrowIcon />}
                            </Stack>
                        </Stack>
                        <Stack
                            width={
                                isSmall ? '46%' : isMedium ? '169px' : '315px'
                            }
                            px="12px"
                            pb="12px"
                            gap="12px"
                            borderRadius="12px"
                            sx={{ backgroundColor: 'primaryLight.main' }}
                        >
                            <ImageList cols={1}>
                                <ImageListItem>
                                    <img src={thad} alt="team" />
                                </ImageListItem>
                            </ImageList>
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <Typography
                                        fontSize={isMedium ? '16px' : '20px'}
                                        fontWeight="700"
                                        color="primaryDark.main"
                                    >
                                        Thad Huffmaster
                                    </Typography>
                                    <Typography
                                        fontSize={isMedium ? '14px' : '16px'}
                                        fontWeight="400"
                                        color="navTextColor.main"
                                    >
                                        Adviser
                                    </Typography>
                                </Stack>
                                {isMedium ? null : <AboutPageArrowIcon />}
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
                <Stack zIndex={2}>
                    {isMedium ? <Newsletter formik={formik} /> : null}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default About;
