import React from 'react';

const DreamJobEclipse1 = () => {
    return (
        <svg
            width="96"
            height="24"
            viewBox="0 0 96 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill="#4CBFFF" />
            <circle cx="38" cy="12" r="10" fill="#4CBFFF" />
            <circle cx="60" cy="12" r="8" fill="#4CBFFF" />
            <circle cx="78" cy="12" r="6" fill="#4CBFFF" />
            <circle cx="92" cy="12" r="4" fill="#4CBFFF" />
        </svg>
    );
};

export default DreamJobEclipse1;
