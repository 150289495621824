import React from 'react';

const Frame = () => {
    return (
        <svg
            width="420"
            height="803"
            viewBox="0 0 420 803"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="path-1-inside-1_357_13515" fill="white">
                <path d="M472 -98C399.234 -98 340.252 -44.9388 340.252 20.5225V183.247H65.6323C29.3618 183.247 0 209.793 0 242.5V773.43C0 838.923 58.7237 892 131.265 892V669.086H405.729C441.999 669.086 471.516 642.523 471.516 609.769V-97.7777M340.494 555.056H131.506V305.786H340.494V555.056Z" />
            </mask>
            <path
                d="M340.252 183.247V187.247H344.252V183.247H340.252ZM131.265 892V896H135.265V892H131.265ZM131.265 669.086V665.086H127.265V669.086H131.265ZM340.494 555.056V559.056H344.494V555.056H340.494ZM131.506 555.056H127.506V559.056H131.506V555.056ZM131.506 305.786V301.786H127.506V305.786H131.506ZM340.494 305.786H344.494V301.786H340.494V305.786ZM472 -102C397.434 -102 336.252 -47.5354 336.252 20.5225H344.252C344.252 -42.3421 401.035 -94 472 -94V-102ZM336.252 20.5225V183.247H344.252V20.5225H336.252ZM340.252 179.247H65.6323V187.247H340.252V179.247ZM65.6323 179.247C27.5455 179.247 -4 207.211 -4 242.5H4C4 212.376 31.1782 187.247 65.6323 187.247V179.247ZM-4 242.5V773.43H4V242.5H-4ZM-4 773.43C-4 841.504 56.9062 896 131.265 896V888C60.5411 888 4 836.341 4 773.43H-4ZM135.265 892V669.086H127.265V892H135.265ZM131.265 673.086H405.729V665.086H131.265V673.086ZM405.729 673.086C443.806 673.086 475.516 645.114 475.516 609.769H467.516C467.516 639.933 440.192 665.086 405.729 665.086V673.086ZM475.516 609.769V-97.7777H467.516V609.769H475.516ZM340.494 551.056H131.506V559.056H340.494V551.056ZM135.506 555.056V305.786H127.506V555.056H135.506ZM131.506 309.786H340.494V301.786H131.506V309.786ZM336.494 305.786V555.056H344.494V305.786H336.494Z"
                fill="#FFC24C"
                mask="url(#path-1-inside-1_357_13515)"
            />
        </svg>
    );
};

export default Frame;
