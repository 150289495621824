import React from 'react';

const LinkedinIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M3.95833 1.5625C2.98033 1.5625 2.1875 2.35533 2.1875 3.33333C2.1875 4.31134 2.98033 5.10417 3.95833 5.10417C4.93634 5.10417 5.72917 4.31134 5.72917 3.33333C5.72917 2.35533 4.93634 1.5625 3.95833 1.5625Z"
                fill="#808080"
            />
            <path
                d="M2.29167 6.5625C2.23414 6.5625 2.1875 6.60914 2.1875 6.66667V17.5C2.1875 17.5575 2.23414 17.6042 2.29167 17.6042H5.625C5.68253 17.6042 5.72917 17.5575 5.72917 17.5V6.66667C5.72917 6.60914 5.68253 6.5625 5.625 6.5625H2.29167Z"
                fill="#808080"
            />
            <path
                d="M7.70833 6.5625C7.6508 6.5625 7.60417 6.60914 7.60417 6.66667V17.5C7.60417 17.5575 7.6508 17.6042 7.70833 17.6042H11.0417C11.0992 17.6042 11.1458 17.5575 11.1458 17.5V11.6667C11.1458 11.2523 11.3105 10.8548 11.6035 10.5618C11.8965 10.2688 12.2939 10.1042 12.7083 10.1042C13.1227 10.1042 13.5202 10.2688 13.8132 10.5618C14.1062 10.8548 14.2708 11.2523 14.2708 11.6667V17.5C14.2708 17.5575 14.3175 17.6042 14.375 17.6042H17.7083C17.7659 17.6042 17.8125 17.5575 17.8125 17.5V10.3169C17.8125 8.29458 16.0537 6.71249 14.0415 6.89541C13.421 6.95183 12.8075 7.10609 12.2346 7.35162L11.1458 7.81822V6.66667C11.1458 6.60914 11.0992 6.5625 11.0417 6.5625H7.70833Z"
                fill="#808080"
            />
        </svg>
    );
};

export default LinkedinIcon;
