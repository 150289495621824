import React from 'react';

const DreamJobIcon1 = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#4CBFFF" />
            <path
                d="M22.6654 32C19.7187 32 17.332 34.3867 17.332 37.3333C17.332 38.3333 17.612 39.28 18.1054 40.08C19.0254 41.6267 20.7187 42.6667 22.6654 42.6667C24.612 42.6667 26.3054 41.6267 27.2254 40.08C27.7187 39.28 27.9987 38.3333 27.9987 37.3333C27.9987 34.3867 25.612 32 22.6654 32ZM25.292 36.8933L22.452 39.52C22.2654 39.6933 22.012 39.7867 21.772 39.7867C21.5187 39.7867 21.2654 39.6933 21.0654 39.4933L19.7454 38.1733C19.3587 37.7867 19.3587 37.1467 19.7454 36.76C20.132 36.3733 20.772 36.3733 21.1587 36.76L21.7987 37.4L23.932 35.4267C24.332 35.0533 24.972 35.08 25.3454 35.48C25.7187 35.88 25.692 36.52 25.292 36.8933Z"
                fill="white"
            />
            <path
                d="M44.668 28.666H41.3346C39.868 28.666 38.668 29.866 38.668 31.3327C38.668 32.7993 39.868 33.9993 41.3346 33.9993H44.668C45.0413 33.9993 45.3346 33.706 45.3346 33.3327V29.3327C45.3346 28.9593 45.0413 28.666 44.668 28.666Z"
                fill="white"
            />
            <path
                d="M38.0416 19.2009C38.4416 19.5875 38.1082 20.1875 37.5482 20.1875L26.5082 20.1742C25.8682 20.1742 25.5482 19.4009 26.0016 18.9475L28.3349 16.6009C30.3082 14.6409 33.4949 14.6409 35.4682 16.6009L37.9882 19.1475C38.0016 19.1609 38.0282 19.1875 38.0416 19.2009Z"
                fill="white"
            />
            <path
                d="M45.1592 36.8807C44.3458 39.6273 41.9992 41.334 38.7992 41.334H30.1325C29.6125 41.334 29.2792 40.7607 29.4925 40.2807C29.8925 39.3473 30.1458 38.294 30.1458 37.334C30.1458 33.294 26.8525 30.0007 22.8125 30.0007C21.7992 30.0007 20.8125 30.214 19.9058 30.614C19.4125 30.8273 18.8125 30.494 18.8125 29.9607V28.0007C18.8125 24.374 20.9992 21.8407 24.3992 21.414C24.7325 21.3607 25.0925 21.334 25.4658 21.334H38.7992C39.1458 21.334 39.4792 21.3473 39.7992 21.4007C42.4925 21.7073 44.4392 23.3473 45.1592 25.7873C45.2925 26.2273 44.9725 26.6673 44.5192 26.6673H41.4658C38.5725 26.6673 36.2792 29.3073 36.9058 32.3073C37.3458 34.494 39.3725 36.0007 41.5992 36.0007H44.5192C44.9858 36.0007 45.2925 36.454 45.1592 36.8807Z"
                fill="white"
            />
        </svg>
    );
};

export default DreamJobIcon1;
