import React from 'react';
type AboutPageIconProps = {
    color: string;
};
const AboutPageIcon = ({ color }: AboutPageIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="34"
            viewBox="0 0 42 34"
            fill="none"
        >
            <path
                d="M20.597 20.3203C20.9298 19.5854 19.7373 19.0031 18.6974 19.4052C18.0873 19.6409 16.3263 21.083 16.3679 21.3048C16.6314 22.6637 19.8621 21.9149 20.597 20.3203Z"
                fill={color}
            />
            <path
                d="M29.4325 17.3273C27.3387 18.2008 15.2199 27.0057 14.4296 28.212C13.9997 28.8637 15.3586 29.4877 16.246 29.0578C17.286 28.5725 26.1879 21.7366 30.0703 18.3672C30.6111 17.9096 30.0842 17.0638 29.4325 17.3273Z"
                fill={color}
            />
            <path
                d="M2.68413 16.5908C0.729044 17.8942 0.368531 18.3934 1.08956 18.7539C2.50388 19.475 3.41904 18.3934 5.15228 17.2703C6.33088 16.4938 4.25098 15.537 2.68413 16.5908Z"
                fill={color}
            />
            <path
                d="M8.77354 19.2937C10.2572 18.5727 12.3787 17.1029 14.8884 15.023C15.9561 14.1356 18.1469 12.527 19.7553 11.4455C22.6256 9.51812 22.9445 9.2408 22.4869 9.07441C22.0987 8.92188 22.0155 8.96362 17.3011 11.8061C12.5728 14.6625 9.10632 16.9088 7.49787 18.1844C5.47347 19.779 6.81844 20.2505 8.77354 19.2937Z"
                fill={color}
            />
            <path
                d="M7.96895 14.9237C8.99503 14.3552 10.964 12.9686 15.1654 10.029C20.9752 5.96629 24.5942 3.62296 25.1488 3.02671L25.5648 2.58297C24.9685 2.09767 24.608 1.65396 22.6945 2.6523C20.1155 3.98347 11.1304 10.3755 7.76097 13.1764C5.86135 14.7294 6.41599 15.7833 7.96895 14.9237Z"
                fill={color}
            />
            <path
                d="M6.21566 23.7882C4.7736 24.939 4.73199 24.9945 5.27276 25.3966C6.32657 26.1731 10.209 24.2873 9.58506 23.289C8.98882 22.3322 7.81022 22.4986 6.21566 23.7882Z"
                fill={color}
            />
            <path
                d="M40.0592 0.31211C39.0608 0.811282 18.4838 14.7188 12.1471 19.558C9.44329 21.624 11.1627 21.9429 13.0346 21.0416C15.4334 19.8907 16.612 18.2962 33.4035 7.13412C40.1285 2.65547 42.0004 1.1995 42.0004 0.423009C42.0004 -0.0484319 40.8911 -0.103867 40.0592 0.31211Z"
                fill={color}
            />
            <path
                d="M30.4839 12.8904C31.718 11.7673 34.7546 9.49325 35.6143 8.88315C35.9748 8.59197 35.0319 8.41171 34.5466 8.67516C26.2964 13.1954 18.7672 19.255 21.2631 19.255C22.303 19.255 27.2393 15.8716 30.4839 12.8904Z"
                fill={color}
            />
            <path
                d="M34.7841 12.9188C31.2899 14.8739 30.6243 15.5671 31.789 16.1217C32.7042 16.5516 32.9815 16.4268 35.4912 14.5272C38.7775 12.0313 37.5295 11.3797 34.7841 12.9188Z"
                fill={color}
            />
            <path
                d="M18.3486 31.7043C16.7818 32.7165 16.5322 33.3266 17.5028 33.798C18.5427 34.3111 20.0957 33.6178 20.8306 32.3283C21.205 31.6488 19.2083 31.1497 18.3486 31.7043Z"
                fill={color}
            />
            <path
                d="M34.5738 19.2813C33.0486 20.3906 29.485 23.136 25.769 25.618C20.6247 29.0568 19.6125 30.1799 21.1794 30.6375C22.1361 30.9148 24.2576 29.445 28.0569 25.7844C29.4296 24.4533 32.9931 21.4721 34.8789 20.0162C35.905 19.2258 35.5999 18.5325 34.5738 19.2813Z"
                fill={color}
            />
        </svg>
    );
};

export default AboutPageIcon;
