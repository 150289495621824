import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

type PostBody = {
    fullName: String;
    email: String;
    checkbox: boolean;
};

const useCreateUser = async ({ fullName, email, checkbox }: PostBody) => {
    const body = {
        attributes: { FULLNAME: fullName },
        listIds: [3],
        updateEnabled: false,
        email: email,
        emailBlacklisted: false,
        smsBlacklisted: false
    };

    const response = await axios.post(
        'https://api.sendinblue.com/v3/contacts',
        body,
        {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'api-key': process.env.REACT_APP_API_KEY
            }
        }
    );

    const data = await response.data;
    return data;
};

export const useCreateUserData = () => {
    return useMutation(useCreateUser);
};
