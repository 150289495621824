import React from 'react';

const LinkedinIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <g clipPath="url(#clip0_1565_6027)">
                <path
                    d="M19.9995 0C8.95452 0 0 8.95459 0 19.9997C0 31.0451 8.95417 40 19.9995 40C31.0451 40 40 31.0451 40 19.9997C39.9997 8.95459 31.0451 0 19.9995 0ZM11.7006 9.0203C13.0611 9.0203 14.1646 10.0266 14.1646 11.2681C14.1646 12.5096 13.0611 13.5159 11.7006 13.5159C10.3395 13.5159 9.2367 12.5096 9.2367 11.2681C9.2367 10.0266 10.3395 9.0203 11.7006 9.0203ZM14.1832 28.246H9.31485V15.5424H14.1832V28.246ZM30.7263 28.246H25.8344V21.2605C25.8344 20.463 25.6864 19.8987 25.3914 19.5674C25.0961 19.2368 24.6815 19.0708 24.1479 19.0708C23.5576 19.0708 23.0786 19.2942 22.712 19.741C22.3451 20.1878 22.1622 20.989 22.1622 22.1455V28.246H17.2935V15.5424H21.8268V17.6114C22.5042 16.7666 23.1903 16.1625 23.884 15.7994C24.5777 15.4366 25.4225 15.2554 26.4195 15.2554C27.7675 15.2554 28.8219 15.6558 29.5837 16.4574C30.3449 17.2587 30.7259 18.4967 30.7259 20.1715L30.7263 28.246Z"
                    fill="#05668D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1565_6027">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LinkedinIcon;
