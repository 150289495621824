import React from 'react';
import { Stack, Typography } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Stack>
            <Typography
                color="primaryDark.main"
                fontSize="45px"
                fontWeight={700}
                lineHeight="normal"
                mb="15px"
                fontFamily="montserrat"
            >
                Jobfactor Privacy Policy
            </Typography>
            <Typography
                color="primaryDark.main"
                fontSize="20px"
                fontWeight={600}
                lineHeight="normal"
                mb="25px"
                fontFamily="montserrat"
            >
                Effective Date: August 1, 2023
            </Typography>
            <Typography
                color="primaryDark.main"
                fontSize="18px"
                fontWeight={400}
                lineHeight="normal"
                mb="45px"
                fontFamily="open sans"
            >
                At Jobfactor, we are committed to safeguarding your privacy and
                providing transparency in our data practices. This Privacy
                Policy outlines how we collect, use, and protect your personal
                information.
            </Typography>
            <Stack gap="32px" mb="45px">
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        1. Data Collection:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.1. We collect personal information such as your
                            name, contact details, and professional history to
                            facilitate connections and enhance your platform
                            experience.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.2. We also collect data on your interactions with
                            other users, content, and features.1.2. You will not
                            engage in any illegal, fraudulent, or harmful
                            activities on the platform.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.3. We also use your platform activities to
                            generate personalized experiences and match you with
                            the right job opportunities.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.4. We share any information you have decided to
                            make publicly available with employers, who may
                            contact you to discuss employment opportunities
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        2. Data Usage:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            2.1. Your data is used to personalize your
                            experience, match you with relevant job
                            opportunities, and improve our services.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            2.2. We may analyze aggregated data to identify
                            trends and improve user experiences
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        3. Data Sharing:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            3.1. We do not sell your personal information to
                            third parties for marketing purposes.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            3.2. We may analyze aggregated data to identify
                            trends and improve user experiences.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        4. Security Measures:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            4.1. We employ industry-standard security measures
                            to protect your data from unauthorized access and
                            loss.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            4.2. Our platform uses encryption for data
                            transmission and storage.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        5. Consent Mechanisms:
                    </Typography>
                    <Typography
                        color="primaryDark.main"
                        fontSize="18px"
                        fontWeight={400}
                        lineHeight="normal"
                        fontFamily="open sans"
                    >
                        5.1. By using Jobfactor, you consent to the collection,
                        processing, and sharing of your data as outlined in this
                        Privacy Policy.
                    </Typography>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        6. GDPR Compliance:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            6.1. For users in the European Economic Area (EEA),
                            our practices comply with the General Data
                            Protection Regulation (GDPR).
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        7. Data Retention:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            7.1. We retain your data as long as necessary to
                            provide services and as required by law.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            7.2. You can request the deletion of your account
                            and associated data at any time.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        8. Changes to Policy:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            9.1. We may update this Privacy Policy periodically
                            to reflect changes in our practices. You will be
                            notified of significant changes..
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Typography
                color="primaryDark.main"
                fontSize="18px"
                fontWeight={400}
                lineHeight="normal"
                fontFamily="open sans"
            >
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at
                contact@jobfactor.com.
            </Typography>
        </Stack>
    );
};

export default PrivacyPolicy;
