import React from 'react';

const BleuLeft = () => {
    return (
        <svg
            width="585"
            height="402"
            viewBox="0 0 585 402"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.3">
                <path
                    d="M0.5 0.5H82.9286V79.9H0.5V0.5ZM501.071 0.5H583.5V79.9H501.071V0.5ZM501.071 80.9H583.5V160.3H501.071V80.9ZM501.071 161.3H583.5V240.7H501.071V161.3ZM501.071 241.7H583.5V321.1H501.071V241.7ZM501.071 322.1H583.5V401.5H501.071V322.1ZM500.071 322.1V401.5H417.643V322.1H500.071ZM416.643 322.1V401.5H334.214V322.1H416.643ZM333.214 322.1V401.5H250.786V322.1H333.214ZM249.786 322.1V401.5H167.357V322.1H249.786ZM166.357 322.1V401.5H83.9286V322.1H166.357ZM82.9286 322.1V401.5H0.5V322.1H82.9286ZM82.9286 321.1H0.5V241.7H82.9286V321.1ZM82.9286 240.7H0.5V161.3H82.9286V240.7ZM82.9286 160.3H0.5V80.9H82.9286V160.3ZM83.9286 160.3V80.9H166.357V160.3H83.9286ZM167.357 160.3V80.9H249.786V160.3H167.357ZM249.786 79.9H167.357V0.5H249.786V79.9ZM250.786 160.3V80.9H333.214V160.3H250.786ZM333.214 79.9H250.786V0.5H333.214V79.9ZM334.214 160.3V80.9H416.643V160.3H334.214ZM416.643 79.9H334.214V0.5H416.643V79.9ZM417.643 160.3V80.9H500.071V160.3H417.643ZM500.071 79.9H417.643V0.5H500.071V79.9ZM417.643 161.3H500.071V240.7H417.643V161.3ZM417.643 241.7H500.071V321.1H417.643V241.7ZM416.643 241.7V321.1H334.214V241.7H416.643ZM333.214 241.7V321.1H250.786V241.7H333.214ZM249.786 241.7V321.1H167.357V241.7H249.786ZM166.357 241.7V321.1H83.9286V241.7H166.357ZM166.357 240.7H83.9286V161.3H166.357V240.7ZM167.357 240.7V161.3H249.786V240.7H167.357ZM250.786 240.7V161.3H333.214V240.7H250.786ZM334.214 240.7V161.3H416.643V240.7H334.214ZM83.9286 0.5H166.357V79.9H83.9286V0.5Z"
                    stroke="url(#paint0_radial_1362_11803)"
                />
                <mask id="path-2-inside-1_1362_11803" fill="white">
                    <path d="M57.7305 267.514H84.4276V248.817C84.4276 244.399 80.8459 240.817 76.4276 240.817H57.7305V267.514Z" />
                </mask>
                <path
                    d="M57.7305 267.514H84.4276V248.817C84.4276 244.399 80.8459 240.817 76.4276 240.817H57.7305V267.514Z"
                    fill="url(#paint1_linear_1362_11803)"
                />
                <path
                    d="M57.7305 267.514H84.4276H57.7305ZM85.4276 248.817C85.4276 243.846 81.3982 239.817 76.4276 239.817H57.7305V241.817H76.4276C80.2936 241.817 83.4276 244.951 83.4276 248.817H85.4276ZM57.7305 240.817V267.514V240.817ZM85.4276 267.514V248.817C85.4276 243.846 81.3982 239.817 76.4276 239.817V241.817C80.2936 241.817 83.4276 244.951 83.4276 248.817V267.514H85.4276Z"
                    fill="url(#paint2_radial_1362_11803)"
                    mask="url(#path-2-inside-1_1362_11803)"
                />
                <mask id="path-4-inside-2_1362_11803" fill="white">
                    <path d="M391.445 37.2515H418.142V18.5543C418.142 14.136 414.561 10.5543 410.142 10.5543H391.445V37.2515Z" />
                </mask>
                <path
                    d="M391.445 37.2515H418.142V18.5543C418.142 14.136 414.561 10.5543 410.142 10.5543H391.445V37.2515Z"
                    fill="url(#paint3_linear_1362_11803)"
                />
                <path
                    d="M391.445 37.2515H418.142H391.445ZM419.142 18.5543C419.142 13.5838 415.113 9.55432 410.142 9.55432H391.445V11.5543H410.142C414.008 11.5543 417.142 14.6883 417.142 18.5543H419.142ZM391.445 10.5543V37.2515V10.5543ZM419.142 37.2515V18.5543C419.142 13.5838 415.113 9.55432 410.142 9.55432V11.5543C414.008 11.5543 417.142 14.6883 417.142 18.5543V37.2515H419.142Z"
                    fill="url(#paint4_radial_1362_11803)"
                    mask="url(#path-4-inside-2_1362_11803)"
                />
                <mask id="path-6-inside-3_1362_11803" fill="white">
                    <path d="M27.6953 106.497H0.998169V87.7999C0.998169 83.3816 4.57989 79.7999 8.99817 79.7999H27.6953V106.497Z" />
                </mask>
                <path
                    d="M27.6953 106.497H0.998169V87.7999C0.998169 83.3816 4.57989 79.7999 8.99817 79.7999H27.6953V106.497Z"
                    fill="url(#paint5_linear_1362_11803)"
                />
                <path
                    d="M27.6953 106.497H0.998169H27.6953ZM-0.00183105 87.7999C-0.00183105 82.8294 4.02761 78.7999 8.99817 78.7999H27.6953V80.7999H8.99817C5.13218 80.7999 1.99817 83.9339 1.99817 87.7999H-0.00183105ZM27.6953 79.7999V106.497V79.7999ZM-0.00183105 106.497V87.7999C-0.00183105 82.8294 4.02761 78.7999 8.99817 78.7999V80.7999C5.13218 80.7999 1.99817 83.9339 1.99817 87.7999V106.497H-0.00183105Z"
                    fill="url(#paint6_radial_1362_11803)"
                    mask="url(#path-6-inside-3_1362_11803)"
                />
                <mask id="path-8-inside-4_1362_11803" fill="white">
                    <path d="M194.137 294.628H167.44V313.326C167.44 317.744 171.021 321.326 175.44 321.326H194.137V294.628Z" />
                </mask>
                <path
                    d="M194.137 294.628H167.44V313.326C167.44 317.744 171.021 321.326 175.44 321.326H194.137V294.628Z"
                    fill="url(#paint7_linear_1362_11803)"
                />
                <path
                    d="M194.137 294.628H167.44H194.137ZM166.44 313.326C166.44 318.296 170.469 322.326 175.44 322.326H194.137V320.326H175.44C171.574 320.326 168.44 317.192 168.44 313.326H166.44ZM194.137 321.326V294.628V321.326ZM166.44 294.628V313.326C166.44 318.296 170.469 322.326 175.44 322.326V320.326C171.574 320.326 168.44 317.192 168.44 313.326V294.628H166.44Z"
                    fill="url(#paint8_radial_1362_11803)"
                    mask="url(#path-8-inside-4_1362_11803)"
                />
                <mask id="path-10-inside-5_1362_11803" fill="white">
                    <path d="M194.137 37.2515H167.44V18.5543C167.44 14.136 171.021 10.5543 175.44 10.5543H194.137V37.2515Z" />
                </mask>
                <path
                    d="M194.137 37.2515H167.44V18.5543C167.44 14.136 171.021 10.5543 175.44 10.5543H194.137V37.2515Z"
                    fill="url(#paint9_linear_1362_11803)"
                />
                <path
                    d="M194.137 37.2515H167.44H194.137ZM166.44 18.5543C166.44 13.5838 170.469 9.55432 175.44 9.55432H194.137V11.5543H175.44C171.574 11.5543 168.44 14.6883 168.44 18.5543H166.44ZM194.137 10.5543V37.2515V10.5543ZM166.44 37.2515V18.5543C166.44 13.5838 170.469 9.55432 175.44 9.55432V11.5543C171.574 11.5543 168.44 14.6883 168.44 18.5543V37.2515H166.44Z"
                    fill="url(#paint10_radial_1362_11803)"
                    mask="url(#path-10-inside-5_1362_11803)"
                />
                <mask id="path-12-inside-6_1362_11803" fill="white">
                    <path d="M474.875 348.023H501.572V329.326C501.572 324.907 497.99 321.326 493.572 321.326H474.875V348.023Z" />
                </mask>
                <path
                    d="M474.875 348.023H501.572V329.326C501.572 324.907 497.99 321.326 493.572 321.326H474.875V348.023Z"
                    fill="url(#paint11_linear_1362_11803)"
                />
                <path
                    d="M474.875 348.023H501.572H474.875ZM502.572 329.326C502.572 324.355 498.543 320.326 493.572 320.326H474.875V322.326H493.572C497.438 322.326 500.572 325.46 500.572 329.326H502.572ZM474.875 321.326V348.023V321.326ZM502.572 348.023V329.326C502.572 324.355 498.543 320.326 493.572 320.326V322.326C497.438 322.326 500.572 325.46 500.572 329.326V348.023H502.572Z"
                    fill="url(#paint12_radial_1362_11803)"
                    mask="url(#path-12-inside-6_1362_11803)"
                />
                <mask id="path-14-inside-7_1362_11803" fill="white">
                    <path d="M391.445 214.12H418.142V232.817C418.142 237.235 414.561 240.817 410.142 240.817H391.445V214.12Z" />
                </mask>
                <path
                    d="M391.445 214.12H418.142V232.817C418.142 237.235 414.561 240.817 410.142 240.817H391.445V214.12Z"
                    fill="url(#paint13_linear_1362_11803)"
                />
                <path
                    d="M391.445 214.12H418.142H391.445ZM419.142 232.817C419.142 237.788 415.113 241.817 410.142 241.817H391.445V239.817H410.142C414.008 239.817 417.142 236.683 417.142 232.817H419.142ZM391.445 240.817V214.12V240.817ZM419.142 214.12V232.817C419.142 237.788 415.113 241.817 410.142 241.817V239.817C414.008 239.817 417.142 236.683 417.142 232.817V214.12H419.142Z"
                    fill="url(#paint14_radial_1362_11803)"
                    mask="url(#path-14-inside-7_1362_11803)"
                />
                <mask id="path-16-inside-8_1362_11803" fill="white">
                    <path d="M558.305 187.006H585.002V168.308C585.002 163.89 581.42 160.308 577.002 160.308H558.305V187.006Z" />
                </mask>
                <path
                    d="M558.305 187.006H585.002V168.308C585.002 163.89 581.42 160.308 577.002 160.308H558.305V187.006Z"
                    fill="url(#paint15_linear_1362_11803)"
                />
                <path
                    d="M558.305 187.006H585.002H558.305ZM586.002 168.308C586.002 163.338 581.972 159.308 577.002 159.308H558.305V161.308H577.002C580.868 161.308 584.002 164.442 584.002 168.308H586.002ZM558.305 160.308V187.006V160.308ZM586.002 187.006V168.308C586.002 163.338 581.972 159.308 577.002 159.308V161.308C580.868 161.308 584.002 164.442 584.002 168.308V187.006H586.002Z"
                    fill="url(#paint16_radial_1362_11803)"
                    mask="url(#path-16-inside-8_1362_11803)"
                />
                <mask id="path-18-inside-9_1362_11803" fill="white">
                    <path d="M360.996 134.029H334.299V152.726C334.299 157.144 337.881 160.726 342.299 160.726H360.996V134.029Z" />
                </mask>
                <path
                    d="M360.996 134.029H334.299V152.726C334.299 157.144 337.881 160.726 342.299 160.726H360.996V134.029Z"
                    fill="url(#paint17_linear_1362_11803)"
                />
                <path
                    d="M360.996 134.029H334.299H360.996ZM333.299 152.726C333.299 157.696 337.328 161.726 342.299 161.726H360.996V159.726H342.299C338.433 159.726 335.299 156.592 335.299 152.726H333.299ZM360.996 160.726V134.029V160.726ZM333.299 134.029V152.726C333.299 157.696 337.328 161.726 342.299 161.726V159.726C338.433 159.726 335.299 156.592 335.299 152.726V134.029H333.299Z"
                    fill="url(#paint18_radial_1362_11803)"
                    mask="url(#path-18-inside-9_1362_11803)"
                />
                <mask id="path-20-inside-10_1362_11803" fill="white">
                    <path d="M527.852 53.52H501.154V72.2172C501.154 76.6354 504.736 80.2172 509.154 80.2172H527.852V53.52Z" />
                </mask>
                <path
                    d="M527.852 53.52H501.154V72.2172C501.154 76.6354 504.736 80.2172 509.154 80.2172H527.852V53.52Z"
                    fill="url(#paint19_linear_1362_11803)"
                />
                <path
                    d="M527.852 53.52H501.154H527.852ZM500.154 72.2172C500.154 77.1877 504.184 81.2172 509.154 81.2172H527.852V79.2172H509.154C505.288 79.2172 502.154 76.0832 502.154 72.2172H500.154ZM527.852 80.2172V53.52V80.2172ZM500.154 53.52V72.2172C500.154 77.1877 504.184 81.2172 509.154 81.2172V79.2172C505.288 79.2172 502.154 76.0832 502.154 72.2172V53.52H500.154Z"
                    fill="url(#paint20_radial_1362_11803)"
                    mask="url(#path-20-inside-10_1362_11803)"
                />
                <mask id="path-22-inside-11_1362_11803" fill="white">
                    <path d="M361.414 370.131H334.717V388.828C334.717 393.247 338.299 396.828 342.717 396.828H361.414V370.131Z" />
                </mask>
                <path
                    d="M361.414 370.131H334.717V388.828C334.717 393.247 338.299 396.828 342.717 396.828H361.414V370.131Z"
                    fill="url(#paint21_linear_1362_11803)"
                />
                <path
                    d="M361.414 370.131H334.717H361.414ZM333.717 388.828C333.717 393.799 337.746 397.828 342.717 397.828H361.414V395.828H342.717C338.851 395.828 335.717 392.694 335.717 388.828H333.717ZM361.414 396.828V370.131V396.828ZM333.717 370.131V388.828C333.717 393.799 337.746 397.828 342.717 397.828V395.828C338.851 395.828 335.717 392.694 335.717 388.828V370.131H333.717Z"
                    fill="url(#paint22_radial_1362_11803)"
                    mask="url(#path-22-inside-11_1362_11803)"
                />
                <mask id="path-24-inside-12_1362_11803" fill="white">
                    <path d="M224.586 214.12H251.283V232.817C251.283 237.235 247.701 240.817 243.283 240.817H224.586V214.12Z" />
                </mask>
                <path
                    d="M224.586 214.12H251.283V232.817C251.283 237.235 247.701 240.817 243.283 240.817H224.586V214.12Z"
                    fill="url(#paint23_linear_1362_11803)"
                />
                <path
                    d="M224.586 214.12H251.283H224.586ZM252.283 232.817C252.283 237.788 248.254 241.817 243.283 241.817H224.586V239.817H243.283C247.149 239.817 250.283 236.683 250.283 232.817H252.283ZM224.586 240.817V214.12V240.817ZM252.283 214.12V232.817C252.283 237.788 248.254 241.817 243.283 241.817V239.817C247.149 239.817 250.283 236.683 250.283 232.817V214.12H252.283Z"
                    fill="url(#paint24_radial_1362_11803)"
                    mask="url(#path-24-inside-12_1362_11803)"
                />
                <mask id="path-26-inside-13_1362_11803" fill="white">
                    <path d="M194.555 106.497H167.858V87.7999C167.858 83.3816 171.439 79.7999 175.858 79.7999H194.555V106.497Z" />
                </mask>
                <path
                    d="M194.555 106.497H167.858V87.7999C167.858 83.3816 171.439 79.7999 175.858 79.7999H194.555V106.497Z"
                    fill="url(#paint25_linear_1362_11803)"
                />
                <path
                    d="M194.555 106.497H167.858H194.555ZM166.858 87.7999C166.858 82.8294 170.887 78.7999 175.858 78.7999H194.555V80.7999H175.858C171.992 80.7999 168.858 83.9339 168.858 87.7999H166.858ZM194.555 79.7999V106.497V79.7999ZM166.858 106.497V87.7999C166.858 82.8294 170.887 78.7999 175.858 78.7999V80.7999C171.992 80.7999 168.858 83.9339 168.858 87.7999V106.497H166.858Z"
                    fill="url(#paint26_radial_1362_11803)"
                    mask="url(#path-26-inside-13_1362_11803)"
                />
                <mask id="path-28-inside-14_1362_11803" fill="white">
                    <path d="M558.305 370.131H585.002V388.828C585.002 393.247 581.42 396.828 577.002 396.828H558.305V370.131Z" />
                </mask>
                <path
                    d="M558.305 370.131H585.002V388.828C585.002 393.247 581.42 396.828 577.002 396.828H558.305V370.131Z"
                    fill="url(#paint27_linear_1362_11803)"
                />
                <path
                    d="M558.305 370.131H585.002H558.305ZM586.002 388.828C586.002 393.799 581.972 397.828 577.002 397.828H558.305V395.828H577.002C580.868 395.828 584.002 392.694 584.002 388.828H586.002ZM558.305 396.828V370.131V396.828ZM586.002 370.131V388.828C586.002 393.799 581.972 397.828 577.002 397.828V395.828C580.868 395.828 584.002 392.694 584.002 388.828V370.131H586.002Z"
                    fill="url(#paint28_radial_1362_11803)"
                    mask="url(#path-28-inside-14_1362_11803)"
                />
                <mask id="path-30-inside-15_1362_11803" fill="white">
                    <path d="M27.6953 370.131H0.998169V388.828C0.998169 393.247 4.57989 396.828 8.99817 396.828H27.6953V370.131Z" />
                </mask>
                <path
                    d="M27.6953 370.131H0.998169V388.828C0.998169 393.247 4.57989 396.828 8.99817 396.828H27.6953V370.131Z"
                    fill="url(#paint29_linear_1362_11803)"
                />
                <path
                    d="M27.6953 370.131H0.998169H27.6953ZM-0.00183105 388.828C-0.00183105 393.799 4.02761 397.828 8.99817 397.828H27.6953V395.828H8.99817C5.13218 395.828 1.99817 392.694 1.99817 388.828H-0.00183105ZM27.6953 396.828V370.131V396.828ZM-0.00183105 370.131V388.828C-0.00183105 393.799 4.02761 397.828 8.99817 397.828V395.828C5.13218 395.828 1.99817 392.694 1.99817 388.828V370.131H-0.00183105Z"
                    fill="url(#paint30_radial_1362_11803)"
                    mask="url(#path-30-inside-15_1362_11803)"
                />
                <path
                    d="M163 48C163 51.3137 160.314 54 157 54C153.686 54 151 51.3137 151 48C151 44.6863 153.686 42 157 42C160.314 42 163 44.6863 163 48Z"
                    fill="url(#paint31_linear_1362_11803)"
                />
                <path
                    d="M79 201C79 204.314 76.3137 207 73 207C69.6863 207 67 204.314 67 201C67 197.686 69.6863 195 73 195C76.3137 195 79 197.686 79 201Z"
                    fill="url(#paint32_linear_1362_11803)"
                />
                <path
                    d="M496 112C496 115.314 493.314 118 490 118C486.686 118 484 115.314 484 112C484 108.686 486.686 106 490 106C493.314 106 496 108.686 496 112Z"
                    fill="url(#paint33_linear_1362_11803)"
                />
                <path
                    d="M288 321C288 324.314 285.314 327 282 327C278.686 327 276 324.314 276 321C276 317.686 278.686 315 282 315C285.314 315 288 317.686 288 321Z"
                    fill="url(#paint34_linear_1362_11803)"
                />
                <path
                    d="M246 160C246 163.314 243.314 166 240 166C236.686 166 234 163.314 234 160C234 156.686 236.686 154 240 154C243.314 154 246 156.686 246 160Z"
                    fill="url(#paint35_linear_1362_11803)"
                />
                <path
                    d="M413 241C413 244.314 410.314 247 407 247C403.686 247 401 244.314 401 241C401 237.686 403.686 235 407 235C410.314 235 413 237.686 413 241Z"
                    fill="url(#paint36_linear_1362_11803)"
                />
            </g>
            <defs>
                <radialGradient
                    id="paint0_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(395 129.5) rotate(164.15) scale(455.832 660.439)"
                >
                    <stop stopColor="#E6E6E6" stopOpacity="0.44" />
                    <stop offset="1" stopColor="#EDEDED" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_1362_11803"
                    x1="99.5066"
                    y1="215.356"
                    x2="75.4539"
                    y2="254.272"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint2_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(89.6187 233.648) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint3_linear_1362_11803"
                    x1="433.221"
                    y1="-14.9068"
                    x2="409.169"
                    y2="24.0095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint4_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(423.334 3.38565) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint5_linear_1362_11803"
                    x1="-14.0808"
                    y1="54.3388"
                    x2="9.97187"
                    y2="93.2551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint6_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-4.19294 72.6313) rotate(-126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint7_linear_1362_11803"
                    x1="152.361"
                    y1="346.787"
                    x2="176.413"
                    y2="307.87"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint8_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(162.248 328.494) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint9_linear_1362_11803"
                    x1="152.361"
                    y1="-14.9068"
                    x2="176.413"
                    y2="24.0095"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint10_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(162.248 3.38565) rotate(-126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint11_linear_1362_11803"
                    x1="516.651"
                    y1="295.864"
                    x2="492.598"
                    y2="334.781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint12_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(506.763 314.157) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint13_linear_1362_11803"
                    x1="433.221"
                    y1="266.278"
                    x2="409.169"
                    y2="227.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint14_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(423.334 247.986) rotate(53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint15_linear_1362_11803"
                    x1="600.081"
                    y1="134.847"
                    x2="576.028"
                    y2="173.764"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint16_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(590.193 153.14) rotate(-53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint17_linear_1362_11803"
                    x1="319.22"
                    y1="186.187"
                    x2="343.273"
                    y2="147.27"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint18_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(329.108 167.894) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint19_linear_1362_11803"
                    x1="486.075"
                    y1="105.678"
                    x2="510.128"
                    y2="66.7619"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint20_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(495.963 87.3858) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint21_linear_1362_11803"
                    x1="319.638"
                    y1="422.29"
                    x2="343.691"
                    y2="383.373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint22_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(329.526 403.997) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint23_linear_1362_11803"
                    x1="266.362"
                    y1="266.278"
                    x2="242.309"
                    y2="227.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint24_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(256.474 247.986) rotate(53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint25_linear_1362_11803"
                    x1="152.779"
                    y1="54.3388"
                    x2="176.831"
                    y2="93.2551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint26_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(162.666 72.6313) rotate(-126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint27_linear_1362_11803"
                    x1="600.081"
                    y1="422.29"
                    x2="576.028"
                    y2="383.373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint28_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(590.193 403.997) rotate(53.3743) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint29_linear_1362_11803"
                    x1="-14.0808"
                    y1="422.29"
                    x2="9.97187"
                    y2="383.373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#23155C" />
                    <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint30_radial_1362_11803"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(-4.19294 403.997) rotate(126.626) scale(34.8055)"
                >
                    <stop stopColor="#75797E" />
                    <stop offset="1" stopColor="#BFBEBE" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint31_linear_1362_11803"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_1362_11803"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_1362_11803"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_1362_11803"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_1362_11803"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_1362_11803"
                    x1="26"
                    y1="-26.5"
                    x2="554.5"
                    y2="533"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAA6E6" />
                    <stop offset="1" stopColor="#F8CB2C" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BleuLeft;
