import React from 'react';

const LinkedinIconModal = () => {
    return (
        <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3718_5251)">
                <path
                    d="M26.095 0.880859C12.223 0.880859 0.976562 12.1274 0.976562 25.9995C0.976562 39.872 12.2226 51.119 26.095 51.119C39.9678 51.119 51.2147 39.872 51.2147 25.9995C51.2142 12.1274 39.9678 0.880859 26.095 0.880859ZM15.672 12.2099C17.3806 12.2099 18.7666 13.4738 18.7666 15.0331C18.7666 16.5923 17.3806 17.8562 15.672 17.8562C13.9625 17.8562 12.5774 16.5923 12.5774 15.0331C12.5774 13.4738 13.9625 12.2099 15.672 12.2099ZM18.79 36.3565H12.6756V20.4014H18.79V36.3565ZM39.5673 36.3565H33.4233V27.583C33.4233 26.5815 33.2375 25.8726 32.867 25.4566C32.4961 25.0413 31.9753 24.8329 31.3051 24.8329C30.5637 24.8329 29.9622 25.1134 29.5018 25.6746C29.041 26.2357 28.8112 27.2421 28.8112 28.6945V36.3565H22.6964V20.4014H28.3899V23C29.2408 21.9389 30.1025 21.1801 30.9737 20.7241C31.845 20.2685 32.9061 20.0409 34.1582 20.0409C35.8512 20.0409 37.1755 20.5439 38.1323 21.5506C39.0883 22.557 39.5669 24.1119 39.5669 26.2153L39.5673 36.3565Z"
                    fill="#05668D"
                />
            </g>
            <defs>
                <clipPath id="clip0_3718_5251">
                    <rect
                        width="50.2381"
                        height="50.2381"
                        fill="white"
                        transform="translate(0.976562 0.880859)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LinkedinIconModal;
