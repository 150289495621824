import {
    Container,
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
    Box
} from '@mui/material';
import React from 'react';
import Logo from '../../Assets/Icons/Logo';
import VectorYellow from '../../Assets/Icons/VectorYellow';
import GroupYellow from '../../Assets/Icons/GroupYellow';
import DreamBlue from '../../Assets/Icons/DreamBlue';
import DreamPurple from '../../Assets/Icons/DreamPurple';
import DreamRed from '../../Assets/Icons/DreamRed';
import DreamGreen from '../../Assets/Icons/DreamGreen';
import DreamEclipseBlue from '../../Assets/Icons/DreamEclipseBlue';
import DreamEclipseRed from '../../Assets/Icons/DreamEclipseRed';
import DreamEclipsePurple from '../../Assets/Icons/DreamEclipsPurple';
import DreamEclipseGreen from '../../Assets/Icons/DreamEclipseGreen';
import DreamJobCard from '../DreamJobCard/DreamJobCard';
import BusinessLady from '../../Assets/Images/BusinessLady.png';
import BusinessLadySmall from '../../Assets/Images/BussinessLadySmall.png';
import DreamJobScreen2ArrowLeft from '../../Assets/Icons/DreamJobScreen2ArrowLeft';
import DreamJobScreen2ArrowRight from '../../Assets/Icons/DreamJobScreen2ArrowRight';
type DreamJobScreenProps = {
    screen: number;
    setScreen: (screen: number) => void;
};
const DreamJob = ({ screen, setScreen }: DreamJobScreenProps) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));
    const medium = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack
            data-aos="fade"
            data-aos-duration={2000}
            bgcolor="#FCFBF8"
            pt={medium ? '60px' : '92px'}
            pb={medium ? '123px' : '79px'}
            mb={mobile ? undefined : '180px'}
            position="relative"
        >
            {mobile ? (
                <Stack position="absolute" right={0} top={0}>
                    <VectorYellow />
                </Stack>
            ) : null}
            <Container
                maxWidth="xl"
                disableGutters
                sx={{ px: medium ? '16px' : '60px', position: 'relative' }}
            >
                <Stack>
                    <Stack flexDirection="row" justifyContent="space-between">
                        <Typography
                            color="primaryDark.main"
                            component="h2"
                            fontFamily="Poppins"
                            fontSize={medium ? '28px' : '48px'}
                            fontWeight={600}
                            lineHeight="120%"
                            letterSpacing={medium ? '-0.9px' : '-2.4px'}
                            maxWidth={medium ? '280px' : '617px'}
                            mb={mobile ? '20px' : undefined}
                        >
                            We help you get your dream job
                        </Typography>

                        {medium ? (
                            <Stack width="200px" height="20px"></Stack>
                        ) : null}
                    </Stack>
                    {mobile ? (
                        <Stack
                            alignSelf="flex-start"
                            py="8px"
                            px="24px"
                            bgcolor="#857CE9"
                            borderRadius="64px"
                            mb={mobile ? '36px' : undefined}
                        >
                            <Typography
                                color="#FFF"
                                component="h2"
                                fontFamily="Open Sans"
                                fontSize="14px"
                                fontWeight={700}
                                lineHeight="120%"
                            >
                                GET HIRED
                            </Typography>
                        </Stack>
                    ) : null}
                    {mobile ? null : (
                        <Stack
                            flexDirection="row"
                            gap="16px"
                            mt="120px"
                            mb="148px"
                        >
                            <Box
                                sx={{
                                    transition: 'all .4s ease',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        opacity: 0.8
                                    }
                                }}
                            >
                                <DreamJobScreen2ArrowLeft
                                    bgColor="#FFC24C"
                                    screen={screen}
                                    setScreen={setScreen}
                                />
                            </Box>
                            <Box
                                sx={{
                                    transition: 'all .4s ease',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        opacity: 0.8
                                    }
                                }}
                            >
                                <DreamJobScreen2ArrowRight
                                    bgColor="#05668D"
                                    screen={screen}
                                    setScreen={setScreen}
                                />
                            </Box>
                        </Stack>
                    )}
                </Stack>
                {mobile ? null : (
                    <Stack position="absolute" right="60px" top="31px">
                        <Logo />
                    </Stack>
                )}
                {medium ? (
                    <Stack position="absolute" right={0} top="13px">
                        <img src={BusinessLadySmall} alt="business Lady" />
                    </Stack>
                ) : null}
                <Stack
                    top="179px"
                    right="60px"
                    flexDirection="row"
                    flexWrap={mobile ? 'wrap' : 'nowrap'}
                    gap="20px"
                    position={mobile ? undefined : 'absolute'}
                    zIndex={2}
                >
                    {mobile ? null : (
                        <Stack position="absolute" top="-45px" left="-45px">
                            <GroupYellow />
                        </Stack>
                    )}
                    <Stack gap="20px">
                        <DreamJobCard
                            title="It's not just a job, 
                            it's your life."
                            subTitle="There is more to your career than a paycheck! Get
                            the job that aligns with your values and life visions.
                            With curated feedback from current employees of
                            top companies, we help you choose the company
                            whose values align with yours."
                            StartIcon={DreamBlue}
                            EndIcon={DreamEclipseBlue}
                        />
                        <DreamJobCard
                            title="Give back and grow!"
                            subTitle="Our anonymous peer to peer reviews empower you
                            to give back to your coworkers, helping them grow
                            their skills and careers while you earn rewards 
                            towards your own growth."
                            StartIcon={DreamRed}
                            EndIcon={DreamEclipseRed}
                        />
                    </Stack>
                    <Stack gap="88px">
                        {mobile ? null : (
                            <Stack
                                alignSelf="flex-end"
                                py="8px"
                                px="24px"
                                bgcolor="#FFC24C"
                                borderRadius="64px"
                                width="152px"
                            >
                                <Typography
                                    color="primaryLight.main"
                                    component="h2"
                                    fontFamily="Open Sans"
                                    fontSize="20px"
                                    fontWeight={700}
                                    lineHeight="28px"
                                >
                                    GET HIRED
                                </Typography>
                            </Stack>
                        )}
                        <Stack gap="20px">
                            <DreamJobCard
                                title="Showcase your professional experience"
                                subTitle="With Jobfactor, you can build your professional 
                                eminence with a unique score that 
                                summarizes your skills, education, and character."
                                StartIcon={DreamPurple}
                                EndIcon={DreamEclipsePurple}
                            />
                            <DreamJobCard
                                title="Save time. Find your dream job."
                                subTitle="Let Jobfactor's AI-powered platform eliminate 
                                guess work. Get instant skill and qualification 
                                assessment. No more wasted applications or ghosting"
                                StartIcon={DreamGreen}
                                EndIcon={DreamEclipseGreen}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
            {mobile ? null : (
                <Stack bgcolor="#FFE3AD" height="300px" position="relative">
                    <Stack
                        width="100%"
                        maxWidth="1536px"
                        position="relative"
                        mx="auto"
                        height="100%"
                    >
                        <Stack position="absolute" bottom={0}>
                            <img src={BusinessLady} alt="business Lady" />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
export default DreamJob;
