import React from 'react';

const YellowLogo = () => {
    return (
        <svg
            width="424"
            height="862"
            viewBox="0 0 424 862"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="path-1-inside-1_1362_11763" fill="white">
                <path d="M424 0C351.234 0 292.252 53.0612 292.252 118.522V281.247H17.6323C-18.6382 281.247 -48 307.793 -48 340.5V871.43C-48 936.923 10.7237 990 83.2647 990V767.086H357.729C393.999 767.086 423.516 740.523 423.516 707.769V0.222278M292.494 653.056H83.5064V403.786H292.494V653.056Z" />
            </mask>
            <path
                d="M292.252 281.247V285.247H296.252V281.247H292.252ZM83.2647 990V994H87.2647V990H83.2647ZM83.2647 767.086V763.086H79.2647V767.086H83.2647ZM292.494 653.056V657.056H296.494V653.056H292.494ZM83.5064 653.056H79.5064V657.056H83.5064V653.056ZM83.5064 403.786V399.786H79.5064V403.786H83.5064ZM292.494 403.786H296.494V399.786H292.494V403.786ZM424 -4C349.434 -4 288.252 50.4646 288.252 118.522H296.252C296.252 55.6579 353.035 4 424 4V-4ZM288.252 118.522V281.247H296.252V118.522H288.252ZM292.252 277.247H17.6323V285.247H292.252V277.247ZM17.6323 277.247C-20.4545 277.247 -52 305.211 -52 340.5H-44C-44 310.376 -16.8218 285.247 17.6323 285.247V277.247ZM-52 340.5V871.43H-44V340.5H-52ZM-52 871.43C-52 939.504 8.90625 994 83.2647 994V986C12.5411 986 -44 934.341 -44 871.43H-52ZM87.2647 990V767.086H79.2647V990H87.2647ZM83.2647 771.086H357.729V763.086H83.2647V771.086ZM357.729 771.086C395.806 771.086 427.516 743.114 427.516 707.769H419.516C419.516 737.933 392.192 763.086 357.729 763.086V771.086ZM427.516 707.769V0.222278H419.516V707.769H427.516ZM292.494 649.056H83.5064V657.056H292.494V649.056ZM87.5064 653.056V403.786H79.5064V653.056H87.5064ZM83.5064 407.786H292.494V399.786H83.5064V407.786ZM288.494 403.786V653.056H296.494V403.786H288.494Z"
                fill="#FFC24C"
                mask="url(#path-1-inside-1_1362_11763)"
            />
        </svg>
    );
};

export default YellowLogo;
