import React, { useState } from 'react';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import DreamJobScreen2 from '../DreamJobScreen2/DreamJobScreen2';
import DreamJob from '../DreamJob/DreamJob';

const DreamJobSection = () => {
    const [screen, setScreen] = useState(1);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Stack zIndex={1}>
            {mobile ? null : screen === 1 ? (
                <DreamJob screen={screen} setScreen={setScreen} />
            ) : (
                <DreamJobScreen2 screen={screen} setScreen={setScreen} />
            )}
            <Stack>
                {mobile ? (
                    <>
                        <DreamJob screen={screen} setScreen={setScreen} />
                        <DreamJobScreen2
                            screen={screen}
                            setScreen={setScreen}
                        />
                    </>
                ) : null}
            </Stack>
        </Stack>
    );
};

export default DreamJobSection;
