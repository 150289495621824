import React from 'react';

const EclipseLarge = () => {
    return (
        <svg
            width="719"
            height="700"
            viewBox="0 0 719 700"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.64876e-05 350C2.64876e-05 543.3 156.733 700 350.073 700C525.218 700 689.389 548.905 715.148 381C717.824 363.558 718.648 368.191 718.648 350C718.648 323.723 720.639 325.623 715.148 301C680.247 144.493 517.13 0 350.073 0C156.733 0 2.64876e-05 156.7 2.64876e-05 350Z"
                fill="white"
            />
        </svg>
    );
};

export default EclipseLarge;
