import React from 'react';

const ThirdVector = () => {
    return (
        <svg
            width="155"
            height="100"
            viewBox="0 0 155 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_f_357_13661)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.4515 30.8213C94.4937 31.4469 104.833 33.1238 112.276 36.9494C120.394 41.1215 125.661 46.3344 124.511 51.9232C123.137 58.602 118.748 66.0401 105.691 68.9564C92.6267 71.8744 78.2639 68.042 65.1376 65.1843C51.7827 62.2769 36.6032 59.5231 32.3323 52.9857C27.8068 46.0586 32.7588 38.039 44.1708 33.091C54.3013 28.6985 69.6717 30.0406 83.4515 30.8213Z"
                    fill="#EF6CE2"
                    fillOpacity="0.3"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_357_13661"
                    x="0.667969"
                    y="0"
                    width="154"
                    height="100"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="15"
                        result="effect1_foregroundBlur_357_13661"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default ThirdVector;
