import React from 'react';

const YellowRectangle = () => {
    return (
        <svg
            width="376"
            height="456"
            viewBox="0 0 376 456"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="376" height="456" rx="20" fill="#FFECC8" />
        </svg>
    );
};

export default YellowRectangle;
