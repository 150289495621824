import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

type DreamJobCardProps = {
    title: string;
    subTitle: string;
    StartIcon: () => JSX.Element;
    EndIcon: () => JSX.Element;
};
const DreamJobCard = ({
    title,
    subTitle,
    StartIcon,
    EndIcon
}: DreamJobCardProps) => {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const matches = useMediaQuery('(max-width: 991px)');
    return (
        <Stack
            zIndex={2}
            width="100%"
            maxWidth={isMedium || matches ? undefined : '426px'}
            borderRadius={isMedium ? '4px' : '20px'}
            px={isMedium ? '12px' : '28px'}
            py={isMedium ? '12px' : '36px'}
            gap={isMedium ? '16px' : '36px'}
            border={'1px'}
            borderColor={'#F2F2F2'}
            bgcolor="primaryLight.main"
            sx={{
                transition: 'all .4s ease',
                cursor: 'pointer',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#F2F2F2',
                '&:hover': {
                    px: isMedium ? undefined : '32px',
                    py: isMedium ? undefined : '40px',
                    maxHeight: isMedium ? undefined : '460px'
                }
            }}
        >
            <StartIcon />
            <Stack gap={isMedium ? '8px' : '20px'}>
                <Typography
                    color="primaryDark.main"
                    component="h3"
                    fontFamily="Poppins"
                    fontSize={isMedium ? '16px' : '20px'}
                    fontWeight={600}
                    lineHeight="150%"
                    letterSpacing="-0.4px"
                    width="100%"
                    maxWidth="150px"
                    sx={{
                        cursor: 'pointer',
                        transition: 'all .4s ease',
                        '&:hover': {
                            fontSize: '21px'
                        }
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    color="navTextColor.main"
                    component="p"
                    fontSize={isMedium ? '14px' : '16px'}
                    fontWeight={300}
                    lineHeight="150%"
                    sx={{
                        cursor: 'pointer',
                        transition: 'all .4s ease',
                        '&:hover': {
                            fontSize: '17px'
                        }
                    }}
                >
                    {subTitle}
                </Typography>
                <Box alignSelf="flex-end">
                    <EndIcon />
                </Box>
            </Stack>
        </Stack>
    );
};

export default DreamJobCard;
