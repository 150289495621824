import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
    signupModalState: boolean;
    videoModalState: boolean;
    feedbackModalState: boolean;
    completeModalState: boolean;
    errorModalState: boolean;
    page: number;
    // policy: term
};

const initialState: InitialState = {
    signupModalState: false,
    videoModalState: false,
    feedbackModalState: false,
    completeModalState: false,
    errorModalState: false,
    page: 1
};

const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPageValue: (state, action): any => {
            state.page = action.payload;
        },
        changePageUp: (state, action): any => {
            state.page += action.payload;
        },
        changePageDown: (state, action): any => {
            state.page -= action.payload;
        },
        openSignupModalState: (state) => {
            state.signupModalState = true;
        },
        openVideoModalState: (state) => {
            state.signupModalState = true;
            state.videoModalState = true;
        },
        openFeedbackModalState: (state) => {
            state.signupModalState = true;
            state.feedbackModalState = true;
        },
        closeSignupModalState: (state) => {
            state.signupModalState = false;
            state.videoModalState = false;
            state.feedbackModalState = false;
            state.completeModalState = false;
            state.errorModalState = false;
        },
        successPopup: (state) => {
            state.completeModalState = true;
        },
        errorPopup: (state) => {
            state.videoModalState = false;
            state.feedbackModalState = false;
            state.errorModalState = true;
        }
    }
});

export const {
    openSignupModalState,
    closeSignupModalState,
    openVideoModalState,
    openFeedbackModalState,
    changePageDown,
    changePageUp,
    setPageValue,
    successPopup,
    errorPopup
} = AppSlice.actions;
export default AppSlice.reducer;
