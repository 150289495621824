import React from 'react';

const FourthVector = () => {
    return (
        <svg
            width="224"
            height="104"
            viewBox="0 0 224 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_f_357_13669)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M122.09 30.9034C141.356 31.5916 159.393 33.4362 172.381 37.6444C186.544 42.2337 195.732 47.9678 193.726 54.1155C191.328 61.4622 183.671 69.6441 160.891 72.8521C138.098 76.0618 113.04 71.8462 90.1385 68.7028C66.8385 65.5046 40.3551 62.4754 32.9037 55.2843C25.0081 47.6644 33.6478 38.8429 53.5581 33.4001C71.2326 28.5684 98.0491 30.0447 122.09 30.9034Z"
                    fill="#EF6F6C"
                    fillOpacity="0.3"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_357_13669"
                    x="0"
                    y="0"
                    width="224"
                    height="104"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="15"
                        result="effect1_foregroundBlur_357_13669"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default FourthVector;
