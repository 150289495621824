import React from 'react';

const DreamJobIcon2 = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#EF6F6C" />
            <path
                d="M32.0013 36.6667V37.84C32.0013 40.3333 31.0013 41.3333 28.4946 41.3333H22.1613C20.228 41.3333 18.668 39.7733 18.668 37.84V31.5067C18.668 29 19.668 28 22.1613 28H23.3346V32.6667C23.3346 34.88 25.1213 36.6667 27.3346 36.6667H32.0013Z"
                fill="white"
            />
            <path
                d="M38.6654 30.0007V31.1607C38.6654 33.094 37.092 34.6673 35.1587 34.6673H28.8254C26.332 34.6673 25.332 33.6673 25.332 31.1607V24.8273C25.332 22.894 26.892 21.334 28.8254 21.334H29.9987V26.0007C29.9987 28.214 31.7854 30.0007 33.9987 30.0007H38.6654Z"
                fill="white"
            />
            <path
                d="M45.3333 18.1593V24.4927C45.3333 26.9993 44.3333 27.9993 41.8267 27.9993H35.4933C33 27.9993 32 26.9993 32 24.4927V18.1593C32 15.666 33 14.666 35.4933 14.666H41.8267C44.3333 14.666 45.3333 15.666 45.3333 18.1593Z"
                fill="white"
            />
        </svg>
    );
};

export default DreamJobIcon2;
