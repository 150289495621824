import React from 'react';
import {
    Stack,
    Typography,
    Avatar,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import YellowArrowRight from '../../Assets/Icons/YellowArrowRight';
import DotIcon from '../../Assets/Icons/DotIcon';
import '@fontsource/outfit';
import blogDetailImage from '../../Assets/Images/blogdetail1image.png';

const BlogDetails1 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    return (
        <Stack flex={1}>
            <Stack
                zIndex={2}
                height={isMobile ? '238px' : '358px'}
                borderRadius={isMobile ? '20px' : '40px'}
                justifyContent="flex-end"
                px={isMobile ? '8px' : '48px'}
                pb={isMobile ? '16px' : '24px'}
                pt="48px"
                mb={isMobile ? '20px' : '60px'}
                sx={{
                    backgroundImage: `url(${blogDetailImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <Stack
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    {isMobile ? null : (
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Jobs
                            </Typography>
                            <YellowArrowRight />
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Hiring
                            </Typography>
                        </Stack>
                    )}

                    <Stack gap="8px"
                        sx={{
                            background: 'rgba(67, 67, 67, 0.30)',
                            zIndex: '-1',
                            borderRadius: '12px'
                        }}
                    >
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="#D8D8D8"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                Feb. 11, 2023
                            </Typography>
                            <DotIcon />
                            <Typography
                                component="span"
                                color="#D8D8D8"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                10 min read
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="12px"
                            py="4px"
                            pl="4px"
                            pr="16px"
                            // width="200px"
                            borderRadius={1}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                cursor: 'pointer'
                            }}
                        >
                            <Avatar
                                variant="square"
                                src={location.state.Profile}
                            />
                            <Typography
                                component="span"
                                color="primaryLight.main"
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontWeight: 500,
                                    lineHeight: '120%',
                                    letterSpacing: '-0.32px'
                                }}
                            >
                                by Samson Olanipekun
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                px={isMobile ? '4px' : undefined}
                pb={isMobile ? '50px' : '38px'}
                zIndex={2}
                maxWidth="914px"
                mx="auto"
                gap="20px"
            >
                <Typography
                    component="span"
                    variant="h2"
                    color="primaryDark"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: isMobile ? '20px' : '40px',
                        fontWeight: 600,
                        lineHeight: '120%',
                        letterSpacing: isMobile ? '-0.6px' : '-0.8px'
                    }}
                >
                    Boost Your Hiring Potential: 7 Resume Tips for 2023
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    Are you looking to boost your hiring potential and land your
                    dream job in 2023? Your resume is a key tool in your job
                    search, and making sure it stands out is crucial to success.
                    Here are 7 tips to help you create a resume that will catch
                    the attention of hiring managers in the coming year:
                </Typography>
                <Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            1.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Keep it concise.
                            </Typography>
                             A resume should be no longer than one or two pages,
                            so make sure to include only the most relevant and
                            recent experiences. Don't worry about including
                            every job or activity you've ever had - focus on
                            those that showcase your skills and achievements.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            2.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Use action verbs.
                            </Typography>
                             Instead of using passive language, choose strong
                            action verbs to describe your responsibilities and
                            accomplishments. Words like "managed," "developed,"
                            and "achieved" convey a sense of leadership and
                            initiative.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            3.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Highlight your achievements.
                            </Typography>
                             Don't just list your job duties - make sure to
                            include specific examples of your achievements and
                            how you made a difference in your previous roles.
                            This will help you stand out from other candidates
                            and show the value you can bring to a company.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            4.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Include relevant keywords.
                            </Typography>
                             Many companies use applicant tracking systems (ATS)
                            to scan resumes and identify those that are a good
                            fit for the job. To make sure your resume gets
                            noticed, include keywords from the job posting that
                            align with your skills and experiences.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            5.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Use a clear and easy-to-read format.
                            </Typography>
                             Make sure your resume is easy to scan by using
                            bullet points, short paragraphs, and clear headings.
                            Use a consistent font and size, and avoid using
                            overly complex or fancy design elements.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            6.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Proofread carefully.
                            </Typography>
                            Typos and errors can be a major turn-off to hiring
                            managers, so make sure to proofread your resume
                            carefully. Consider having a friend or professional
                            proofreader take a look as well to catch any
                            mistakes you may have missed.
                        </Typography>
                    </Stack>
                    <Stack flexDirection="row" gap="5px">
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            7.{' '}
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            {' '}
                            <Typography
                                component="span"
                                fontWeight={600}
                                sx={{
                                    display: 'inline',
                                    fontSize: isMobile ? '14px' : '20px',
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                Keep it up to date. 
                            </Typography>
                             Make sure to regularly update your resume with your
                            most recent experiences and achievements. This will
                            help you stay top-of-mind with hiring managers and
                            be ready to apply for new opportunities as they
                            arise.
                        </Typography>
                    </Stack>
                </Stack>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    By following these tips, you can create a resume that
                    showcases your skills and experiences in the best light and
                    helps you stand out in the job market. For more ideas and
                    inspiration, check out this list of creative resume
                    templates or these expert tips for creating a standout
                    resume.
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    As you work on your resume, it's also important to stay
                    up-to-date on the latest trends and best practices in the
                    job market. According to a recent survey, some of the top
                    trends to watch out for in 2023 include the growing
                    importance of soft skills, the increasing use of artificial
                    intelligence in the hiring process, and the continued shift
                    towards remote work. By staying informed and adapting your
                    job search strategy accordingly, you can increase your
                    chances of finding the perfect job in the coming year.
                </Typography>
            </Stack>
        </Stack>
    );
};

export default BlogDetails1;
