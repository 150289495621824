import React from 'react';

const VectorBlue = () => {
    return (
        <svg
            width="118"
            height="901"
            viewBox="0 0 118 901"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M105.956 900.878C94.9519 901.835 85.2555 893.69 84.2985 882.686L0.00264961 -86.6556L279.946 -111L364.242 858.342C365.199 869.346 357.054 879.042 346.05 879.999L105.956 900.878Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default VectorBlue;
