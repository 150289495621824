import React, { useState } from 'react';
import { Stack, LinearProgress, useTheme, useMediaQuery } from '@mui/material';
import CarouselLeftArrow from '../../Assets/Icons/CarouselLeftArrow';
import CarouselRightArrow from '../../Assets/Icons/CarouselRightArrow';
import Animation1 from '../../Assets/Images/animation.png';
import Animation2 from '../../Assets/Images/animation (1).png';
import Animation3 from '../../Assets/Images/animation (2).png';
import Animation4 from '../../Assets/Images/animation (3).png';
import Animation5 from '../../Assets/Images/animation (4).png';
import Animation6 from '../../Assets/Images/animation (5).png';
import Animation7 from '../../Assets/Images/animation (6).png';
import Animation8 from '../../Assets/Images/animation (7).png';
import Animation9 from '../../Assets/Images/animation (8).png';
import Animation10 from '../../Assets/Images/animation (9).png';
import MobileAnimation1 from '../../Assets/Images/mobile carousel.png';
import MobileAnimation2 from '../../Assets/Images/mobile carousel (1).png';
import MobileAnimation3 from '../../Assets/Images/mobile carousel (2).png';
import MobileAnimation4 from '../../Assets/Images/mobile carousel (3).png';
import MobileAnimation5 from '../../Assets/Images/mobile carousel (4).png';
import MobileAnimation6 from '../../Assets/Images/mobile carousel (5).png';
import MobileAnimation7 from '../../Assets/Images/mobile carousel (6).png';
import MobileAnimation8 from '../../Assets/Images/mobile carousel (7).png';
import MobileAnimation9 from '../../Assets/Images/mobile carousel (8).png';
import MobileAnimation10 from '../../Assets/Images/mobile carousel (9).png';
import Slider from 'react-slick';
import DisabledCarouselRightArrow from '../../Assets/Icons/DisabledCarouselRightArrow';
import DisabledCarouselLeftArrow from '../../Assets/Icons/DisabledCarouselLeftArrow';

const CarouselCard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const matches = useMediaQuery('(max-width:1439px)');
    const carousels = [
        Animation1,
        Animation2,
        Animation3,
        Animation4,
        Animation5,
        Animation6,
        Animation7,
        Animation8,
        Animation9,
        Animation10
    ];
    const MobileCarousels = [
        MobileAnimation1,
        MobileAnimation2,
        MobileAnimation3,
        MobileAnimation4,
        MobileAnimation5,
        MobileAnimation6,
        MobileAnimation7,
        MobileAnimation8,
        MobileAnimation9,
        MobileAnimation10
    ];
    const [progress, setProgress] = useState(10);
    const [position, setPosition] = useState('start');
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 500,
        cssEase: 'linear',
        nextArrow: isMobile ? (
            <></>
        ) : position === 'end' ? (
            <DisabledCarouselRightArrow />
        ) : (
            <CarouselRightArrow />
        ),
        prevArrow: isMobile ? (
            <></>
        ) : position === 'start' ? (
            <DisabledCarouselLeftArrow />
        ) : (
            <CarouselLeftArrow />
        ),
        beforeChange: (currentSlide: number, nextSlide: number) => {
            if (nextSlide > currentSlide) {
                setProgress((prev) => prev + 10);
            } else if (nextSlide < currentSlide) {
                setProgress((prev) => prev - 10);
            }
        },
        afterChange: (currentSlide: number) => {
            if (currentSlide === 9) {
                setPosition('end');
            } else if (currentSlide > 0) {
                setPosition('');
            } else if (currentSlide === 0) {
                setPosition('start');
                setProgress(10);
            }
        }
    };

    return (
        <Stack position="relative" gap={isMobile ? '24px' : '89px'}>
            <Stack width="100%" maxWidth={matches ? '2000px' : '874px'}>
                {isMobile ? (
                    <Slider {...settings}>
                        {MobileCarousels.map((carousel) => (
                            <Stack key={carousel}>
                                <img
                                    style={{
                                        width: '100%'
                                    }}
                                    src={carousel}
                                    alt="slideshow"
                                />
                            </Stack>
                        ))}
                    </Slider>
                ) : (
                    <Slider {...settings}>
                        {carousels.map((carousel) => (
                            <Stack key={carousel}>
                                <img
                                    style={{
                                        width: '100%'
                                    }}
                                    src={carousel}
                                    alt="slideshow"
                                />
                            </Stack>
                        ))}
                    </Slider>
                )}
            </Stack>
            <Stack maxWidth={matches ? '2000px' : '874px'}>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        backgroundColor: '#D9D9D9',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'primary2.main'
                        }
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default CarouselCard;
