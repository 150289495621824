import React from 'react';

const ProfileIconBlue = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M10.0013 1.66669C7.81797 1.66669 6.04297 3.44169 6.04297 5.62502C6.04297 7.76669 7.71797 9.50002 9.9013 9.57502C9.96797 9.56669 10.0346 9.56669 10.0846 9.57502C10.1013 9.57502 10.1096 9.57502 10.1263 9.57502C10.1346 9.57502 10.1346 9.57502 10.143 9.57502C12.2763 9.50002 13.9513 7.76669 13.9596 5.62502C13.9596 3.44169 12.1846 1.66669 10.0013 1.66669Z"
                fill="#05668D"
            />
            <path
                d="M14.2328 11.7917C11.9078 10.2417 8.11615 10.2417 5.77448 11.7917C4.71615 12.5 4.13281 13.4583 4.13281 14.4833C4.13281 15.5083 4.71615 16.4583 5.76615 17.1583C6.93281 17.9417 8.46615 18.3333 9.99948 18.3333C11.5328 18.3333 13.0661 17.9417 14.2328 17.1583C15.2828 16.45 15.8661 15.5 15.8661 14.4667C15.8578 13.4417 15.2828 12.4917 14.2328 11.7917Z"
                fill="#05668D"
            />
        </svg>
    );
};

export default ProfileIconBlue;
