import React from 'react';
import { Stack, Typography, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import blogpost2image from '../../Assets/Images/blogpost2image.png';
import Profile from '../../Assets/Images/bjprofile.png';

type BlogPost1Props = {
    isMobile?: boolean;
    isMedium?: boolean;
    screen?: string;
    blogid: number;
};

const BlogPost2 = ({ isMobile, isMedium, screen, blogid }: BlogPost1Props) => {
    const navigate = useNavigate();

    const openBlogPost1 = () => {
        navigate(`/blog-details/${blogid}`, {
            state: {
                blogImage: blogpost2image,
                Profile: Profile,
                blogid: blogid
            }
        });
    };
    return (
        <Stack
            onClick={openBlogPost1}
            alignItems={
                isMobile || screen === 'more-blogs' ? 'flex-start' : 'center'
            }
            pl={isMedium ? '12px' : '24px'}
            pr={isMedium ? '12px' : undefined}
            pb={isMedium ? '12px' : '24px'}
            maxWidth={
                screen === 'more-blogs'
                    ? undefined
                    : isMobile
                    ? '100%'
                    : '351px'
            }
            width={
                screen === 'more-blogs'
                    ? undefined
                    : isMobile
                    ? '100%'
                    : '351px'
            }
            height={isMobile ? '163px' : '378px'}
            position="relative"
            justifyContent="flex-end"
            gap="12px"
            borderRadius={isMobile ? '20px' : '40px'}
            sx={{
                backgroundImage: `url(${blogpost2image})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                cursor: 'pointer'
            }}
        >
            <Stack
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                right={0}
                top={0}
                bottom={0}
                borderRadius={isMobile ? '20px' : '40px'}
                sx={{
                    background:
                        'linear-gradient(170deg, rgba(255, 255, 255, 0.00) 0%, rgba(21, 21, 21, 0.56) 100%)'
                }}
            ></Stack>
            <Stack
                zIndex={1}
                gap={isMobile ? '7.5px' : '12px'}
                width="100%"
                maxWidth={
                    isMedium
                        ? undefined
                        : screen === 'more-blogs'
                        ? '356px'
                        : '298px'
                }
            >
                <Typography
                    variant="h3"
                    color="primaryLight.main"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: isMobile ? '16px' : '24px',
                        fontWeight: 600,
                        lineHeight: '120%',
                        letterSpacing: '-0.72px'
                    }}
                >
                    Is It a Game of Chess? Aligning Employee and Employer
                    Interests
                </Typography>
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    alignSelf="flex-start"
                    gap="12px"
                    py="4px"
                    pl="4px"
                    pr="16px"
                    borderRadius={1}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.15)'
                    }}
                >
                    <Avatar variant="square" src={Profile} />
                    <Stack gap="4px">
                        <Typography
                            color="primaryLight.main"
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '120%',
                                letterSpacing: '-0.28px'
                            }}
                        >
                            by Samson Olanipekun
                        </Typography>
                        <Typography
                            color="primaryLight.main"
                            sx={{
                                fontSize: '12px',
                                fontWeight: 300,
                                lineHeight: '100%'
                            }}
                        >
                            10 min read
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default BlogPost2;
