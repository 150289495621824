import React from 'react';

const DreamJobGroup = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
        >
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 102.729)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 80.627)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 58.5234)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 32 36.4219)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 54.207 32)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 76.3086 32)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 98.4102 32)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 86.7285)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 64.627)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 42.5234)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 16 20.4219)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 38.207 16)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 60.3086 16)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 82.4102 16)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 70.7285)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 48.627)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 26.5234)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(1 0 0 -1 0 4.42188)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 22.207 0)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 44.3086 0)"
                fill="#49B6FF"
            />
            <circle
                cx="2.21025"
                cy="2.21025"
                r="2.21025"
                transform="matrix(0 1 1 0 66.4102 0)"
                fill="#49B6FF"
            />
        </svg>
    );
};

export default DreamJobGroup;
