import React from 'react';

const FirstVector = () => {
    return (
        <svg
            width="154"
            height="100"
            viewBox="0 0 154 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_f_357_13648)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M82.7835 30.8213C93.8257 31.4469 104.165 33.1238 111.608 36.9494C119.726 41.1215 124.993 46.3344 123.843 51.9232C122.469 58.602 118.08 66.0401 105.023 68.9564C91.9587 71.8744 77.5959 68.042 64.4696 65.1843C51.1147 62.2769 35.9352 59.5231 31.6643 52.9857C27.1388 46.0586 32.0908 38.039 43.5028 33.091C53.6333 28.6985 69.0037 30.0406 82.7835 30.8213Z"
                    fill="#FFC24C"
                    fillOpacity="0.25"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_357_13648"
                    x="0"
                    y="0"
                    width="154"
                    height="100"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="15"
                        result="effect1_foregroundBlur_357_13648"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default FirstVector;
