import React from 'react';
import {
    Stack,
    Typography,
    Avatar,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import YellowArrowRight from '../../Assets/Icons/YellowArrowRight';
import DotIcon from '../../Assets/Icons/DotIcon';
import '@fontsource/outfit';
import blogDetailImage from '../../Assets/Images/blogdetail4image.png';

const BlogDetails4 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();
    return (
        <Stack flex={1}>
            <Stack
                zIndex={2}
                height={isMobile ? '238px' : '358px'}
                borderRadius={isMobile ? '20px' : '40px'}
                justifyContent="flex-end"
                px={isMobile ? '8px' : '48px'}
                pb={isMobile ? '16px' : '24px'}
                pt="48px"
                mb={isMobile ? '20px' : '60px'}
                sx={{
                    backgroundImage: `url(${blogDetailImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <Stack
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    {isMobile ? null : (
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Job
                            </Typography>
                            <YellowArrowRight />
                            <Typography
                                component="span"
                                color="primary2.main"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '120%'
                                }}
                            >
                                Soft skills
                            </Typography>
                        </Stack>
                    )}

                    <Stack gap="8px">
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                        >
                            <Typography
                                component="span"
                                color="navTextColor.main"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                Feb. 11, 2023
                            </Typography>
                            <DotIcon />
                            <Typography
                                component="span"
                                color="navTextColor.main"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '0.049px'
                                }}
                            >
                                10 min read
                            </Typography>
                        </Stack>
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="12px"
                            py="4px"
                            pl="4px"
                            pr="16px"
                            // width="200px"
                            borderRadius={1}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                cursor: 'pointer'
                            }}
                        >
                            <Avatar
                                variant="square"
                                src={location.state.Profile}
                            />
                            <Typography
                                component="span"
                                color="primaryLight.main"
                                sx={{
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontWeight: 500,
                                    lineHeight: '120%',
                                    letterSpacing: '-0.32px'
                                }}
                            >
                                by Samson Olanipekun
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                px={isMobile ? '4px' : undefined}
                pb={isMobile ? '50px' : '38px'}
                zIndex={2}
                maxWidth="914px"
                mx="auto"
                gap="20px"
            >
                <Typography
                    component="span"
                    variant="h2"
                    color="primaryDark"
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: isMobile ? '20px' : '40px',
                        fontWeight: 600,
                        lineHeight: '120%',
                        letterSpacing: isMobile ? '-0.6px' : '-0.8px'
                    }}
                >
                    Unlocking Career success: Mastering soft skills for
                    acceleration
                </Typography>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    In today's ever-evolving professional landscape, technical
                    skills alone are no longer sufficient to guarantee a
                    meteoric rise in your career. The true differentiator lies
                    in your ability to harness the power of soft skills, those
                    intangible qualities that shape how you interact,
                    communicate, and collaborate with others. In this article,
                    I'll guide you through the art of leveraging these skills to
                    propel your journey to the top.
                </Typography>
                <Stack gap="20px">
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            1. The Foundation: Emotional Intelligence (EQ):
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            At the core of every successful individual lies a
                            robust foundation of emotional intelligence. This
                            encompasses self-awareness, self-regulation,
                            empathy, and interpersonal skills. EQ helps you
                            navigate complex workplace dynamics, build
                            meaningful relationships, and handle challenges with
                            grace.
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Action Steps:{' '}
                            </Typography>
                            Start by enhancing your self-awareness. Regularly
                            assess your emotions, reactions, and triggers.
                            Practice active listening to understand others
                            deeply. Cultivate empathy by putting yourself in
                            their shoes. Remember, emotional intelligence is a
                            lifelong journey of growth.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            2. Mastering Communication:
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Effective communication is the bedrock of successful
                            leadership. Whether it's delivering presentations,
                            holding meetings, or engaging in one-on-one
                            discussions, the ability to convey ideas clearly and
                            persuasively is a game-changer.
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Action Steps:{' '}
                            </Typography>
                            Hone your verbal and written communication skills.
                            Craft concise yet compelling messages. Focus on
                            active and empathetic listening, allowing you to
                            comprehend nuances and respond thoughtfully. Tailor
                            your communication style to your audience and
                            situation.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            3. Collaborative Leadership
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            Modern workplaces thrive on collaboration. The
                            ability to lead and contribute seamlessly within
                            teams, across departments, and even globally is a
                            sought-after skill. Collaborative leaders unite
                            diverse perspectives, driving innovation and
                            achieving collective goals
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Action Steps:{' '}
                            </Typography>
                            Foster a team-first mindset. Value diverse
                            viewpoints and encourage open dialogue. Develop your
                            conflict resolution skills to navigate disagreements
                            constructively. Offer your expertise while remaining
                            open to learning from others.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            4. Adaptability and Resilience
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            In a rapidly changing world, adaptability is
                            paramount. Embrace challenges as opportunities for
                            growth. Resilience empowers you to bounce back from
                            setbacks stronger and more determined than before.
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Action Steps:{' '}
                            </Typography>
                            Embrace change with a positive attitude.
                            Continuously seek opportunities to learn new skills.
                            When faced with setbacks, analyze the lessons they
                            offer and apply them to future endeavors.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 600,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            5. Influence and Persuasion
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            The ability to influence and persuade is a hallmark
                            of impactful leaders. Whether you're negotiating a
                            deal, presenting a proposal, or rallying support for
                            your vision, mastering this skill will give you a
                            distinct edge.
                        </Typography>
                        <Typography
                            component="span"
                            color="primaryDark.light"
                            sx={{
                                fontSize: isMobile ? '14px' : '20px',
                                fontWeight: 300,
                                lineHeight: '150%',
                                fontFamily: 'Outfit',
                                letterSpacing: isMobile ? '.28px' : '0.4px'
                            }}
                        >
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 600,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px',
                                    display: 'inline'
                                }}
                            >
                                Action Steps:{' '}
                            </Typography>
                            Understand your audience's needs and motivations.
                            Craft compelling narratives that resonate. Use
                            evidence and storytelling to make your case.
                            Remember, true influence is built on authenticity
                            and trust.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                1.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                {' '}
                                <Typography
                                    component="span"
                                    fontWeight={600}
                                    sx={{
                                        display: 'inline',
                                        fontSize: isMobile ? '14px' : '20px',
                                        lineHeight: '150%',
                                        fontFamily: 'Outfit',
                                        letterSpacing: isMobile
                                            ? '.28px'
                                            : '0.4px'
                                    }}
                                >
                                    Conflict Resolution:
                                </Typography>
                                 Imagine a project where team members have
                                conflicting viewpoints. Utilize your emotional
                                intelligence to mediate discussions, validate
                                each person's perspective, and find common
                                ground. Your collaborative approach will enhance
                                team cohesion and propel the project forward.
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                2.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                {' '}
                                <Typography
                                    component="span"
                                    fontWeight={600}
                                    sx={{
                                        display: 'inline',
                                        fontSize: isMobile ? '14px' : '20px',
                                        lineHeight: '150%',
                                        fontFamily: 'Outfit',
                                        letterSpacing: isMobile
                                            ? '.28px'
                                            : '0.4px'
                                    }}
                                >
                                    Negotiation:
                                </Typography>
                                 In a high-stakes negotiation, draw upon your
                                communication skills to articulate your points
                                persuasively. Incorporate active listening to
                                understand the other party's concerns deeply. By
                                finding mutually beneficial solutions, you can
                                secure a successful outcome.
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                3.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                {' '}
                                <Typography
                                    component="span"
                                    fontWeight={600}
                                    sx={{
                                        display: 'inline',
                                        fontSize: isMobile ? '14px' : '20px',
                                        lineHeight: '150%',
                                        fontFamily: 'Outfit',
                                        letterSpacing: isMobile
                                            ? '.28px'
                                            : '0.4px'
                                    }}
                                >
                                    Change Management:
                                </Typography>
                                 During a company-wide transition, demonstrate
                                adaptability by embracing the changes openly.
                                Your resilience will inspire others to follow
                                suit, fostering a smoother transition and
                                positioning you as a champion of progress.
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        component="span"
                        color="primaryDark.light"
                        sx={{
                            fontSize: isMobile ? '14px' : '20px',
                            fontWeight: 300,
                            lineHeight: '150%',
                            fontFamily: 'Outfit',
                            letterSpacing: isMobile ? '.28px' : '0.4px'
                        }}
                    >
                        To ensure you're noticed and recognized for your soft
                        skills:
                    </Typography>
                    <Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                1.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                {' '}
                                <Typography
                                    component="span"
                                    fontWeight={600}
                                    sx={{
                                        display: 'inline',
                                        fontSize: isMobile ? '14px' : '20px',
                                        lineHeight: '150%',
                                        fontFamily: 'Outfit',
                                        letterSpacing: isMobile
                                            ? '.28px'
                                            : '0.4px'
                                    }}
                                >
                                    Lead by Example:
                                </Typography>
                                 Model the behavior you wish to see in others.
                                Your consistent display of soft skills will
                                inspire your colleagues to follow suit.
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                2.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                {' '}
                                <Typography
                                    component="span"
                                    fontWeight={600}
                                    sx={{
                                        display: 'inline',
                                        fontSize: isMobile ? '14px' : '20px',
                                        lineHeight: '150%',
                                        fontFamily: 'Outfit',
                                        letterSpacing: isMobile
                                            ? '.28px'
                                            : '0.4px'
                                    }}
                                >
                                    Mentorship:
                                </Typography>
                                 Offer guidance and support to those who seek to
                                develop their soft skills. Your willingness to
                                invest in others will showcase your leadership
                                and expertise.
                            </Typography>
                        </Stack>
                        <Stack flexDirection="row" gap="5px">
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                3.{' '}
                            </Typography>
                            <Typography
                                component="span"
                                color="primaryDark.light"
                                sx={{
                                    fontSize: isMobile ? '14px' : '20px',
                                    fontWeight: 300,
                                    lineHeight: '150%',
                                    fontFamily: 'Outfit',
                                    letterSpacing: isMobile ? '.28px' : '0.4px'
                                }}
                            >
                                {' '}
                                <Typography
                                    component="span"
                                    fontWeight={600}
                                    sx={{
                                        display: 'inline',
                                        fontSize: isMobile ? '14px' : '20px',
                                        lineHeight: '150%',
                                        fontFamily: 'Outfit',
                                        letterSpacing: isMobile
                                            ? '.28px'
                                            : '0.4px'
                                    }}
                                >
                                    Continual Growth:
                                </Typography>
                                 Soft skills are not static. Commit to ongoing
                                development through workshops, courses, and
                                self-assessment. Your dedication to improvement
                                will set you apart.
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Typography
                    component="span"
                    color="primaryDark.light"
                    sx={{
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 300,
                        lineHeight: '150%',
                        fontFamily: 'Outfit',
                        letterSpacing: isMobile ? '.28px' : '0.4px'
                    }}
                >
                    In the modern workplace, mastering soft skills is the key to
                    career acceleration. Beyond technical prowess, these skills
                    are the driving force behind impactful leadership, effective
                    collaboration, and personal growth. By investing in your
                    emotional intelligence, communication abilities,
                    adaptability, and influence, you'll not only reach the top
                    but leave an indelible mark on your journey. So, embark on
                    this transformative quest, for the path to success is paved
                    with the finesse of soft skills.
                </Typography>
            </Stack>
        </Stack>
    );
};

export default BlogDetails4;
