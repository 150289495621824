import React from 'react';

const DotsBlue = () => {
    return (
        <svg
            width="91"
            height="91"
            viewBox="0 0 91 91"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="19.3079" cy="2.30794" r="2.30794" fill="#05668D" />
            <circle cx="19.3079" cy="25.3873" r="2.30794" fill="#05668D" />
            <circle cx="19.3079" cy="48.4666" r="2.30794" fill="#05668D" />
            <circle cx="19.3079" cy="71.546" r="2.30794" fill="#05668D" />
            <circle cx="2.30794" cy="19.3079" r="2.30794" fill="#05668D" />
            <circle cx="2.30794" cy="42.3873" r="2.30794" fill="#05668D" />
            <circle cx="2.30794" cy="65.4666" r="2.30794" fill="#05668D" />
            <circle cx="2.30794" cy="88.546" r="2.30794" fill="#05668D" />
            <circle
                cx="41.9251"
                cy="71.5461"
                r="2.30794"
                transform="rotate(-90 41.9251 71.5461)"
                fill="#05668D"
            />
            <circle
                cx="65.0033"
                cy="71.5461"
                r="2.30794"
                transform="rotate(-90 65.0033 71.5461)"
                fill="#05668D"
            />
            <circle
                cx="88.0853"
                cy="71.5461"
                r="2.30794"
                transform="rotate(-90 88.0853 71.5461)"
                fill="#05668D"
            />
            <circle
                cx="24.9251"
                cy="88.5461"
                r="2.30794"
                transform="rotate(-90 24.9251 88.5461)"
                fill="#05668D"
            />
            <circle
                cx="48.0033"
                cy="88.5461"
                r="2.30794"
                transform="rotate(-90 48.0033 88.5461)"
                fill="#05668D"
            />
            <circle
                cx="71.0853"
                cy="88.5461"
                r="2.30794"
                transform="rotate(-90 71.0853 88.5461)"
                fill="#05668D"
            />
        </svg>
    );
};

export default DotsBlue;
