import React from 'react';
import {
    Container,
    Pagination,
    PaginationItem,
    Stack,
    useTheme,
    useMediaQuery,
    Typography,
    Grid
} from '@mui/material';
import PreviousIcon from '../../Assets/Icons/PreviousIcon';
import NextIcon from '../../Assets/Icons/NextIcon';
import Newsletter from '../Newsletter/Newsletter';
import { useAppDispatch, useAppSelector } from '../../Feature/Store/Store';
import { changePageDown, changePageUp } from '../../Feature/App/AppSlice';
import BlogPost1 from '../BlogPost/BlogPost1';
import BlogPost4 from '../BlogPost/BlogPost4';
import BlogPost3 from '../BlogPost/BlogPost3';
import BlogPost2 from '../BlogPost/BlogPost2';

type MoreBlogSectionProps = {
    formik?: any;
};

const MoreBlogs = ({ formik }: MoreBlogSectionProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    // const [page, setPage] = useState(1);
    const dispatch = useAppDispatch();
    const page = useAppSelector((state) => state.app.page);
    const PaginatePrevious = () => {
        return (
            <Stack onClick={() => dispatch(changePageDown(1))}>
                <PreviousIcon />
            </Stack>
        );
    };
    const PaginateNext = () => {
        return (
            <Stack onClick={() => dispatch(changePageUp(1))}>
                <NextIcon />
            </Stack>
        );
    };

    return (
        <Stack
            sx={{
                background:
                    'linear-gradient(225deg, #F1FBFF 0%, rgba(255, 251, 243, 0.92) 100%)'
            }}
        >
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    px: isMobile ? '8px' : '60px',
                    pt: isMobile ? '60px' : '100px'
                }}
            >
                <Stack>
                    <Stack
                        height="120px"
                        flexDirection="row"
                        alignItems="center"
                        pl={isMobile ? '16px' : undefined}
                    >
                        <Stack
                            zIndex={2}
                            // ml={isMobile ? '12px' : '42px'}
                            px={isMobile ? '12px' : '20px'}
                            py={isMobile ? '8px' : '20px'}
                            sx={{ backgroundColor: 'primary2.main' }}
                            borderRadius="12PX"
                        >
                            <Typography
                                color="primaryLight.main"
                                variant="h3"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: isMobile ? '20px' : '36px',
                                    fontWeight: 600,
                                    lineHeight: '32px',
                                    letterSpacing: isMobile ? '-.8px' : '-1.8px'
                                }}
                            >
                                Most Popular Articles
                            </Typography>
                        </Stack>
                    </Stack>
                    {/* <Stack
                  flexDirection="row"
                  gap={isMobile ? '12px' : '24px'}
                  flexWrap="wrap"
                  mb={isMobile ? '28px' : '32px'}
              >
                  {page === 1 && (
                      <>
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage}
                          />
                      </>
                  )}

                  {page === 2 && (
                      <>
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                          <BlogPost
                              isMedium={isMedium}
                              screen="more-blogs"
                              isMobile={isMobile}
                              Profile={Profile}
                              blogImage={blogImage1}
                          />
                      </>
                  )}
              </Stack> */}
                    <Grid
                        mb={isMobile ? '24px' : '32px'}
                        container
                        rowSpacing={{ xs: 1, sm: 2, md: '12px', lg: 6 }}
                        columnSpacing={{ md: 3 }}
                    >
                        {page === 1 && (
                            <>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost1
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        blogid={1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost2
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        blogid={2}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost3
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        blogid={3}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost4
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        blogid={4}
                                    />
                                </Grid>
                                {/* <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage}
                                    />
                                </Grid>
                            </>
                        )}

                        {page === 2 && (
                            <>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid>
                                <Grid item xl={4} lg={6} md={12} sm={12}>
                                    <BlogPost
                                        isMedium={isMedium}
                                        screen="more-blogs"
                                        isMobile={isMobile}
                                        Profile={Profile}
                                        blogImage={blogImage1}
                                    />
                                </Grid> */}
                            </>
                        )}
                    </Grid>
                    <Stack
                        width="100%"
                        sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        mb={isMobile ? '20px' : '49px'}
                    >
                        <Pagination
                            color="primary"
                            size={isMedium ? 'small' : 'large'}
                            count={1}
                            page={page}
                            siblingCount={1}
                            boundaryCount={2}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: PaginatePrevious,
                                        next: PaginateNext
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            </Container>
            <Stack mt={isMobile ? '50px' : '41px'} zIndex={2}>
                <Newsletter formik={formik} />
            </Stack>
        </Stack>
    );
};

export default MoreBlogs;
