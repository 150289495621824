import React from 'react';

const EclipseSmall = () => {
    return (
        <svg
            width="540"
            height="515"
            viewBox="0 0 540 515"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M-2.51512e-05 257.5C-2.51512e-05 399.713 115.127 515 257.144 515C373.791 515 498.761 430.527 530.232 323.935C537.076 300.753 540 284.449 540 259.045C540 236.816 535.52 215.246 530.231 194.67C501.686 83.6085 376.962 0 257.144 0C115.127 0 -2.51512e-05 115.287 -2.51512e-05 257.5Z"
                fill="#D9D9D9"
            />
        </svg>
    );
};

export default EclipseSmall;
