import React from 'react';

const DreamJobIcon3 = () => {
    return (
        <svg
            width="64"
            height="56"
            viewBox="0 0 64 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="64" height="56" rx="4" fill="#EF6CE2" />
            <path
                d="M42.0186 36.6609L42.0186 36.6609L42.0155 36.6616C41.3515 36.8231 40.8112 37.3318 40.6595 38.023L40.1939 39.9783L40.1939 39.9786C40.0337 40.6528 39.1803 40.8522 38.7429 40.3322L38.743 40.3322L38.7377 40.3261L34.7533 35.7421C34.753 35.7418 34.7528 35.7415 34.7525 35.7412C34.6647 35.6375 34.7252 35.4991 34.8272 35.4728C37.2907 34.8738 39.5035 33.4957 41.1318 31.5334L41.1325 31.5326C41.2149 31.4328 41.3269 31.4478 41.3801 31.501L44.3401 34.461C44.751 34.8719 44.8363 35.2808 44.7825 35.5641C44.729 35.845 44.5319 36.0667 44.2184 36.1409L42.0186 36.6609Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M22.8021 31.5334C24.4304 33.4958 26.6433 34.8739 29.1068 35.4728C29.2087 35.4993 29.2692 35.6375 29.1814 35.7411C29.1811 35.7415 29.1809 35.7418 29.1806 35.7421L25.1962 40.3261L25.1961 40.3261L25.1909 40.3322C24.7535 40.8522 23.9002 40.6528 23.74 39.9786L23.74 39.9783L23.2744 38.023C23.1227 37.3318 22.5824 36.8231 21.9184 36.6616L21.9184 36.6616L21.9152 36.6609L19.7155 36.1409C19.402 36.0667 19.2048 35.845 19.1514 35.5641C19.0976 35.2808 19.1829 34.8719 19.5938 34.461L22.5538 31.501C22.6069 31.4478 22.719 31.4328 22.8014 31.5326L22.8021 31.5334Z"
                fill="white"
                stroke="white"
            />
            <path
                d="M32.0013 14.666C26.8413 14.666 22.668 18.8393 22.668 23.9993C22.668 25.9327 23.2413 27.706 24.228 29.186C25.668 31.3193 27.948 32.826 30.6013 33.2127C31.0546 33.2927 31.5213 33.3327 32.0013 33.3327C32.4813 33.3327 32.948 33.2927 33.4013 33.2127C36.0546 32.826 38.3346 31.3193 39.7746 29.186C40.7613 27.706 41.3346 25.9327 41.3346 23.9993C41.3346 18.8393 37.1613 14.666 32.0013 14.666ZM36.0813 23.706L34.9746 24.8127C34.788 24.9993 34.6813 25.3593 34.748 25.626L35.068 26.9993C35.3213 28.0793 34.748 28.506 33.788 27.9327L32.4546 27.146C32.2146 26.9993 31.8146 26.9993 31.5746 27.146L30.2413 27.9327C29.2813 28.4927 28.708 28.0793 28.9613 26.9993L29.2813 25.626C29.3346 25.3727 29.2413 24.9993 29.0546 24.8127L27.9213 23.706C27.268 23.0527 27.4813 22.3993 28.388 22.2527L29.8146 22.0127C30.0546 21.9727 30.3346 21.7593 30.4413 21.546L31.228 19.9727C31.6546 19.1193 32.348 19.1193 32.7746 19.9727L33.5613 21.546C33.668 21.7593 33.948 21.9727 34.2013 22.0127L35.628 22.2527C36.5213 22.3993 36.7346 23.0527 36.0813 23.706Z"
                fill="white"
            />
        </svg>
    );
};

export default DreamJobIcon3;
