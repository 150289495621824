import React from 'react';

const FBIconModal = () => {
    return (
        <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3718_5255)">
                <path
                    d="M26.0019 51.119C39.8747 51.119 51.1209 39.8728 51.1209 25.9999C51.1209 12.127 39.8747 0.880859 26.0019 0.880859C12.129 0.880859 0.882812 12.127 0.882812 25.9999C0.882812 39.8728 12.129 51.119 26.0019 51.119Z"
                    fill="#05668D"
                />
                <path
                    d="M38.9234 9.69238H32.564C28.7901 9.69238 24.5924 11.2796 24.5924 16.75C24.6109 18.6561 24.5924 20.4816 24.5924 22.5361H20.2266V29.4835H24.7275V49.4839H32.9983V29.3515H38.4573L38.9512 22.5166H32.8558C32.8558 22.5166 32.8695 19.4761 32.8558 18.5932C32.8558 16.4314 35.1052 16.5552 35.2405 16.5552C36.3109 16.5552 38.3922 16.5583 38.9265 16.5552V9.69238H38.9234Z"
                    fill="#FAFAFA"
                />
            </g>
            <defs>
                <clipPath id="clip0_3718_5255">
                    <rect
                        width="50.2381"
                        height="50.2381"
                        fill="white"
                        transform="translate(0.882812 0.880859)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FBIconModal;
