import { Stack, Typography } from '@mui/material';
import React from 'react';

type TermPolicyProps = {
    isMedium: boolean;
};

const TermPolicy = ({ isMedium }: TermPolicyProps) => {
    return (
        <Stack>
            <Typography
                color="primaryDark.main"
                fontSize="45px"
                fontWeight={700}
                lineHeight="normal"
                mb="15px"
                fontFamily="montserrat"
            >
                {isMedium
                    ? 'Jobfactor Terms of use'
                    : 'Jobfactor Terms of use Policy'}
            </Typography>
            <Typography
                color="primaryDark.main"
                fontSize="20px"
                fontWeight={600}
                lineHeight="normal"
                mb="25px"
                fontFamily="montserrat"
            >
                Effective Date: August 1, 2023
            </Typography>
            <Typography
                color="primaryDark.main"
                fontSize="18px"
                fontWeight={400}
                lineHeight="normal"
                mb="45px"
                fontFamily="open sans"
            >
                Welcome to Jobfactor, a Talent Acquisition SaaS Platform
                connecting job seekers and employers globally. Please carefully
                read and understand the following terms before using our
                platform.
                <br />
                <br />
                User Agreement <br /> By accessing and using Jobfactor, you
                agree to abide by the terms and conditions outlined in this
                agreement.
            </Typography>
            <Stack gap="32px" mb="45px">
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        1. User Responsibilities:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.1. You are responsible for the accuracy and
                            completeness of the information you provide on
                            Jobfactor.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.2. You will not engage in any illegal, fraudulent,
                            or harmful activities on the platform.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            1.3. You agree to respect the intellectual property
                            rights of other users and Jobfactor.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        2. Intellectual Property:
                    </Typography>
                    <Typography
                        color="primaryDark.main"
                        fontSize="18px"
                        fontWeight={400}
                        lineHeight="normal"
                        fontFamily="open sans"
                    >
                        2.1. Jobfactor retains all rights to its intellectual
                        property, including trademarks, logos, and platform
                        content. Users are prohibited from using our branding
                        without explicit permission.
                    </Typography>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        3. Content Guidelines:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            3.1. You agree not to post or share any offensive,
                            defamatory, or inappropriate content.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            3.2. You will exercise appropriate precautions while
                            posting job-related accomplishments and activities
                            and while seeking reviews and feedback from
                            colleagues and professional connections. It is your
                            responsibility to understand what your employer
                            considers to be sensitive company information and to
                            refrain from posting or sharing any such information
                            deemed or that could be deemed sensitive or
                            proprietary to your employer. Jobfactor will not be
                            responsible for any careless sharing of sensitive
                            information and will not be party to any conflicts
                            or litigations arising from such exposure except to
                            assist in mitigating the impact by removing such
                            content and its thread from our platform upon formal
                            written request and in accordance with our policies.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            3.3. You will not impersonate any individual or
                            entity, or engage in spamming or phishing
                            activities. We reserve the right to temporarily or
                            indefinitely remove, delete, or ban any account that
                            engage in such prohibited activities from Jobfactor.
                            Any such account, so removed, deleted or banned can
                            only be reinstated upon formal written request,
                            followed by our internal investigations and final
                            decision to grant or deny such request. We may also
                            request additional information from the account
                            holder to aid in a decision.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            3.4. Jobfactor reserves the right to remove content
                            that violates these guidelines.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        4. Account Management:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            4.1. You are responsible for maintaining the
                            confidentiality of your account credentials.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            4.2. You will not share your account information
                            with anyone or allow unauthorized access.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            4.3. Our Privacy Policy governs the collection, use,
                            and disclosure of personal information and data on
                            this Website. By using this Website, you consent to
                            the practices outlined in our Privacy Policy.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        5. Dispute Resolution:
                    </Typography>
                    <Typography
                        color="primaryDark.main"
                        fontSize="18px"
                        fontWeight={400}
                        lineHeight="normal"
                        fontFamily="open sans"
                    >
                        5.1. Any disputes arising from platform use will be
                        resolved through arbitration in accordance with relevant
                        arbitration rules.
                    </Typography>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        6. Termination:
                    </Typography>
                    <Stack gap="20px">
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            6.1. Jobfactor reserves the right to suspend or
                            terminate your account if you violate these terms.
                        </Typography>
                        <Typography
                            color="primaryDark.main"
                            fontSize="18px"
                            fontWeight={400}
                            lineHeight="normal"
                            fontFamily="open sans"
                        >
                            6.2. You may also terminate your account at any
                            time, subject to our data retention policies.
                        </Typography>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography
                        color="primaryDark.main"
                        fontSize="24px"
                        fontWeight={700}
                        lineHeight="normal"
                        mb="25px"
                        fontFamily="montserrat"
                    >
                        7. Governing Law:
                    </Typography>
                    <Typography
                        color="primaryDark.main"
                        fontSize="18px"
                        fontWeight={400}
                        lineHeight="normal"
                        fontFamily="open sans"
                    >
                        7.1. These terms are governed by the laws of North
                        Carolina. Any legal action will be subject to the courts
                        of North Carolina.
                    </Typography>
                </Stack>
            </Stack>
            <Typography
                color="primaryDark.main"
                fontSize="18px"
                fontWeight={400}
                lineHeight="normal"
                fontFamily="open sans"
            >
                If you have any questions or concerns about these Terms of Use,
                please contact us at contact@jobfactor.com.
            </Typography>
        </Stack>
    );
};

export default TermPolicy;
