import {
    Box,
    Container,
    ImageList,
    ImageListItem,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React from 'react';
import Background from '../../Assets/Images/heroBackground.png';

const Career = () => {
    const theme = useTheme();
    const medium = useMediaQuery(theme.breakpoints.down('md'));
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Stack
            position="relative"
            pt={mobile ? '60px' : '126px'}
            pb={mobile ? '60px' : '90px'}
            sx={{
                bgcolor: '#FCFBF8',

                backgroundBlendMode: 'overlay'
            }}
        >
            <Stack
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                sx={{
                    backgroundImage: `url(${Background})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
            ></Stack>
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    px: '16px',
                    position: 'relative'
                }}
            >
                {mobile ? (
                    <Stack
                        sx={{
                            gap: '50px'
                        }}
                    >
                        <Stack
                            sx={{
                                // display: 'flex',
                                maxWidth: '700px',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyItems: 'center',
                                gap: '24px'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#464646',
                                    fontFamily: 'Poppins',
                                    fontSize: '60px',
                                    fontStyle: 'normal',
                                    lineHeight: '120%',
                                    letterSpacing: '-3px',
                                    fontWeight: 600,
                                    alignSelf: 'stretch'
                                }}
                            >
                                Your Career Success Indicator
                            </Typography>
                            <Typography
                                color="#747474"
                                fontSize={'20px'}
                                fontWeight={300}
                                lineHeight="30px"
                                fontFamily="Outfit"
                            >
                                Jobfactor score is a scoring system that
                                combines 62 different data points, including
                                skills, culture fit and value interaction to
                                predict success in employment.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    bgcolor: '#FFC24C',
                                    borderRadius: '64px',
                                    alignItems: 'center',
                                    gap: '10px',
                                    px: '16px',
                                    py: '4px'
                                }}
                            >
                                <Typography
                                    color={'#FFF'}
                                    fontFamily={'poppins'}
                                    fontSize={'16px'}
                                    fontWeight={700}
                                    lineHeight={'28px'}
                                >
                                    Jobfactor
                                </Typography>
                            </Box>
                        </Stack>
                        <Box
                            maxWidth={'362.999px'}
                            display={'flex'}
                            alignContent={'center'}
                        >
                            <img
                                src={require('../../Assets/Images/CareerMocks.png')}
                                alt="CareerMocks"
                                width={'100%'}
                                height={'100%'}
                            />
                        </Box>
                    </Stack>
                ) : (
                    <Stack maxWidth={'1163px'} mx="auto" position="relative">
                        <Stack
                            position={'relative'}
                            flexDirection="row-reverse"
                            alignItems="center"
                            gap="81px"
                        >
                            <Stack
                                flex={1}
                                sx={{
                                    display: 'flex',
                                    maxWidth: medium ? '100%' : '511px',
                                    flexDirection: medium ? 'row' : 'column',
                                    alignItems: 'flex-start',
                                    gap: '24px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#464646',
                                        fontFamily: 'Poppins',
                                        fontSize: '60px',
                                        fontStyle: 'normal',
                                        lineHeight: '72px',
                                        letterSpacing: '-3px',
                                        maxWidth: '511px',
                                        fontWeight: 600
                                    }}
                                >
                                    Your Career Success Indicator
                                </Typography>
                                <Typography
                                    maxWidth="492px"
                                    color="#747474"
                                    fontSize={'20px'}
                                    fontWeight="300"
                                    lineHeight="30px"
                                    fontFamily="Outfit"
                                    fontStyle="normal"
                                    letterSpacing="0.92px"
                                >
                                    Jobfactor score is a comprehensive scoring system that
                                    combines 62 different data points, including
                                    skills, culture fit and value interaction to
                                    predict success in employment.
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        bgcolor: '#FFC24C',
                                        borderRadius: '64px',
                                        alignItems: 'center',
                                        gap: '10px',
                                        maxWidth: '133px',
                                        height: '36px',
                                        py: '16px',
                                        px: '4px'
                                    }}
                                >
                                    <Typography
                                        color={'#FFF'}
                                        fontFamily={'poppins'}
                                        fontSize={'20px'}
                                        fontWeight={900}
                                        lineHeight={'28px'}
                                        marginTop={0}
                                        marginLeft={1.5}
                                        marginRight={1.5}
                                    >
                                        Jobfactor
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack width="100%" maxWidth="571px">
                                <ImageList cols={1}>
                                    <ImageListItem>
                                        <img
                                            src={require('../../Assets/Images/CareerMocks.png')}
                                            alt="Monitor"
                                        />
                                    </ImageListItem>
                                </ImageList>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Container>
        </Stack>
    );
};

export default Career;
