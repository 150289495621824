import React from 'react';

const RecieptItemIcon = () => {
    return (
        <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.668 4H12.668C6.66797 4 4.66797 7.58 4.66797 12V14V42C4.66797 43.66 6.54797 44.6 7.86797 43.6L11.288 41.04C12.088 40.44 13.208 40.52 13.928 41.24L17.248 44.58C18.028 45.36 19.308 45.36 20.088 44.58L23.448 41.22C24.148 40.52 25.268 40.44 26.048 41.04L29.468 43.6C30.788 44.58 32.668 43.64 32.668 42V8C32.668 5.8 34.468 4 36.668 4H14.668ZM12.608 28.02C11.508 28.02 10.608 27.12 10.608 26.02C10.608 24.92 11.508 24.02 12.608 24.02C13.708 24.02 14.608 24.92 14.608 26.02C14.608 27.12 13.708 28.02 12.608 28.02ZM12.608 20.02C11.508 20.02 10.608 19.12 10.608 18.02C10.608 16.92 11.508 16.02 12.608 16.02C13.708 16.02 14.608 16.92 14.608 18.02C14.608 19.12 13.708 20.02 12.608 20.02ZM24.668 27.52H18.668C17.848 27.52 17.168 26.84 17.168 26.02C17.168 25.2 17.848 24.52 18.668 24.52H24.668C25.488 24.52 26.168 25.2 26.168 26.02C26.168 26.84 25.488 27.52 24.668 27.52ZM24.668 19.52H18.668C17.848 19.52 17.168 18.84 17.168 18.02C17.168 17.2 17.848 16.52 18.668 16.52H24.668C25.488 16.52 26.168 17.2 26.168 18.02C26.168 18.84 25.488 19.52 24.668 19.52Z"
                fill="white"
            />
            <path
                d="M36.688 4V7C38.008 7 39.268 7.54 40.188 8.44C41.148 9.42 41.668 10.68 41.668 12V16.84C41.668 18.32 41.008 19 39.508 19H35.668V8.02C35.668 7.46 36.128 7 36.688 7V4ZM36.688 4C34.468 4 32.668 5.8 32.668 8.02V22H39.508C42.668 22 44.668 20 44.668 16.84V12C44.668 9.8 43.768 7.8 42.328 6.34C40.868 4.9 38.888 4.02 36.688 4C36.708 4 36.688 4 36.688 4Z"
                fill="white"
            />
        </svg>
    );
};

export default RecieptItemIcon;
