import { configureStore } from '@reduxjs/toolkit';
import AppSlice from '../App/AppSlice';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

const Store = configureStore({
    reducer: {
        app: AppSlice
    }
});

type RootState = ReturnType<typeof Store.getState>;
type AppDispatch = typeof Store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default Store;
