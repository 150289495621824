import React from 'react';

const EclipseMedium = () => {
    return (
        <svg
            width="606"
            height="575"
            viewBox="0 0 606 575"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M-0.00152588 287.5C-0.00152588 446.282 128.717 575 287.498 575C426.058 575 573.416 474.981 600.631 344.5C604.603 325.457 605.629 307.722 605.629 287.5C605.629 267.808 604.402 263.079 600.63 244.5C573.992 113.271 426.588 0 287.498 0C128.717 0 -0.00152588 128.718 -0.00152588 287.5Z"
                fill="#97A87D"
                fillOpacity="0.2"
            />
        </svg>
    );
};

export default EclipseMedium;
