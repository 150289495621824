import React from 'react';
import {
    Stack,
    Typography,
    useTheme,
    useMediaQuery,
    Button
} from '@mui/material';
import Slider from 'react-slick';
import OutlinedArrowRightIcon from '../../Assets/Icons/OutlinedArrowRightIcon';
import DocumentIcon from '../../Assets/Icons/DocumentIcon';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import BlogPost1 from '../BlogPost/BlogPost1';
import BlogPost2 from '../BlogPost/BlogPost2';
import BlogPost3 from '../BlogPost/BlogPost3';
import BlogPost4 from '../BlogPost/BlogPost4';
type BlogListProps = {
    title: string;
    mainBlog?: boolean;
    blogId?: number;
};
const BlogList = ({ title, mainBlog, blogId }: BlogListProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isExtra = useMediaQuery(theme.breakpoints.up('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const ArrowLeft = ({ onClick }: any) => {
        return (
            <Stack
                position="absolute"
                zIndex={5}
                onClick={onClick}
                justifyContent="center"
                top="50%"
                py="10px"
                px="5px"
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, .8)',
                    transform: 'translate(0, -50%)',
                    cursor: 'pointer'
                }}
            >
                <ChevronLeft />
            </Stack>
        );
    };
    const ArrowRight = ({ onClick }: any) => {
        return (
            <Stack
                position="absolute"
                right={isExtra ? 0 : 0}
                top="50%"
                zIndex={5}
                onClick={onClick}
                justifyContent="center"
                py="10px"
                px="5px"
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, .8)',
                    transform: 'translate(0, -50%)',
                    cursor: 'pointer'
                }}
            >
                <ChevronRight />
            </Stack>
        );
    };
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 3.5
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3.2
                }
            }
        ]
    };
    return (
        <Stack
            // pl={mainBlog && !isMobile ? '60px' : undefined}
            // pr={isMobile ? '8px' : undefined}
            width="100%"
            mt={mainBlog ? '50px' : undefined}
        >
            <Stack
                flexDirection={mainBlog || isMobile ? 'row' : undefined}
                justifyContent="space-between"
                alignItems={mainBlog ? 'center' : undefined}
                pr={mainBlog ? '60px' : undefined}
                // pl={isMobile ? '16px' : undefined}
                mb={isMobile ? '22px' : '32px'}
            >
                <Stack
                    zIndex={2}
                    // ml={isMobile ? '12px' : '42px'}
                    px={isMobile ? '12px' : '20px'}
                    py={isMobile ? '8px' : '20px'}
                    sx={{ backgroundColor: 'primary2.main' }}
                    borderRadius="12px"
                >
                    <Typography
                        color="primaryLight.main"
                        variant="h3"
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: isMobile ? '20px' : '36px',
                            fontWeight: 600,
                            lineHeight: '32px',
                            letterSpacing: isMobile ? '-.8px' : '-1.8px'
                        }}
                    >
                        {title}
                    </Typography>
                </Stack>
                {!isMobile && title !== 'More For You' ? (
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/more-blogs')}
                        endIcon={<OutlinedArrowRightIcon />}
                        sx={{
                            backgroundColor: 'primaryLight.main',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                            letterSpacing: '0.08px',
                            textTransform: 'none',
                            p: '16px',
                            borderRadius: '8px',
                            height: '48px'
                        }}
                    >
                        See more
                    </Button>
                ) : null}
            </Stack>
            {mainBlog && !isMobile ? (
                <Stack position="relative">
                    <Slider {...settings}>
                        <BlogPost2
                            blogid={2}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                        <BlogPost3
                            blogid={3}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                        <BlogPost4
                            blogid={4}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                    </Slider>
                </Stack>
            ) : (
                <Stack gap={isMobile ? '12px' : '24px'}>
                    {isMobile ? null : (
                        <>
                            {blogId !== 1 && (
                                <BlogPost1
                                    blogid={1}
                                    isMedium={isMedium}
                                    isMobile={isMobile}
                                />
                            )}
                            {blogId !== 2 && (
                                <BlogPost2
                                    blogid={2}
                                    isMedium={isMedium}
                                    isMobile={isMobile}
                                />
                            )}
                            {blogId !== 3 && (
                                <BlogPost3
                                    blogid={3}
                                    isMedium={isMedium}
                                    isMobile={isMobile}
                                />
                            )}
                            {blogId !== 4 && (
                                <BlogPost4
                                    blogid={4}
                                    isMedium={isMedium}
                                    isMobile={isMobile}
                                />
                            )}
                        </>
                    )}
                    {title === 'Most Popular Articles' && isMobile ? (
                        <BlogPost2
                            blogid={2}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                    ) : null}
                    {title === 'Quick Reads' && isMobile ? (
                        <BlogPost3
                            blogid={3}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                    ) : null}
                    {isMobile ? (
                        <BlogPost4
                            blogid={4}
                            isMedium={isMedium}
                            isMobile={isMobile}
                        />
                    ) : null}
                </Stack>
            )}
            {isMobile && (
                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        py: '16px',
                        mx: '8px',
                        lineHeight: '24px',
                        letterSpacing: '0.024px',
                        mt: '28px',
                        fontSize: '16px',
                        fontWeight: 600,
                        borderRadius: '8px'
                    }}
                    endIcon={<DocumentIcon />}
                    onClick={() => navigate('/more-blogs')}
                >
                    See more
                </Button>
            )}
        </Stack>
    );
};
export default BlogList;
