import {
    Stack,
    Container,
    useMediaQuery,
    useTheme,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import '@fontsource/montserrat';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermPolicy from '../TermPolicy/TermPolicy';
import background from '../../Assets/Images/heroBackground.png';
type PolicyPropsType = {
    setPolicy: (policy: string) => void;
    policy: string;
};
const Policies = ({ setPolicy, policy }: PolicyPropsType) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Stack
            position="relative"
            sx={{
                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`
            }}
        >
            {isMedium ? (
                <Stack
                    position="absolute"
                    right={0}
                    top={0}
                    bottom={0}
                    left={0}
                    sx={{
                        backgroundImage: `url(${background})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain'
                    }}
                ></Stack>
            ) : null}
            <Container
                maxWidth="xl"
                disableGutters
                sx={{
                    px: isMobile ? '16px' : '60px',
                    py: isMobile ? '120px' : '170px'
                }}
            >
                {isMedium ? (
                    <Stack gap="17px" alignItems="center" mb="50px">
                        <Stack
                            zIndex={3}
                            px="25px"
                            py="10px"
                            borderRadius="8px"
                            bgcolor={
                                policy === 'term' ? '#B2D0DC' : 'transparent'
                            }
                            sx={{
                                border:
                                    policy === 'term'
                                        ? '1px solid #05668D'
                                        : undefined
                            }}
                            onClick={() => {
                                setPolicy('term');
                            }}
                        >
                            <Typography
                                color="primaryDark.main"
                                fontSize="16px"
                                fontWeight={600}
                                fontFamily="Montserrat"
                                sx={{ cursor: 'pointer' }}
                            >
                                Jobfactor Term of use Policy
                            </Typography>
                        </Stack>
                        <Stack
                            zIndex={3}
                            px="25px"
                            py="10px"
                            borderRadius="8px"
                            bgcolor={
                                policy === 'privacy' ? '#B2D0DC' : 'transparent'
                            }
                            sx={{
                                border:
                                    policy === 'privacy'
                                        ? '1px solid #05668D'
                                        : undefined
                            }}
                            onClick={() => {
                                setPolicy('privacy');
                            }}
                        >
                            <Typography
                                color="primaryDark.main"
                                fontSize="16px"
                                fontWeight={600}
                                fontFamily="Montserrat"
                                sx={{ cursor: 'pointer' }}
                            >
                                Jobfactor Privacy Policy
                            </Typography>
                        </Stack>
                    </Stack>
                ) : null}
                <Stack flexDirection="row" gap="50px">
                    {isMedium ? null : (
                        <Stack
                            gap="10px"
                            width="250px"
                            height="100%"
                            lineHeight="normal"
                        >
                            <Typography
                                color="primaryDark.main"
                                fontSize="16px"
                                fontWeight={600}
                                fontFamily="Montserrat"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPolicy('term');
                                }}
                            >
                                Jobfactor Term of use Policy
                            </Typography>
                            <Typography
                                color="primaryDark.main"
                                fontSize="16px"
                                fontWeight={600}
                                fontFamily="Montserrat"
                                onClick={() => {
                                    setPolicy('privacy');
                                }}
                                sx={{ cursor: 'pointer' }}
                            >
                                Jobfactor Privacy Policy
                            </Typography>
                        </Stack>
                    )}
                    <Stack flex={1}>
                        {policy === 'privacy' ? (
                            <PrivacyPolicy />
                        ) : (
                            <TermPolicy isMedium={isMedium} />
                        )}
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
};

export default Policies;
