import React from 'react';

const YellowArrowRight = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.0253 15.6834C11.8669 15.6834 11.7086 15.6251 11.5836 15.5001C11.3419 15.2584 11.3419 14.8584 11.5836 14.6168L16.2003 10.0001L11.5836 5.38343C11.3419 5.14176 11.3419 4.74176 11.5836 4.5001C11.8253 4.25843 12.2253 4.25843 12.4669 4.5001L17.5253 9.55843C17.7669 9.8001 17.7669 10.2001 17.5253 10.4418L12.4669 15.5001C12.3419 15.6251 12.1836 15.6834 12.0253 15.6834Z"
                fill="#FFC24C"
            />
            <path
                d="M16.943 10.625H2.91797C2.5763 10.625 2.29297 10.3417 2.29297 10C2.29297 9.65833 2.5763 9.375 2.91797 9.375H16.943C17.2846 9.375 17.568 9.65833 17.568 10C17.568 10.3417 17.2846 10.625 16.943 10.625Z"
                fill="#FFC24C"
            />
        </svg>
    );
};

export default YellowArrowRight;
