import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Container, Grid, Link } from '@mui/material';
import FBIconModal from '../../Assets/Icons/FBIconModal';
import TwitterIconModal from '../../Assets/Icons/TwitterIconModal';
import InstaIconModal from '../../Assets/Icons/InstaIconModal';
import TiktokIconModal from '../../Assets/Icons/TiktokIconModal';
import LinkedinIconModal from '../../Assets/Icons/LinkedinIconModal';
import CloseIconModal from '../../Assets/Icons/CloseIconModal';

const style = {
    position: 'absolute' as 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxidth: '750px',
    maxHeight: '250px',
    bgcolor: '#FFF',
    borderRaduis: '4px',
    px: '40px',
    py: '32px',
    alignItem: 'center',
    gap: '40px'
};
type BasicModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};
export default function BasicModal({ open, setOpen }: BasicModalProps) {
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Container
                        sx={{
                            display: 'flex',
                            maxWidth: '686px',
                            maxHeight: '80px',
                            gap: '16px',
                            mb: 10
                        }}
                    >
                        <Box
                            maxWidth="630px"
                            maxHeight="32px"
                            alignContent="center"
                            flexShrink={0}
                            ml={4}
                        >
                            <Typography
                                id="modal-modal-title"
                                color="#23282B"
                                textAlign="center"
                                fontFamily="Poppins"
                                fontSize="20px"
                                fontWeight="600"
                                fontStyle="normal"
                                lineHeight="32px"
                            >
                                Join our Jobfactor community
                            </Typography>

                            <Typography
                                id="modal-modal-description"
                                sx={{
                                    mt: '16px',
                                    mb: '40px',
                                    color: '#808080',
                                    textAlign: 'center',
                                    fontFamily: 'Open Sans',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                    letterSpacing: '0.08px'
                                }}
                            >
                                joining our community gives you access to
                                updates, newsletter and more.
                            </Typography>
                        </Box>
                        <Box
                            id="close botton"
                            onClick={handleClose}
                            sx={{
                                justifySelf: 'flex-end',

                                maxWidth: '40px',
                                maxHeight: '40px',
                                position: 'relative',
                                left: 30
                            }}
                        >
                            <CloseIconModal />
                        </Box>
                    </Container>
                    <Grid
                        id="modal -social-media-icon"
                        display="flex"
                        maxHeight="50.23809px"
                        maxWidth="432.04761px"
                        gap="45.214px"
                        //mt="45px"
                        mb="5px"
                        ml="110px"
                    >
                        <Link
                            href="https://www.linkedin.com/company/job-factor/"
                            color="inherit"
                            sx={{
                                transition: 'all .4s ease',
                                cursor: 'pointer',
                                //alignSelf: 'center',
                                //marginLeft: '12px',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        >
                            <LinkedinIconModal />
                        </Link>

                        <Link
                            href="https://twitter.com/_jobfactor?t=9xNuozxqPZaQ9ilYibL0iQ&s=09"
                            sx={{
                                transition: 'all .4s ease',
                                cursor: 'pointer',
                                //alignSelf: 'center',
                                //marginLeft: '12px',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        >
                            <TwitterIconModal />
                        </Link>

                        <Link
                            href="https://www.facebook.com/profile.php?id=100089853477839&mibextid=ZbWKwL"
                            color="inherit"
                            sx={{
                                transition: 'all .4s ease',
                                cursor: 'pointer',
                                //alignSelf: 'center',
                                //marginLeft: '12px',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        >
                            <FBIconModal />
                        </Link>

                        <Link
                            href="http://www.instagram.com/officialjobfactor"
                            color="primary"
                            sx={{
                                transition: 'all .4s ease',
                                cursor: 'pointer',
                                //alignSelf: 'center',
                                //marginLeft: '12px',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        >
                            <InstaIconModal />
                        </Link>
                        <Link
                            href="https://www.tiktok.com/@officialjobfactor?_t=8aFd3ZWiKDF&_r=1"
                            color="rgba(255,255,255,1)"
                            sx={{
                                transition: 'all .4s ease',
                                cursor: 'pointer',
                                //alignSelf: 'center',
                                //marginLeft: '12px',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        >
                            <TiktokIconModal />
                        </Link>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
