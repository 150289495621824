import React from 'react';

const Vector = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="101"
            viewBox="0 0 48 101"
            fill="none"
        >
            <mask id="path-1-inside-1_357_15240" fill="white">
                <path d="M48 0C40.6001 0 34.6019 5.41332 34.6019 12.0917V28.6929H6.67447C2.98595 28.6929 0 31.4011 0 34.7379V88.9035C0 95.5851 5.9719 101 13.3489 101V78.2582H41.2605C44.9491 78.2582 47.9508 75.5483 47.9508 72.2067V0.0226769M34.6265 66.625H13.3735V41.1943H34.6265V66.625Z" />
            </mask>
            <path
                d="M34.6019 28.6929V30.6929H36.6019V28.6929H34.6019ZM13.3489 101V103H15.3489V101H13.3489ZM13.3489 78.2582V76.2582H11.3489V78.2582H13.3489ZM34.6265 66.625V68.625H36.6265V66.625H34.6265ZM13.3735 66.625H11.3735V68.625H13.3735V66.625ZM13.3735 41.1943V39.1943H11.3735V41.1943H13.3735ZM34.6265 41.1943H36.6265V39.1943H34.6265V41.1943ZM48 -2C39.6935 -2 32.6019 4.12065 32.6019 12.0917H36.6019C36.6019 6.70598 41.5067 2 48 2V-2ZM32.6019 12.0917V28.6929H36.6019V12.0917H32.6019ZM34.6019 26.6929H6.67447V30.6929H34.6019V26.6929ZM6.67447 26.6929C2.07151 26.6929 -2 30.1156 -2 34.7379H2C2 32.6867 3.90039 30.6929 6.67447 30.6929V26.6929ZM-2 34.7379V88.9035H2V34.7379H-2ZM-2 88.9035C-2 96.8701 5.05691 103 13.3489 103V99C6.88689 99 2 94.3 2 88.9035H-2ZM15.3489 101V78.2582H11.3489V101H15.3489ZM13.3489 80.2582H41.2605V76.2582H13.3489V80.2582ZM41.2605 80.2582C45.8589 80.2582 49.9508 76.8381 49.9508 72.2067H45.9508C45.9508 74.2586 44.0392 76.2582 41.2605 76.2582V80.2582ZM49.9508 72.2067V0.0226769H45.9508V72.2067H49.9508ZM34.6265 64.625H13.3735V68.625H34.6265V64.625ZM15.3735 66.625V41.1943H11.3735V66.625H15.3735ZM13.3735 43.1943H34.6265V39.1943H13.3735V43.1943ZM32.6265 41.1943V66.625H36.6265V41.1943H32.6265Z"
                fill="#FFF9ED"
                mask="url(#path-1-inside-1_357_15240)"
            />
        </svg>
    );
};

export default Vector;
