import React from 'react';
import { Stack, Container, Typography } from '@mui/material';
import TwitterIcon from '../../Assets/Icons/NavTwitterIcon';
import FacebookIcon from '../../Assets/Icons/NavFacebookIcon';
import InstagramIcon from '../../Assets/Icons/NavInstagramIcon';
import YoutubeIcon from '../../Assets/Icons/NavYoutubeIcon';
import TiktokIcon from '../../Assets/Icons/NavTiktokIcon';
import LinkedinIcon from '../../Assets/Icons/NavLinkedinIcon';
import GridTexture from '../../Assets/Images/heroBackground.png';
import { useNavigate, useLocation } from 'react-router-dom';

type MobileNavProps = {
    setMenu: any;
};

const MobileNav = ({ setMenu }: MobileNavProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const navLink = {
        fontWeight: 400,
        fontFamily: 'outfit',
        fontSize: '20px',
        transition: 'all .4s ease',
        '&:hover': {
            color: 'primary.main',
            fontWeight: 700
        }
    };

    const isActive = {
        color: 'primary.main',
        fontWeight: 700
    };

    const navItems = [
        { name: 'BLOG', ref: '/blog' },
        { name: 'ABOUT', ref: '/about' },
        {
            name: 'LINKEDIN',
            icon: <LinkedinIcon />,
            ref: 'https://www.linkedin.com/company/job-factor/'
        },
        { name: 'TWITTER', icon: <TwitterIcon /> },
        {
            name: 'FACEBOOK',
            icon: <FacebookIcon />,
            ref: 'https://www.facebook.com/profile.php?id=100089853477839&mibextid=ZbWKwL'
        },
        {
            name: 'INSTAGRAM',
            icon: <InstagramIcon />,
            ref: 'https://www.instagram.com/officialjobfactor/'
        },
        { name: 'YOUTUBE', icon: <YoutubeIcon /> },
        {
            name: 'TIKTOK',
            icon: <TiktokIcon />,
            ref: 'https://www.tiktok.com/@officialjobfactor?_t=8aFd3ZWiKDF&_r=1'
        }
    ];
    return (
        <Stack
            data-aos="fade-down"
            sx={{
                pt: '30px',
                zIndex: 20,
                position: 'absolute',
                left: 0,
                width: '100%',
                background: `linear-gradient(255deg, #f1fbff 0%, #fff3f5 100%)`,
                height: '100dvh'
            }}
        >
            <Stack
                sx={{
                    zIndex: 2,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    overflow: 'hidden',
                    backgroundImage: `url(${GridTexture})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            ></Stack>
            {navItems.map((item) => (
                <Container
                    key={item.name}
                    onClick={() => {
                        setMenu(false);
                        !item.ref?.includes('http')
                            ? navigate(item?.ref ?? '')
                            : window.open(item.ref, '_blank');
                    }}
                    // underline="none"
                    // target={!item.ref?.includes('http') ? undefined : '_blank'}
                    // rel="noopener"
                    // href={item.ref}
                    sx={{
                        zIndex: 3,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: '12px',
                        borderBottom: '1px solid #D9D9D9'
                    }}
                >
                    <Typography
                        color="navTextColor.main"
                        sx={
                            location.pathname === item.ref ||
                            location.pathname.includes(item.name.toLowerCase())
                                ? isActive
                                : navLink
                        }
                    >
                        {item?.name}
                    </Typography>
                    {item?.icon}
                </Container>
            ))}
        </Stack>
    );
};

export default MobileNav;
