import React from 'react';

const MenuIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M28 10.3335H4C3.45333 10.3335 3 9.88016 3 9.3335C3 8.78683 3.45333 8.3335 4 8.3335H28C28.5467 8.3335 29 8.78683 29 9.3335C29 9.88016 28.5467 10.3335 28 10.3335Z"
                fill="#494949"
            />
            <path
                d="M28 17H4C3.45333 17 3 16.5467 3 16C3 15.4533 3.45333 15 4 15H28C28.5467 15 29 15.4533 29 16C29 16.5467 28.5467 17 28 17Z"
                fill="#494949"
            />
            <path
                d="M28 23.6665H4C3.45333 23.6665 3 23.2132 3 22.6665C3 22.1198 3.45333 21.6665 4 21.6665H28C28.5467 21.6665 29 22.1198 29 22.6665C29 23.2132 28.5467 23.6665 28 23.6665Z"
                fill="#494949"
            />
        </svg>
    );
};

export default MenuIcon;
